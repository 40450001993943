import React from "react";
import type { IconSize } from "./iconStyles";
import { getIconStyles } from "./iconStyles";
interface CollapseColumnIconProps {
    size?: IconSize;
}
export function CollapseColumnIcon({ size = 24 }: CollapseColumnIconProps) {
    return (<svg className={getIconStyles(size)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M6.4375 18.9375L14.9375 10.9375C15.5625 10.375 16.5 10.375 17.0625 11C17.625 11.625 17.625 12.5625 17 13.125L11.25 18.5H16.5H24.5C25.3125 18.5 26 19.1875 26 20C26 20.875 25.3125 21.5 24.5 21.5H16.5H11.25L17 26.9375C17.625 27.5 17.625 28.4375 17.0625 29.0625C16.5 29.6875 15.5625 29.6875 14.9375 29.125L6.4375 21.125C6.125 20.8125 6 20.4375 6 20C6 19.625 6.125 19.25 6.4375 18.9375ZM31 30.5V9.5C31 8.6875 31.625 8 32.5 8C33.3125 8 34 8.6875 34 9.5V30.5C34 31.375 33.3125 32 32.5 32C31.625 32 31 31.375 31 30.5Z"/>
        </svg>);
}
