import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export function MissingPermissionIllustration() {
    return (<svg className={illustrationStyles} viewBox="0 0 236 230" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M104.573 1.89234C141.682 -9.34824 173.21 31.8791 197.163 62.4486C220.839 92.6642 244.139 129.127 233.107 165.93C222.548 201.158 183.103 216.405 147.91 226.763C120.379 234.867 93.1547 226.585 66.9649 214.831C39.4006 202.461 1.20803 190.69 0.0925195 160.424C-1.00682 130.596 46.4376 128.033 62.8481 103.133C83.9705 71.0832 67.9036 12.9995 104.573 1.89234Z" fill={themeTokens.color.background.primary.default}/>
            <mask id="mask0_242_22481" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="90" y="66" width="17" height="17">
                <path fillRule="evenodd" clipRule="evenodd" d="M96.9576 67.2326L95.4431 71.0846C95.3096 71.4226 95.0427 71.6907 94.7119 71.8247L90.8704 73.3458C89.7678 73.7828 89.7678 75.3446 90.8704 75.7817L94.7119 77.3027C95.0485 77.4368 95.3154 77.699 95.4431 78.037L96.9576 81.8891C97.3928 82.9963 98.948 82.9963 99.3832 81.8891L100.898 78.037C101.031 77.699 101.298 77.4309 101.629 77.3027L105.465 75.7817C106.561 75.3446 106.561 73.7828 105.465 73.3458L101.629 71.8247C101.292 71.6907 101.025 71.4226 100.898 71.0846L99.3832 67.2326C99.1627 66.6789 98.6637 66.405 98.1646 66.405C97.6714 66.405 97.1723 66.6789 96.9576 67.2326Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_242_22481)">
                <path fillRule="evenodd" clipRule="evenodd" d="M86.5591 86.8018H110.931V62.3257H86.5591V86.8018Z" fill={themeTokens.color.chip.filled.text.info}/>
            </g>
            <mask id="mask1_242_22481" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="87" y="63" width="5" height="6">
                <path fillRule="evenodd" clipRule="evenodd" d="M89.768 63.7953L90.2148 64.9375C90.2554 65.0366 90.3309 65.1182 90.4353 65.159L91.5727 65.6077C91.8976 65.7359 91.8976 66.2021 91.5727 66.3303L90.4353 66.7791C90.3367 66.8198 90.2554 66.8956 90.2148 67.0005L89.768 68.1427C89.6403 68.4691 89.1761 68.4691 89.0484 68.1427L88.6016 67.0005C88.561 66.9014 88.4855 66.8198 88.3811 66.7791L87.2437 66.3303C86.9187 66.2021 86.9187 65.7359 87.2437 65.6077L88.3811 65.159C88.4797 65.1182 88.561 65.0424 88.6016 64.9375L89.0484 63.7953C89.1122 63.6321 89.2631 63.5505 89.4082 63.5505C89.5591 63.5505 89.7041 63.6321 89.768 63.7953Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_242_22481)">
                <path fillRule="evenodd" clipRule="evenodd" d="M93.5752 70.1531H86.1184V62.6704H93.5752V70.1531Z" fill={themeTokens.color.chip.filled.text.info}/>
            </g>
            <mask id="mask2_242_22481" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="112" y="91" width="11" height="11">
                <path fillRule="evenodd" clipRule="evenodd" d="M117.026 92.3371L116.098 94.7031C116.016 94.9129 115.854 95.0761 115.645 95.1577L113.289 96.0901C112.616 96.3582 112.616 97.3139 113.289 97.582L115.645 98.5144C115.848 98.596 116.016 98.7591 116.098 98.9631L117.026 101.329C117.293 102.005 118.245 102.005 118.512 101.329L119.44 98.9631C119.521 98.7533 119.684 98.5901 119.893 98.5144L122.249 97.582C122.922 97.3139 122.922 96.3582 122.249 96.0901L119.893 95.1577C119.69 95.0761 119.521 94.9129 119.44 94.7031L118.512 92.3371C118.378 91.9991 118.071 91.8301 117.769 91.8301C117.467 91.8301 117.16 91.9991 117.026 92.3371Z" fill="#C5E6FF"/>
            </mask>
            <g mask="url(#mask2_242_22481)">
                <path fillRule="evenodd" clipRule="evenodd" d="M110.074 105.472H125.469V90.0117H110.074V105.472Z" fill={themeTokens.color.chip.filled.text.info}/>
            </g>
            <mask id="mask3_242_22481" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="122" y="100" width="8" height="7">
                <path fillRule="evenodd" clipRule="evenodd" d="M125.579 100.502L124.959 102.075C124.906 102.215 124.796 102.32 124.657 102.378L123.09 103.002C122.643 103.182 122.643 103.818 123.09 103.998L124.657 104.622C124.796 104.674 124.901 104.785 124.959 104.925L125.579 106.498C125.754 106.953 126.392 106.953 126.572 106.498L127.193 104.925C127.245 104.785 127.355 104.68 127.494 104.622L129.061 103.998C129.508 103.818 129.508 103.182 129.061 103.002L127.494 102.378C127.355 102.326 127.251 102.215 127.193 102.075L126.572 100.502C126.485 100.274 126.282 100.164 126.079 100.164C125.87 100.164 125.666 100.28 125.579 100.502Z" fill="#C5E6FF"/>
            </mask>
            <g mask="url(#mask3_242_22481)">
                <path fillRule="evenodd" clipRule="evenodd" d="M121.094 109.336H131.057V99.3301H121.094V109.336Z" fill={themeTokens.color.chip.filled.text.info}/>
            </g>
            <g clipPath="url(#clip0_242_22481)">
                <path d="M193.95 116.981H131.884C129.187 116.981 127 119.185 127 121.904V180.077C127 182.796 129.187 185 131.884 185H193.95C196.648 185 198.835 182.796 198.835 180.077V121.904C198.835 119.185 196.648 116.981 193.95 116.981Z" fill="#7C98B4"/>
                <path d="M202.116 116.981H140.05C137.352 116.981 135.166 119.185 135.166 121.904V180.077C135.166 182.796 137.352 185 140.05 185H202.116C204.813 185 207 182.796 207 180.077V121.904C207 119.185 204.813 116.981 202.116 116.981Z" fill="#A9BBCB"/>
                <path d="M164.003 165.616L168.351 149.18C166.049 148.01 164.458 145.514 164.458 142.622C164.458 138.61 167.527 135.354 171.307 135.354C175.088 135.354 178.157 138.61 178.157 142.622C178.157 145.678 176.379 148.293 173.859 149.368L178.244 165.616H164.003Z" fill="#DAE2E9"/>
                <path d="M161.283 165.616L165.631 149.18C163.329 148.01 161.739 145.514 161.739 142.622C161.739 138.61 164.808 135.354 168.588 135.354C172.368 135.354 175.437 138.61 175.437 142.622C175.437 145.678 173.659 148.293 171.139 149.368L175.524 165.616H161.283Z" fill="#041A2D"/>
                <path d="M187.494 116.981V99.2C187.494 88.618 178.905 79.9538 168.4 79.9538C157.902 79.9538 149.306 88.6117 149.306 99.2V116.981H142.414V98.194C142.42 83.7892 154.109 72 168.407 72C182.697 72 194.393 83.7892 194.393 98.194V116.981H187.501H187.494Z" fill="#3D4653"/>
                <path d="M190.944 100.772C190.944 87.9138 180.514 77.1873 166.635 74.6848C180.833 75.2696 192.123 84.9461 192.123 96.7982C192.123 99.2503 191.636 101.614 190.744 103.815C190.875 102.815 190.944 101.803 190.944 100.772Z" fill="#68778D"/>
            </g>
            <g clipPath="url(#clip1_242_22481)">
                <path d="M66.6089 128.241C66.8151 131.752 66.6526 132.751 67.6149 137.199C69.1082 144.098 68.9395 142.473 69.0832 143.441C69.7456 145.141 71.7076 152.623 72.4261 154.062C72.4699 156.978 72.9072 159.273 73.7633 162.822C74.7818 167.065 77.756 174.262 79.2556 178.201L82.2236 177.5C81.7924 169.646 80.6802 158.864 78.9994 147.002C77.9184 139.402 78.9619 132.348 77.781 126.181L66.6089 128.241Z" fill="#093051"/>
                <path d="M60.1795 127.509C59.4422 130.971 59.3173 134.774 59.1985 139.327C59.0111 146.381 60.217 149.601 60.1108 150.575C60.4045 152.387 60.7731 154.087 61.2043 155.65C60.442 158.498 59.9983 161.891 59.9983 165.545C59.9983 169.907 60.2608 174.845 60.8981 179.021H64.5159C66.1967 171.284 67.915 160.539 69.2647 148.609C70.1269 140.965 70.7393 133.775 71.0892 127.503H60.1795V127.509Z" fill="#0F4778"/>
                <path d="M70.827 134.681C74.8887 134.681 78.1813 132.184 78.1813 129.103C78.1813 126.023 74.8887 123.526 70.827 123.526C66.7653 123.526 63.4727 126.023 63.4727 129.103C63.4727 132.184 66.7653 134.681 70.827 134.681Z" fill="#0F4778"/>
                <path d="M63.7159 181.546H61.1729V177.829H64.1658L63.7159 181.546Z" fill="#EA9F88"/>
                <path d="M64.8093 184.784V184.53C64.8093 184.263 64.7718 184.015 64.7031 183.81L64.0782 181.322C64.0782 181.322 63.5034 181.614 62.941 181.49C62.3787 181.372 62.4037 180.559 61.8101 180.162H61.5414C61.5414 180.162 60.9915 180.348 60.6041 181.161C60.3292 181.75 60.2292 182.935 60.0543 183.32C59.8481 183.76 59.6419 184.182 59.4732 184.505C59.292 184.629 59.1483 184.821 59.067 185.057C58.8483 185.057 58.8046 185.057 59.067 185.076C59.0295 185.2 59.0045 185.33 59.0045 185.473V186.012H65.0217V185.473C65.0217 185.218 64.9467 184.983 64.8218 184.79L64.8093 184.784ZM62.6411 184.337H62.4661H62.6348H62.6411Z" fill="#093051"/>
                <path d="M82.761 180.894L80.6428 181.533L79.5493 177.985L82.0424 177.221L82.761 180.894Z" fill="#EA9F88"/>
                <path d="M78.4747 183.494L79.3932 180.925C79.3932 180.925 80.7491 181.223 81.5739 181.099C82.3986 180.975 82.3986 180.224 82.7298 179.728H83.8358C83.8358 179.728 85.2854 182.278 88.1284 182.917H90.2591C90.7527 182.917 91.1589 183.506 91.1589 184.232V184.815H82.2112L81.7613 184.033H81.5114V184.815H78.3247V184.232C78.3247 183.959 78.3809 183.705 78.4809 183.494H78.4747Z" fill="#093051"/>
                <path d="M65.0469 94.6155C65.9591 95.0498 66.9964 95.2979 68.0898 95.2979C69.352 95.2979 70.5204 94.9691 71.5264 94.4108C71.1328 93.7594 70.8954 92.9094 70.8954 91.9788C70.8954 90.707 71.339 89.5903 72.0076 88.9202V87.332H67.9399C67.7587 90.2913 66.6652 92.8846 65.0469 94.6155Z" fill="#EA9F86"/>
                <path d="M74.0698 84.9125C74.2386 82.5984 71.7017 82.3378 69.8335 82.1951C68.6338 82.1083 67.5528 82.1951 66.7655 83.3739C66.5718 83.0947 66.1344 83.591 65.8282 83.5786C65.1909 83.5538 64.7973 83.7523 64.7598 84.6395C64.7285 85.5267 65.0722 86.7737 65.7033 86.7985C65.7345 86.7985 65.7658 86.7923 65.797 86.7861C65.8157 87.5119 65.5471 88.0641 65.8657 88.6597C66.4843 89.8198 68.0152 90.7876 69.1274 91.104C72.7514 92.1463 73.9011 87.2142 74.0698 84.9001V84.9125Z" fill="#EBB190"/>
                <path d="M76.6066 82.5735C76.8065 80.7123 76.2254 79.5211 73.2199 79.1675C71.2267 78.9318 67.8401 78.9132 65.7032 79.335C63.5225 79.7631 63.8286 81.4506 63.9161 82.1827C64.1223 83.988 64.6659 86.6433 65.5532 86.9287C65.7594 86.9969 65.9343 86.9535 66.0906 86.8356C65.7969 86.6557 65.5469 86.3207 65.4282 85.8988C65.2283 85.1916 65.4845 84.5153 65.9968 84.3913C66.4155 84.292 66.8778 84.596 67.1403 85.0923C67.8151 83.8267 68.8336 82.5673 71.3454 83.7212C74.0885 84.9806 76.4191 84.4347 76.6191 82.5735H76.6066Z" fill="#1C3054"/>
                <path d="M66.6712 109.555C72.3698 109.555 77.7934 109.257 82.7233 108.717C82.4546 101.738 78.187 95.8997 72.3698 93.9331C71.4637 94.7148 70.1203 95.2111 68.6145 95.2111C66.9524 95.2111 65.4903 94.6031 64.5905 93.6787C58.2859 95.3662 53.5622 101.552 53.3872 108.99C57.5549 109.356 62.0225 109.555 66.6775 109.555H66.6712Z" fill="#1A77CA"/>
                <path d="M82.4109 108.407L82.7296 113.866C79.7553 114.567 77.8496 115.808 77.2185 117.731C76.0938 116.509 75.4502 114.462 75.2815 111.583C75.244 110.919 75.2315 109.896 75.2815 109.263L82.4109 108.407Z" fill="#EA9F87"/>
                <path d="M77.1996 117.837C77.7182 114.642 79.7739 114.04 82.3795 113.73L93.839 113.072C94.1514 112.514 94.9137 112.048 95.9072 111.806C96.0884 111.763 96.3196 112.03 96.3321 112.204C96.3759 113.004 95.8572 113.755 97.4443 113.711C98.1754 113.692 99.6313 113.606 100.412 113.463C100.606 113.426 101.281 113.438 100.868 114.027C100.225 114.958 98.6565 116.18 96.957 116.224C95.8822 116.255 95.045 115.839 94.4826 115.281C90.8148 116.906 84.1353 118.755 80.3363 118.867C79.0616 118.904 78.0181 118.556 77.1934 117.837H77.1996Z" fill="#EFB290"/>
                <path d="M60.4795 128.576C60.6857 128.576 77.4813 128.576 77.8812 128.576C78.2811 128.576 78.1999 127.472 78.1499 127.317C77.8125 126.324 77.4751 126.057 77.2501 124.934C76.9002 123.154 76.7003 121.212 76.7003 119.152C76.7003 112.638 78.6747 106.484 81.2678 102.848C79.5495 98.617 76.2379 95.3475 72.1702 93.9702C71.2642 94.7519 69.9208 95.2482 68.4149 95.2482C66.7529 95.2482 65.2907 94.6402 64.391 93.7158C59.6235 94.9876 55.7682 98.8217 54.0874 103.822C57.8489 105.466 60.6857 113.01 60.6857 122.074C60.6857 123.489 60.617 124.866 60.4795 126.194C60.4045 126.92 60.0983 127.23 60.0608 127.484C59.9546 128.204 60.267 128.582 60.4732 128.582L60.4795 128.576Z" fill="#1A77CA"/>
                <path d="M60.9541 108.457L60.7479 114.996C57.4988 115.628 55.3368 116.981 54.4683 119.239C53.3873 117.7 52.9062 115.213 53.0124 111.763C53.0374 110.963 53.1249 109.741 53.2436 108.996L60.9541 108.457Z" fill="#EA9F87"/>
                <path d="M54.437 119.369C55.3118 115.591 57.5674 115.008 60.3917 114.822H72.7135C73.1009 114.164 73.9632 113.662 75.0504 113.445C75.2503 113.407 75.469 113.736 75.4628 113.947C75.4315 114.909 74.8005 115.771 76.5 115.821C77.2873 115.845 78.8494 115.845 79.6992 115.721C79.9116 115.69 80.6302 115.752 80.1303 116.422C79.343 117.49 77.5497 118.842 75.7252 118.78C74.5693 118.743 73.7195 118.191 73.1759 117.49C69.0957 119.183 61.7601 120.939 57.6924 120.809C56.3303 120.765 55.2431 120.281 54.437 119.363V119.369Z" fill="#EFB290"/>
            </g>
            <g clipPath="url(#clip2_242_22481)">
                <path d="M84.751 90.5314C84.6593 95.3461 86.6274 99.973 90.1595 103.246C90.6127 103.662 91.1455 103.913 91.7085 103.674L97.0767 101.39C97.5763 101.178 97.7082 100.948 97.6974 100.527C97.6785 99.7998 97.5239 97.1359 97.5011 96.7522C97.4745 96.3703 97.346 95.3199 98.8685 94.6723L109.308 90.2318C109.466 90.1643 109.557 89.9711 109.554 89.7991C109.543 89.2231 109.917 88.6476 110.527 88.3879C110.999 88.1872 111.509 88.2186 111.903 88.4301C112.054 88.5109 112.293 88.5545 112.452 88.487L112.519 88.4584C112.678 88.3909 112.816 88.1871 112.896 88.0357C112.981 87.8826 113.118 87.754 113.301 87.6764C113.483 87.5988 113.671 87.5891 113.84 87.6343C114.009 87.6794 114.248 87.7231 114.406 87.6556L114.434 87.6438C114.593 87.5763 114.728 87.3781 114.773 87.2091C114.895 86.7783 115.221 86.3911 115.697 86.1887C116.312 85.9273 116.981 86.0593 117.389 86.4666C117.512 86.5919 117.709 86.6581 117.871 86.5889L118.577 86.2887C118.736 86.2213 118.898 86.0305 118.938 85.8592L119.484 83.5338C119.524 83.3625 119.436 83.1563 119.285 83.0659L117.231 81.8469C117.08 81.7565 116.83 81.7411 116.671 81.8086L96.9667 90.1905C95.4403 90.8398 94.7817 90.029 94.5251 89.7449C94.2684 89.4607 92.4566 87.5018 91.942 86.9856C91.6458 86.6855 91.3892 86.6214 90.8896 86.8339L85.5214 89.1174C84.9544 89.3586 84.7656 89.9165 84.7549 90.5297L84.751 90.5314ZM87.7002 96.7823C87.3309 95.914 87.7341 94.9137 88.6024 94.5444C89.4707 94.1751 90.4709 94.5783 90.8403 95.4466C91.2096 96.3149 90.8024 97.3168 89.9381 97.6845C89.0738 98.0521 88.0679 97.6466 87.7002 96.7823Z" fill="#C5AEEE"/>
            </g>
            <defs>
                <clipPath id="clip0_242_22481">
                    <rect width="80" height="113" fill="white" transform="translate(127 72)"/>
                </clipPath>
                <clipPath id="clip1_242_22481">
                    <rect width="48" height="107" fill="white" transform="translate(53 79)"/>
                </clipPath>
                <clipPath id="clip2_242_22481">
                    <rect width="15.8166" height="36.2519" fill="white" transform="translate(89.1909 104.745) rotate(-113.044)"/>
                </clipPath>
            </defs>
        </svg>);
}
const illustrationStyles = css({
    width: 236,
    height: 230,
});
