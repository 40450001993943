import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function StarOnIcon() {
    return (<svg className={cx(iconStyles, starOnIconStyles)} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.763 5.12403C21.4504 4.43712 20.7628 4 20.0126 4C19.1999 4 18.5122 4.43712 18.1997 5.12403L14.1988 13.4294L5.19671 14.7408C4.44654 14.8657 3.82139 15.3652 3.57134 16.1146C3.38379 16.8015 3.57134 17.6133 4.07145 18.1129L10.5729 24.5448L9.07259 33.662C8.94756 34.4113 9.26014 35.1607 9.88528 35.5978C10.5104 36.0974 11.3231 36.0974 11.9482 35.7852L20.0126 31.4764L28.0144 35.7852C28.7021 36.0974 29.5148 36.0974 30.1399 35.5978C30.765 35.1607 31.0776 34.4113 30.9526 33.662L29.3897 24.5448L35.8912 18.1129C36.4538 17.6133 36.6414 16.8015 36.3913 16.1146C36.1413 15.3652 35.5161 14.8657 34.766 14.7408L25.8264 13.4294L21.763 5.12403Z"/>
        </svg>);
}
const starOnIconStyles = css({
    width: 20,
    height: 20,
    color: themeTokens.color.icon.attention,
});
