import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
interface ExpandColumnIconProps {
    size?: "xSmall" | "small";
}
export function ExpandColumnIcon({ size = "small" }: ExpandColumnIconProps) {
    return (<svg className={size === "small" ? iconStyles : xSmallIconStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M34 9.5V30.5C34 31.375 33.3125 32 32.5 32C31.625 32 31 31.375 31 30.5V9.5C31 8.6875 31.625 8 32.5 8C33.3125 8 34 8.6875 34 9.5ZM25.5 21.125L17 29.125C16.375 29.6875 15.4375 29.625 14.875 29.0625C14.3125 28.4375 14.3125 27.5 14.9375 26.9375L20.6875 21.5H15.5H7.5C6.625 21.5 6 20.875 6 20C6 19.1875 6.625 18.5 7.5 18.5H15.5H20.6875L14.9375 13.125C14.3125 12.5625 14.3125 11.625 14.875 11C15.4375 10.375 16.375 10.375 17 10.9375L25.5 18.9375C25.8125 19.25 26 19.625 26 20C26 20.4375 25.8125 20.8125 25.5 21.125Z"/>
        </svg>);
}
const xSmallIconStyles = css({
    width: "16px",
    height: "16px",
    fill: "currentcolor",
    display: "block",
});
