import { css, cx } from "@emotion/css";
export const iconStyles = css({
    width: "24px",
    height: "24px",
    fill: "currentcolor",
    display: "block",
});
export type IconSize = 16 | 20 | 24;
export function getIconStyles(size: IconSize): string {
    switch (size) {
        case 16:
            return cx(iconBaseStyles, xSmallIconStyles);
        case 20:
            return cx(iconBaseStyles, smallIconStyles);
        case 24:
            return cx(iconBaseStyles, mediumIconStyles);
    }
}
export const iconBaseStyles = css({
    fill: "currentcolor",
    display: "block",
});
const mediumIconStyles = css({
    width: "24px",
    height: "24px",
});
const smallIconStyles = css({
    width: "20px",
    height: "20px",
});
const xSmallIconStyles = css({
    width: "16px",
    height: "16px",
});
