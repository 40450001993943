import type { CSSObject } from "@emotion/css";
const buttonResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
    boxSizing: "border-box",
};
const anchorResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
    boxSizing: "border-box",
};
const ulResetStyles: CSSObject = {
    all: "unset",
    display: "block",
    listStyle: "none",
    boxSizing: "border-box",
};
const headingResetStyles: CSSObject = {
    all: "unset",
    boxSizing: "border-box",
};
const paragraphResetStyles: CSSObject = {
    all: "unset",
    display: "block",
    boxSizing: "border-box",
};
const inputResetStyles: CSSObject = {
    all: "unset",
    boxSizing: "border-box",
};
export const resetStyles = {
    button: buttonResetStyles,
    anchor: anchorResetStyles,
    ul: ulResetStyles,
    heading: headingResetStyles,
    paragraph: paragraphResetStyles,
    input: inputResetStyles,
};
