import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export function UnbindIcon(props: SvgIconProps) {
    return (<SvgIcon viewBox="0 0 200 200" {...props}>
            <path d="M124.8,108c0.6-2.5,0.9-5,0.9-7.7c0.1-3.5-0.1-5.3-1-8.4c-0.1-0.4-0.4-0.8-0.8-1.1c-2-1.3-4.4-2-7.1-2.1&#xA;&#x9;        c-3.2,0-6.3,1.2-8.7,3.4L124.8,108z"/>
            <g>
                <path d="M167.2,163.7L32.7,36.2l-7.8,8.2l22.2,21.1l-3.6-0.1c-9.3,0-18.1,3.6-24.7,10.2C12.2,82.2,8.6,91,8.6,100.3s3.6,18.1,10.2,24.7c6.6,6.6,15.4,10.2,24.7,10.2h47.3c8.2,0,15.8-2.9,21.8-7.7l46.8,44.4L167.2,163.7z M90.8,116.4H43.5c-9,0-16.3-7.3-16.3-16.3c0-4.3,1.7-8.4,4.8-11.5c3.1-3.1,7.2-4.8,11.5-4.8l22.9-0.1l8.8,8.3c-0.6,2.5-0.9,5.1-0.9,7.7v1.3c0,2.4,0,4,0.8,6.7c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.2,0.3,0.3c2,1.3,4.4,2,7.1,2.1c3.2,0,6.2-1.2,8.6-3.3l7,6.7C96.4,115.6,93.7,116.4,90.8,116.4z"/>
                <path d="M156.5,64.9h-47.3c-8.2,0-15.8,2.9-21.7,7.6l13.9,13.1c2.3-1.3,5-2.1,7.9-2.1h47.3c4.3,0,8.4,1.7,11.5,4.8c3.1,3.1,4.8,7.2,4.8,11.5c0,9-7.3,16.3-16.3,16.3h-23l19.6,18.6h3.3c19.1-0.2,34.8-15.8,34.9-35C191.4,80.6,175.7,64.9,156.5,64.9z"/>
            </g>
        </SvgIcon>);
}
