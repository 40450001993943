import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export function InsertVariableIcon(props: SvgIconProps) {
    return (<SvgIcon viewBox="0 0 200 200" {...props}>
            <path d="M93.1 102.37V88.3q6.39-.06 9.62-3.78t3.23-16.74a106.25 106.25 0 0 1 .73-14.69 15.43 15.43 0 0 1 3.55-8 15.89 15.89 0 0 1 7.27-4.66Q122 39 130.1 39h3.11v14q-8.5 0-10.17 1.76t-1.79 6.86q-.35 14.07-.91 18.29a23.88 23.88 0 0 1-2.58 8.09q-2 3.84-7.53 7.36a20.26 20.26 0 0 1 7.59 7.56q2.37 4.4 2.79 12.66l.88 17.06a5.59 5.59 0 0 0 2.52 3.72q2 1.26 9.15 1.26v14.07h-3q-9.38 0-14.48-2.14a13.72 13.72 0 0 1-7.39-7q-2.29-4.87-2.29-15.71 0-12-.82-15.3a15 15 0 0 0-3.17-6.13q-2.4-2.8-8.91-3.04zM183.6 102.37q-6.39.06-9.62 3.81t-3.22 16.65a111.41 111.41 0 0 1-.7 14.75 15.26 15.26 0 0 1-3.52 8 15.91 15.91 0 0 1-7.27 4.66q-4.46 1.44-12.61 1.44h-3.11v-14.07q8.15 0 10-1.7t2-6.92q.35-15 1.08-19.26a23.06 23.06 0 0 1 2.93-7.95 18.78 18.78 0 0 1 6.95-6.45 23 23 0 0 1-7-6.45 19.67 19.67 0 0 1-2.7-6.89 109.18 109.18 0 0 1-1.06-13.84q-.35-10.11-.82-11.58a4.69 4.69 0 0 0-2.26-2.52Q150.82 53 143.5 53V39h3.11q9.38 0 14.48 2.14a13.73 13.73 0 0 1 7.39 7q2.29 4.87 2.29 15.71 0 12.43.91 15.65a15.18 15.18 0 0 0 3.22 5.89q2.32 2.67 8.71 2.9z"/>
            <path d="M33 53.5h11v85H33zM62 53.5h11v85H62z"/>
            <path d="M89 75v11H17V75zM89 106v11H17v-11z"/>
        </SvgIcon>);
}
