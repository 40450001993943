import { css } from "@emotion/css";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
/** Props used for the {@link CircularProgress} component */
export interface CircularProgressProps {
    /**
     * The size of the component.
     * Only two sizes are currently supported, small and large.
     */
    size: CircularProgressSize;
}
/**
 * - Docs: https://www.octopus.design/932c0f1a9/p/913f63-progress
 * - Showcase: https://components.octopus.design/?story=progress--circular
 * @param CircularProgressProps
 * @param CircularProgressProps.size - The size of the component
 * @example
 * ```tsx
 * <CircularProgressProps size="small" />
 * ```
 */
export const CircularProgress: React.FC<CircularProgressProps> = ({ size }) => {
    return <MuiCircularProgress classes={{ root: rootStyles }} role="progressbar" aria-label="Progress" size={getProgressSize(size)} variant="indeterminate" thickness={6}/>;
};
CircularProgress.displayName = "CircularProgress"
type CircularProgressSize = "small" | "large" | "container";
const circularProgressClasses = {
    root: "MuiCircularProgress-root",
};
const rootStyles = css({
    [`&.${circularProgressClasses.root}`]: {
        color: themeTokens.color.progressBar.foreground.circular,
        // If the circular progress is used in a flex container, the explicit size may by ignored due to the default flex-shrink value of 1.
        // We can disable this by setting flex-shrink to 0, so that this component is reliably sized in a flex container.
        flexShrink: 0,
    },
});
function getProgressSize(size: CircularProgressSize): string {
    switch (size) {
        case "small":
            return "1.375rem";
        case "large":
            return "2.75rem";
        case "container":
            return "100%";
    }
}
CircularProgress.displayName = "CircularProgress";
