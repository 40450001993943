import { css, cx } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import { IconButton } from "../IconButton";
export interface CarouselProps {
    slides: React.ReactElement[];
    initialSlide?: number;
    accessibleName?: string;
    onInteraction?: (slideKey: string) => void;
}
const carouselStyles = {
    carousel: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
        gap: space[24],
    }),
    carouselContentContainer: css({ overflowX: "hidden", width: "100%" }),
    carouselAnimationContainer: css({
        display: "flex",
        flexDirection: "row",
        direction: "ltr",
        transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;",
    }),
    slideContent: css({
        display: "flex",
        width: "100%",
        overflow: "auto",
        flexShrink: 0,
    }),
};
export function Carousel({ slides, initialSlide, accessibleName, onInteraction }: CarouselProps) {
    const [activeSlide, setActiveSlide] = useState<number>(initialSlide && slides[initialSlide] ? initialSlide : 0);
    const accessibleContainerName = accessibleName ?? "carousel";
    const nextSlide = () => {
        if (activeSlide < slides.length - 1) {
            jumpToSlide(activeSlide + 1);
        }
    };
    const previousSlide = () => {
        if (activeSlide > 0) {
            jumpToSlide(activeSlide - 1);
        }
    };
    const jumpToSlide = (slideNumber: number) => {
        setActiveSlide(slideNumber);
        if (onInteraction) {
            const slideKey = slides[slideNumber].key?.toString() ?? slideNumber.toString();
            onInteraction(slideKey);
        }
    };
    const transitionAnimation = cx(carouselStyles.carouselAnimationContainer, css({
        transform: `translate(-${activeSlide > 0 ? activeSlide * 100 : 0}%, 0px)`,
    }));
    return (<section className={carouselStyles.carousel} aria-label={accessibleContainerName} aria-roledescription="carousel">
            <div className={carouselStyles.carouselContentContainer}>
                <div className={transitionAnimation}>
                    {slides.map((slide, index) => (<div role="tabpanel" aria-roledescription="slide" aria-label={`slide ${index}`} key={slide.key ?? index} className={carouselStyles.slideContent} aria-hidden={index !== activeSlide}>
                            {slide}
                        </div>))}
                </div>
            </div>
            <Controller backButton={<IconButton onClick={previousSlide} icon="ChevronLeft" disabled={activeSlide === 0}/>} nextButton={<IconButton onClick={nextSlide} icon="ChevronRight" disabled={activeSlide === slides.length - 1}/>} totalSlides={slides.length} activeSlide={activeSlide} onSlideDotClick={jumpToSlide} accessibleName={accessibleContainerName}/>
        </section>);
}
interface ControllerProps {
    backButton: React.ReactNode;
    nextButton: React.ReactNode;
    totalSlides: number;
    activeSlide: number;
    onSlideDotClick: (slideNumber: number) => void;
    accessibleName?: string;
}
const controllerStyles = {
    controller: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        height: 28,
        gap: space[24],
    }),
    controllerDotContainer: css({
        display: "inline-flex",
        gap: 12,
    }),
    controllerDot: css({
        borderRadius: "50%",
        width: 12,
        height: 12,
        backgroundColor: themeTokens.color.carousel.icon.default,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.carousel.icon.hover,
        },
    }),
};
const controllerActiveDotStyles = cx(controllerStyles.controllerDot, css({
    backgroundColor: themeTokens.color.carousel.icon.selected,
}));
function Controller({ backButton, nextButton, totalSlides, activeSlide, onSlideDotClick, accessibleName }: ControllerProps) {
    const slides = [...Array(totalSlides).keys()];
    const accessibleContainerName = accessibleName ? `${accessibleName} slides` : "slides";
    return (<div className={controllerStyles.controller} role="tablist" aria-label={accessibleContainerName}>
            {backButton}
            <div className={controllerStyles.controllerDotContainer}>
                {slides.map((val) => (<span role="tab" aria-label={`slide ${val}`} aria-selected={val === activeSlide} key={val} onClick={() => onSlideDotClick(val)} className={val === activeSlide ? controllerActiveDotStyles : controllerStyles.controllerDot}></span>))}
            </div>
            {nextButton}
        </div>);
}
