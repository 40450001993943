import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export interface StepperProps {
    variant: "circular" | "text";
    currentStep: number;
    totalSteps: number;
}
export function Stepper(props: StepperProps) {
    const { variant, currentStep, totalSteps } = props;
    switch (variant) {
        case "circular":
            return <CircularStepper currentStep={currentStep} totalSteps={totalSteps}/>;
        case "text":
            return <TextStepper currentStep={currentStep} totalSteps={totalSteps}/>;
    }
}
interface StepperVariantProps {
    currentStep: number;
    totalSteps: number;
}
const circularStepperStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space[8],
    flex: "1 0 0",
});
function CircularStepper(props: StepperVariantProps) {
    const { currentStep, totalSteps } = props;
    const strokeWidth = 3;
    const circleWidth = 35;
    const radius = circleWidth / 2 - strokeWidth;
    const dashArray = radius * Math.PI * 2;
    const progressPercentage = Math.ceil((currentStep / totalSteps) * 100);
    const dashOffset = dashArray - (dashArray * progressPercentage) / 100;
    return (<div className={circularStepperStyles}>
            <svg width={circleWidth} height={circleWidth} viewBox={`0 0 ${circleWidth} ${circleWidth}`}>
                <circle cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} className={css({
            fill: themeTokens.color.icon.inverse,
        })}/>
                <circle cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} className={css({
            fill: "none",
            stroke: themeTokens.color.progress.background.secondary,
        })}/>
                <circle cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`} className={css({
            fill: "none",
            stroke: themeTokens.color.progress.background.primary,
            strokeDasharray: `${dashArray}`,
            strokeDashoffset: `${dashOffset}`,
            strokeLinecap: "round",
            strokeLinejoin: "round",
        })}/>
                <text x={"50%"} y={"50%"} dy={"0.3em"} textAnchor={"middle"} className={css({
            font: text.interface.body.bold.xSmall,
            fill: themeTokens.color.progress.text.info,
        })}>
                    {props.currentStep}/{props.totalSteps}
                </text>
            </svg>
        </div>);
}
const textStepperStyles = {
    container: css({
        display: "flex",
        alignItems: "center",
    }),
    text: css({
        font: text.interface.body.default.medium,
        color: themeTokens.color.text.secondary,
    }),
};
function TextStepper(props: StepperVariantProps) {
    const { currentStep, totalSteps } = props;
    return (<div className={textStepperStyles.container}>
            <div className={textStepperStyles.text}>
                {currentStep} of {totalSteps}
            </div>
        </div>);
}
