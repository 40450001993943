import MaterialDialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export interface DialogProps {
    open: boolean;
    dialogWidth?: "480px" | "600px" | "800px" | "1000px";
    fullScreen?: boolean;
    children?: React.ReactNode;
}
function DialogRaw(props: DialogProps) {
    const useStyles = makeStyles(() => ({
        paper: ({ dialogWidth }: DialogProps) => {
            const maxWidth = dialogWidth ?? "600px";
            return {
                maxWidth,
                width: "100%",
                backgroundColor: themeTokens.color.dialog.background.primary,
            };
        },
    }));
    const { open, children, fullScreen } = props;
    const classes = useStyles(props);
    // Dialogs are used in portals, however because react uses synthetic events, events in the dialog will bubble up the react tree rather than the dom tree.
    // This means that clicks inside the dialog can trigger click handlers on parent react components such as redirects on list rows.
    // This can be prevented by stopping the event propagation at the root of the dialog.
    const stopEventPropagation = React.useCallback((event: React.MouseEvent) => event.stopPropagation(), []);
    return (<MaterialDialog classes={{ paperWidthSm: classes.paper }} open={open || false} fullScreen={fullScreen} onMouseDown={stopEventPropagation} onMouseUp={stopEventPropagation} onClick={stopEventPropagation}>
            {open && children}
        </MaterialDialog>);
}
export const Dialog = withMobileDialog<DialogProps>()(DialogRaw);
Dialog.displayName = "Dialog";
