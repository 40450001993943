import * as React from "react";
import RadioButton from "./RadioButton";
interface BooleanRadioButtonProps {
    /**
     * The value of the radio button.
     */
    value: boolean;
    // TODO: this can be made required once we isolate usages of radio groups with a single option
    /**
     * The label of the radio button.
     */
    label?: React.ReactNode;
    /**
     * Controls whether the radio button is the default option.
     * The label of a default option will have "(default)" appended to it.
     */
    isDefault?: boolean;
    /**
     * Controls whether the radio button is disabled.
     */
    disabled?: boolean;
    /**
     * The accessible name of the radio button.
     */
    accessibleName?: string;
}
export function BooleanRadioButton({ isDefault, label, disabled, value, accessibleName }: BooleanRadioButtonProps) {
    return <RadioButton label={label} value={`${value}`} isDefault={isDefault} disabled={disabled} accessibleName={accessibleName}/>;
}
