import type { RefObject } from "react";
import { useEffect, useState } from "react";
export function useIsHovered(ref: RefObject<HTMLElement>) {
    const [isHovered, setIsHovered] = useState(false);
    useOnIsHoveredChanged(ref, setIsHovered);
    return isHovered;
}
export function useOnIsHoveredChanged(ref: RefObject<HTMLElement>, onIsHoveredChanged: ((isHovered: boolean, hoveredElement: HTMLElement) => void) | undefined) {
    useEffect(() => {
        const container = ref.current;
        if (container && onIsHoveredChanged) {
            const onMouseMove = (e: MouseEvent) => {
                if (!e.composedPath().includes(container)) {
                    onIsHoveredChanged(false, container);
                    document.removeEventListener("mousemove", onMouseMove);
                }
            };
            const onMouseEnter = () => {
                document.addEventListener("mousemove", onMouseMove);
                onIsHoveredChanged(true, container);
            };
            const onMouseLeave = () => onIsHoveredChanged(false, container);
            container.addEventListener("mouseenter", onMouseEnter);
            container.addEventListener("mouseleave", onMouseLeave);
            return () => {
                container.removeEventListener("mouseenter", onMouseEnter);
                container.removeEventListener("mouseleave", onMouseLeave);
                document.removeEventListener("mousemove", onMouseMove);
            };
        }
    }, [onIsHoveredChanged, ref]);
}
