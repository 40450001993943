import { css, cx } from "@emotion/css";
import { themeTokens, colorScales } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React, { useRef } from "react";
import { useOnIsHoveredChanged } from "../../hooks";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { useIsUrlActive, useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { resetStyles } from "../../utils/resetStyles";
import { Tooltip } from "../Tooltip/Tooltip";
import { navigationSideBarItemStyles } from "./navigationSideBarStyles";
interface SharedSideBarTabProps {
    /**
     * The icon for this link.
     */
    icon: React.ReactNode;
    /**
     * The link location.
     */
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    /**
     * The onClick handler associated with the tab.
     */
    onClick?: MouseEventHandler<Element>;
    /**
     * The hover handler associated with the tab.
     */
    onIsHoveredChanged?: (isHovered: boolean, hoveredElement: HTMLElement) => void;
}
interface NavigationSideBarTabIconOnlyProps extends SharedSideBarTabProps {
    /**
     * The required accessible name for this icon link.
     */
    accessibleName: string;
}
interface NavigationSideBarTabWithLabelProps extends SharedSideBarTabProps {
    /**
     * An optional accessible name for this icon link. If no accessible name is provided the label will be used.
     */
    accessibleName?: string;
    /**
     * The label for this icon link.
     */
    label: string;
}
type NavigationSideBarIconLinkProps = NavigationSideBarTabIconOnlyProps | NavigationSideBarTabWithLabelProps;
export function NavigationSideBarTab(props: NavigationSideBarIconLinkProps) {
    if (isNavigationSideBarTabWithLabel(props)) {
        return <NavigationSideBarTabWithLabel {...props}/>;
    }
    const tab = <NavigationSideBarTabIconOnly icon={props.icon} href={props.href} accessibleName={props.accessibleName} showLinkAsActive={props.showLinkAsActive} onClick={props.onClick}/>;
    // There's no label. Show a tooltip based on the accessibility label if possible.
    if (props.accessibleName) {
        return (<Tooltip content={props.accessibleName} style={{ width: "100%" }}>
                {tab}
            </Tooltip>);
    }
    return tab;
}
function NavigationSideBarTabWithLabel({ icon, label, showLinkAsActive, href, accessibleName, onClick, onIsHoveredChanged }: NavigationSideBarTabWithLabelProps) {
    const Link = useOctopusLinkComponent();
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    useOnIsHoveredChanged(linkRef, onIsHoveredChanged);
    return (<Link ref={linkRef} className={navigationSideBarTabWithLabelStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick}>
            {icon}
            {label}
        </Link>);
}
function NavigationSideBarTabIconOnly({ icon, showLinkAsActive, href, accessibleName, onClick, onIsHoveredChanged }: NavigationSideBarTabIconOnlyProps) {
    const Link = useOctopusLinkComponent();
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    useOnIsHoveredChanged(linkRef, onIsHoveredChanged);
    return (<Link ref={linkRef} className={navigationSideBarTabIconOnlyStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick}>
            {icon}
        </Link>);
}
function isNavigationSideBarTabWithLabel(item: NavigationSideBarIconLinkProps): item is NavigationSideBarTabWithLabelProps {
    return "label" in item && Boolean(item.label);
}
const navigationSideBarTabBaseStyles = cx(css(resetStyles.anchor, {
    "&[aria-current=page]": {
        color: themeTokens.color.text.selected,
        ":hover": {
            background: themeTokens.color.background.secondary.hovered,
        },
        "&::after": {
            content: "\"\"",
            position: "absolute",
            right: "0",
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            width: "4px",
            background: colorScales.blue["400"],
        },
    },
}), navigationSideBarItemStyles);
const navigationSideBarTabWithLabelStyles = cx(navigationSideBarTabBaseStyles, css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
}));
const navigationSideBarTabIconOnlyStyles = navigationSideBarTabBaseStyles;
