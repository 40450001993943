import type { ReactNode } from "react";
import { Children } from "react";
import { isFragment } from "react-is";
// Replaces fragments with an array of their children
export function flattenFragments(children: ReactNode) {
    return Children.map(children, (c) => {
        if (isFragment(c)) {
            return Children.toArray(c.props.children);
        }
        return c;
    })?.flat(Infinity);
}
