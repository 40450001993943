import { css, cx } from "@emotion/css";
import type { ButtonBaseActions } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiRadioButton from "@material-ui/core/Radio";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { RadioButtonGroupContext } from "./RadioButtonGroup";
interface RadioButtonBaseProps {
    className?: string;
    value?: string;
    label?: React.ReactNode;
    isDefault?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    accessibleName?: string;
    checked?: boolean;
}
export function RadioButtonBase({ className, value, label, isDefault, disabled, autoFocus, accessibleName, checked }: RadioButtonBaseProps) {
    const buttonActions = React.useRef<ButtonBaseActions>(null);
    const labelElement = isDefault ? (<>
            {label} <span className={formControlLabelDefaultStyles}>(default)</span>
        </>) : (label);
    const radioButtonGroup = React.useContext(RadioButtonGroupContext);
    const shouldAutoFocus = autoFocus || (radioButtonGroup !== undefined && value === radioButtonGroup.value && radioButtonGroup.autoFocus);
    return (<FormControlLabel control={<MuiRadioButton action={buttonActions} className={cx(radioStyles, className)} value={value} autoFocus={shouldAutoFocus} checked={checked} disableRipple disableTouchRipple disableFocusRipple/>} label={labelElement} className={formControlLabelStyles} disabled={disabled} aria-label={accessibleName}/>);
}
const formControlLabelClasses = {
    root: "MuiFormControlLabel-root",
    label: "MuiFormControlLabel-label",
};
const commonClasses = {
    checked: "Mui-checked",
    disabled: "Mui-disabled",
    focusVisible: "Mui-focusVisible",
};
const radioClasses = {
    root: "MuiRadio-root",
};
const formControlLabelStyles = css({
    [`&.${formControlLabelClasses.root}`]: {
        margin: 0,
        // This marginBottom could potentially be moved to the radio group level using a `gap` property
        // However there are usages of the group that insert arbitrary non-radio elements in such as <br /> and <Note />.
        // A CSS gap would impact the spacing of these elements and would need to be evaluated per-usage.
        marginBottom: space[4],
        font: text.interface.body.default.base,
        [`.${formControlLabelClasses.label}`]: {
            color: themeTokens.color.text.primary,
        },
        [`.${formControlLabelClasses.label}.${commonClasses.disabled}`]: {
            color: themeTokens.color.text.disabled,
        },
    },
    [`&.${formControlLabelClasses.root}.${commonClasses.disabled}`]: {
        cursor: "not-allowed",
    },
});
// 'default' here refers to the "(default)" text appended to the label
const formControlLabelDefaultStyles = css({
    color: themeTokens.color.text.secondary,
    [`.${commonClasses.disabled} &`]: {
        color: themeTokens.color.text.disabled,
    },
});
const radioStyles = css({
    [`&.${radioClasses.root}`]: {
        padding: 0,
        marginRight: space[8],
        color: themeTokens.color.toggle.icon.default,
        ":hover": {
            backgroundColor: "unset",
        },
    },
    [`&.${radioClasses.root}.${commonClasses.checked}`]: {
        color: themeTokens.color.toggle.icon.active,
        ":hover": {
            backgroundColor: "unset",
        },
    },
    [`&.${radioClasses.root}.${commonClasses.disabled}`]: {
        color: themeTokens.color.toggle.icon.disabled,
    },
    [`&.${radioClasses.root}.${commonClasses.focusVisible}`]: {
        boxShadow: themeTokens.shadow.focused,
    },
});
