import { css, cx } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CircleExclamationIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36C28.8125 36 36 28.875 36 20C36 11.1875 28.8125 4 20 4C11.125 4 4 11.1875 4 20C4 28.875 11.125 36 20 36ZM20 12C20.8125 12 21.5 12.6875 21.5 13.5V20.5C21.5 21.375 20.8125 22 20 22C19.125 22 18.5 21.375 18.5 20.5V13.5C18.5 12.6875 19.125 12 20 12ZM22 26C22 27.125 21.0625 28 20 28C18.875 28 18 27.125 18 26C18 24.9375 18.875 24 20 24C21.0625 24 22 24.9375 22 26Z"/>
        </svg>);
}
const styles = cx(iconStyles, css({
    fill: colorScales.red[600],
}));
