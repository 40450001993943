import { css, cx } from "@emotion/css";
import { space, text, themeTokens, colorScales, borderRadius, fontSize } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React, { Fragment } from "react";
import { useIsLargerThanIpadResolution } from "../../hooks";
import type { DesignSystemLinkHref } from "../../routing";
import { useOctopusLinkComponent } from "../../routing";
import { Divider } from "../Divider";
import type { PageHeaderPrimaryLogo } from "./PageHeaderPrimary";
export interface LeadingSegmentProps {
    logo?: PageHeaderPrimaryLogo;
    title: string;
    titleIcon?: ReactElement;
    titleChips?: ReactElement[];
    logoLinkURL?: DesignSystemLinkHref;
}
export function LeadingSegment({ logo, logoLinkURL, title, titleIcon, titleChips }: LeadingSegmentProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const Link = useOctopusLinkComponent();
    return (<div className={cx(leadingSegmentStyles, { [leadingSegmentMobileStyles]: !isLargerThanIpad })}>
            {logo &&
            (logoLinkURL ? (<Link href={logoLinkURL} className={cx(logoLinkStyles, logoContainerStyles)}>
                        <div className={logoLinkHoverStyles}>Edit</div>
                        <img className={logoStyles} src={logo.href} alt={logo.accessibleName}/>
                    </Link>) : (<div className={logoContainerStyles}>
                        <img className={logoStyles} src={logo.href} alt={logo.accessibleName}/>
                    </div>))}
            <div className={titleContainerStyles}>
                <h2 title={title} className={titleStyles}>
                    {title}
                </h2>
                {titleIcon}
            </div>
            {titleChips && (<div className={cx(chipsWrapperStyles, { [chipsWrapperMobileStyles]: !isLargerThanIpad })}>
                    {isLargerThanIpad && <Divider orientation={"vertical"}/>}
                    {titleChips.map((chip, index) => (<Fragment key={index}>{chip}</Fragment>))}
                </div>)}
        </div>);
}
const leadingSegmentStyles = css({
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    gap: space["12"],
});
const leadingSegmentMobileStyles = css({
    gap: space["16"],
    flexWrap: "wrap",
});
const logoLinkStyles = css({
    position: "relative",
    "&:hover > div": {
        opacity: "1",
    },
});
const logoContainerStyles = css({
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: borderRadius.small,
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    background: colorScales.white,
});
const logoLinkHoverStyles = css({
    fontSize: fontSize.xSmall,
    position: "absolute",
    zIndex: "1",
    width: "100%",
    height: "100%",
    display: "flex",
    opacity: "0",
    justifyContent: "center",
    alignItems: "center",
    color: colorScales.white,
    borderRadius: borderRadius.extraSmall,
    backgroundColor: `${colorScales.navy["900"]}BF`,
    transition: "opacity 150ms ease-in-out",
});
const logoStyles = css({
    borderRadius: borderRadius.extraSmall,
    objectFit: "scale-down",
    width: "100%",
    height: "100%",
});
const titleContainerStyles = css({
    flex: "1",
    display: "flex",
    gap: space["12"],
    alignItems: "center",
    overflow: "hidden",
});
const titleStyles = css({
    all: "unset",
    font: text.interface.heading.medium,
    color: themeTokens.color.text.primary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});
const chipsWrapperStyles = css({
    display: "flex",
    gap: space["12"],
});
const chipsWrapperMobileStyles = css({
    flexBasis: "100%",
});
