import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { type AriaAttributes, forwardRef } from "react";
import { resetStyles } from "../../utils/resetStyles";
import { navigationSideBarItemStyles } from "./navigationSideBarStyles";
export type NavigationSideBarButtonAriaAttributes = Pick<AriaAttributes, "aria-controls" | "aria-haspopup" | "aria-expanded">;
export interface NavigationBarIconButtonProps extends NavigationSideBarButtonAriaAttributes {
    onClick?: React.MouseEventHandler;
    accessibleName: string;
    icon: React.ReactNode;
}
export const NavigationSideBarIconButton = forwardRef<HTMLButtonElement, NavigationBarIconButtonProps>(({ onClick, accessibleName, icon, ...otherProps }, ref) => {
    const ariaAttributes = getNavigationButtonAriaAttributes(otherProps);
    return (<button className={navigationSideBarIconButtonStyles} onClick={onClick} aria-label={accessibleName} ref={ref} {...ariaAttributes}>
            {icon}
        </button>);
});
export function getNavigationButtonAriaAttributes<T extends NavigationSideBarButtonAriaAttributes>(props: T): NavigationSideBarButtonAriaAttributes {
    const ariaAttributes: NavigationSideBarButtonAriaAttributes = {
        ["aria-controls"]: props["aria-controls"],
        ["aria-haspopup"]: props["aria-haspopup"],
        ["aria-expanded"]: props["aria-expanded"],
    };
    return ariaAttributes;
}
const navigationBarBaseButtonStyles = cx(css(resetStyles.button, {
    "&[aria-expanded=true]": {
        background: themeTokens.color.navigation.link.background.active,
    },
}), navigationSideBarItemStyles);
NavigationSideBarIconButton.displayName = "NavigationSideBarIconButton";
const navigationSideBarIconButtonStyles = navigationBarBaseButtonStyles;
