import { css } from "@emotion/css";
import { Switch as MuiSwitch, FormControlLabel as MuiFormLabel } from "@material-ui/core";
import { themeTokens, text } from "@octopusdeploy/design-system-tokens";
import type { ChangeEvent } from "react";
import * as React from "react";
interface SharedSwitchProps {
    /**
     * Controls whether the switch is disabled.
     */
    disabled?: boolean;
    /**
     * The current value of the switch.
     */
    value: boolean;
    /**
     * The action to perform when the value is changed.
     */
    onChange?: (value: boolean) => void;
    /**
     * Controls whether the checkbox should be automatically focused.
     */
    autoFocus?: boolean;
    /**
     * The accessible name of the switch.
     */
    accessibleName?: string;
    /**
     * A property that controls the size of the switch.
     */
    size?: "small" | "medium";
}
interface SwitchProps extends SharedSwitchProps {
    /**
     * The label to display alongside the switch.
     */
    label?: string;
}
export function Switch({ label, value, disabled, onChange, autoFocus, accessibleName, size }: SwitchProps) {
    if (label) {
        return <SwitchWithLabel autoFocus={autoFocus} size={size} onChange={onChange} label={label} disabled={disabled} value={value} accessibleName={accessibleName}/>;
    }
    return <StandaloneSwitch size={size} autoFocus={autoFocus} onChange={onChange} value={value} disabled={disabled} accessibleName={accessibleName}/>;
}
type StandaloneSwitchProps = SharedSwitchProps;
function StandaloneSwitch({ value, size, onChange, disabled, autoFocus, accessibleName }: StandaloneSwitchProps) {
    const changeHandler = React.useCallback((event: ChangeEvent<HTMLInputElement>, checked) => {
        onChange?.(checked);
    }, [onChange]);
    return (<MuiSwitch inputProps={{ "aria-label": accessibleName }} autoFocus={autoFocus} disableRipple={true} color={"primary"} classes={{ thumb: switchThumbStyles, track: switchTrackStyles, colorPrimary: colorPrimaryStyles }} checked={value} onChange={changeHandler} disabled={disabled} size={size}/>);
}
const switchClasses = {
    root: "MuiSwitch-root",
    thumb: "MuiSwitch-thumb",
    track: "MuiSwitch-track",
    checked: "Mui-checked",
    colorPrimary: "MuiSwitch-colorPrimary",
    disabled: "Mui-disabled",
};
const switchCheckedClass = `${switchClasses.colorPrimary}.${switchClasses.checked}`;
const switchDisabledClass = `${switchClasses.colorPrimary}.${switchClasses.disabled}`;
const colorPrimaryStyles = css({
    [`&.${switchCheckedClass}`]: {
        color: themeTokens.color.toggle.foreground.on,
        "&:hover": {
            backgroundColor: themeTokens.color.toggle.overlay.on,
        },
    },
    [`&.${switchClasses.colorPrimary}`]: {
        color: themeTokens.color.toggle.foreground.off,
        "&:hover": {
            backgroundColor: themeTokens.color.toggle.overlay.off,
        },
    },
});
const switchThumbStyles = css({
    [`.${switchDisabledClass} &.${switchClasses.thumb}`]: {
        color: themeTokens.color.toggle.foreground.disabled,
    },
});
const switchTrackStyles = css({
    [`.${switchCheckedClass} + &.${switchClasses.track}`]: {
        backgroundColor: themeTokens.color.toggle.background.on,
        opacity: 1,
    },
    [`.${switchDisabledClass} + &.${switchClasses.track}`]: {
        backgroundColor: themeTokens.color.toggle.background.disabled,
        opacity: 1,
    },
    [`&.${switchClasses.track}`]: {
        backgroundColor: themeTokens.color.toggle.background.off,
        opacity: 1,
    },
});
interface SwitchWithLabelProps extends SharedSwitchProps {
    label: string;
}
function SwitchWithLabel({ onChange, size, label, value, disabled, autoFocus, accessibleName }: SwitchWithLabelProps) {
    return <MuiFormLabel classes={{ label: labelStyles }} control={<StandaloneSwitch size={size} autoFocus={autoFocus} onChange={onChange} value={value} disabled={disabled} accessibleName={accessibleName}/>} label={label}/>;
}
const labelClasses = {
    label: "MuiFormControlLabel-label",
    disabled: "Mui-disabled",
};
const labelStyles = css({
    [`.${labelClasses.label}&`]: {
        font: text.interface.body.default.base,
        color: themeTokens.color.text.primary,
    },
    [`.${labelClasses.label}.${labelClasses.disabled}&`]: {
        color: themeTokens.color.text.disabled,
    },
});
