import type { ReactNode } from "react";
import React, { useEffect, useMemo, useState } from "react";
interface PageTitleSetters {
    setPrimarySegment: (value: string | undefined) => void;
    setSecondarySegment: (value: string | undefined) => void;
}
const PageTitleSettersContext = React.createContext<PageTitleSetters | undefined>(undefined);
interface PageTitleContextProps {
    children: ReactNode;
}
export function PageTitleProvider({ children }: PageTitleContextProps) {
    const [primarySegment, setPrimarySegment] = useState<string | undefined>(undefined);
    const [secondarySegment, setSecondarySegment] = useState<string | undefined>(undefined);
    usePageTitleFromPageHeaders(primarySegment, secondarySegment);
    const pageTitleSetters: PageTitleSetters = useMemo(() => ({
        setPrimarySegment,
        setSecondarySegment,
    }), []);
    return <PageTitleSettersContext.Provider value={pageTitleSetters}>{children}</PageTitleSettersContext.Provider>;
}
function usePageTitleSetters() {
    const contextValue = React.useContext(PageTitleSettersContext);
    if (contextValue === undefined) {
        throw new Error("PageTitleSetters context value has not been provided via a PageTitleProvider");
    }
    return contextValue;
}
export function useSetPageTitlePrimarySegmentEffect(title: string | undefined) {
    const { setPrimarySegment } = usePageTitleSetters();
    useEffect(() => {
        setPrimarySegment(title);
        return () => {
            setPrimarySegment(undefined);
        };
    }, [setPrimarySegment, title]);
}
export function useSetPageTitleSecondarySegmentEffect(title: string | undefined) {
    const { setSecondarySegment } = usePageTitleSetters();
    useEffect(() => {
        setSecondarySegment(title);
        return () => {
            setSecondarySegment(undefined);
        };
    }, [setSecondarySegment, title]);
}
export function usePageTitleFromPageHeaders(primarySegment: string | undefined, secondarySegment: string | undefined) {
    useEffect(() => {
        const segments = [primarySegment, secondarySegment, "Octopus Deploy"].filter((value) => value !== undefined && value !== StringHelper.ellipsis);
        const pageTitle = segments.join(" - ");
        document.title = pageTitle;
    }, [primarySegment, secondarySegment]);
}
// This matches the StringHelper.ellipsis from Portal
const StringHelper = {
    ellipsis: "...",
};
