import * as React from "react";
import type { FormFieldProps } from "../FormFieldProps";
import type { RadioButtonGroupProps } from "./RadioButtonGroup";
import { RadioButtonGroup } from "./RadioButtonGroup";
interface BooleanRadioButtonGroupProps extends Omit<RadioButtonGroupProps<string>, keyof FormFieldProps<string>>, FormFieldProps<boolean> {
}
export function BooleanRadioButtonGroup({ value, onChange, ...otherProps }: React.PropsWithChildren<BooleanRadioButtonGroupProps>) {
    const stringValue = `${value}`;
    const onStringChange = React.useCallback((stringValue: string) => {
        onChange?.(stringValue === "true");
    }, [onChange]);
    return <RadioButtonGroup value={stringValue} onChange={onStringChange} {...otherProps}/>;
}
