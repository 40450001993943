import React from "react";
import { iconStyles } from "./iconStyles";
interface XMarkIconProps {
    size?: 20 | 24;
}
export function XMarkIcon({ size }: XMarkIconProps) {
    return (<svg className={iconStyles} style={{ width: size, height: size }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M29.6875 29.75C29.3125 30.125 28.625 30.125 28.25 29.75L20 21.4375L11.6875 29.75C11.3125 30.125 10.625 30.125 10.25 29.75C9.875 29.375 9.875 28.6875 10.25 28.3125L18.5625 20L10.25 11.75C9.875 11.375 9.875 10.6875 10.25 10.3125C10.625 9.9375 11.3125 9.9375 11.6875 10.3125L20 18.625L28.25 10.3125C28.625 9.9375 29.3125 9.9375 29.6875 10.3125C30.0625 10.6875 30.0625 11.375 29.6875 11.75L21.375 20L29.6875 28.3125C30.0625 28.6875 30.0625 29.375 29.6875 29.75Z"/>
        </svg>);
}
