import { darkTheme, darkV2Theme, lightTheme, lightV2Theme } from "@octopusdeploy/design-system-tokens";
import type { ThemeName } from "../Theme";
export function getTheme(themeName: ThemeName) {
    switch (themeName) {
        case "light":
            return lightTheme;
        case "dark":
            return darkTheme;
        case "lightV2":
            return lightV2Theme;
        case "darkV2":
            return darkV2Theme;
        default:
            return lightTheme;
    }
}
