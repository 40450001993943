import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { NavigationBarLink } from "./NavigationBarLink";
export interface NavigationBarItemProps {
    item: NavigationBarItemData;
}
export interface NavigationBarLinkItemData {
    /**
     * The type of navigation item.
     */
    type: "link";
    /**
     * The label of the link.
     */
    label: string;
    /**
     * The href of the link.
     */
    href: DesignSystemLinkHref;
    /**
     * Controls when to style the link as active.
     */
    showLinkAsActive?: ShowLinkAsActive;
}
export interface NavigationBarCustomItemData {
    /**
     * The type of navigation item.
     */
    type: "custom";
    /**
     * A locally unique identifier for the custom item.
     */
    key: string;
    /**
     * A custom react element to use for the item.
     */
    content: React.ReactElement;
    /**
     * A link item to use in the case where the custom content cannot be displayed.
     * This can happen when navigation items are collapsed into a dropdown menu, for example on mobile.
     */
    fallbackLink?: Omit<NavigationBarLinkItemData, "type">;
}
export type NavigationBarItemData = NavigationBarLinkItemData | NavigationBarCustomItemData;
export function NavigationBarItem({ item }: NavigationBarItemProps) {
    const { type } = item;
    switch (type) {
        case "link":
            return <NavigationBarLink label={item.label} href={item.href} showLinkAsActive={item.showLinkAsActive}/>;
        case "custom":
            return item.content;
        default:
            exhaustiveCheck(type, `Unsupported navigation bar item type "${type}"`);
    }
}
