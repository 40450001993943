import { css, cx } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function ArrowLeftIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <title>Arrow Left</title>
            <path d="M4.46829 18.9062L12.96 10.4688C13.522 9.84375 14.4585 9.84375 15.0205 10.4688C15.6449 11.0312 15.6449 11.9688 15.0205 12.5312L9.08878 18.4688H34.5015C35.3132 18.4688 36 19.1562 36 19.9688C36 20.8438 35.3132 21.4688 34.5015 21.4688L9.08878 21.5312L15.0205 27.4688C15.6449 28.0312 15.6449 28.9688 15.0205 29.5312C14.4585 30.1562 13.522 30.1562 12.96 29.5312L4.46829 21.0312C3.8439 20.4688 3.8439 19.5312 4.46829 18.9062Z"/>
        </svg>);
}
const styles = cx(iconStyles, css({
    width: 20,
    height: 20,
}));
