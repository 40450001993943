import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
const maxDrawerWidth = "975px";
export const drawerContainerStyles = css({
    // Using `&` twice to increase specificity
    "&&": {
        backgroundColor: themeTokens.color.background.primary.default,
        width: "90%",
        maxWidth: maxDrawerWidth,
        overflowY: "visible",
        color: themeTokens.color.text.primary,
    },
});
export const drawerHeaderStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: space["16"],
});
export const drawerTitleStyles = css({
    font: text.interface.heading.medium,
    WebkitFontSmoothing: "antialiased",
    margin: 0,
});
export const drawerContentStyles = css({
    overflow: "auto",
});
export const drawerActionListStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: space["16"],
});
