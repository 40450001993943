import { css } from "@emotion/css";
import { borderRadius, themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode } from "react";
import React, { useState } from "react";
import { resetStyles } from "../../utils";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { ActionButton, ActionButtonType } from "../Button/ActionButton";
import { Callout, type CalloutType } from "../Callout/Callout";
import { FilterIcon } from "../Icon";
import type { SimpleMenuItem } from "../MenuItems/SimpleMenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions/PageActions";
import { PageHeaderSecondary } from "../PageHeaderSecondary";
import { LinearProgress } from "../Progress/LinearProgress";
export interface Level2PageContentProps {
    header: {
        title: string;
        titleComplementaryText?: string;
        logo?: ReactElement;
        chip?: ReactElement;
        contextSelector?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        showBreadcrumbBackIcon?: boolean;
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    busy: boolean;
    callout?: {
        title?: ReactNode;
        content: ReactNode;
        type: CalloutType;
        onClose?: () => void;
    };
    errors?: Error[];
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            isResetEnabled: boolean;
            isExpandedByDefault?: boolean;
        };
    };
    pagination?: {
        ui: ReactNode;
        alsoShowInFooter?: boolean;
    };
    sections: ReactNode[];
    sidebar?: ReactNode;
}
export function Level2PageContent({ header, busy, callout, errors, filters, pagination, sections, sidebar }: Level2PageContentProps) {
    const [isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] = useState(filters?.advancedFilters?.isExpandedByDefault ?? false);
    const hasAdvancedFilters = filters?.advancedFilters !== undefined;
    const hasFilters = (filters?.inputs && filters.inputs.length > 0) || hasAdvancedFilters;
    const hasFilterAndPaginationSection = hasFilters || filters?.filtersSummary !== undefined || pagination !== undefined;
    return (<div className={pageContentStyles.container}>
            <LinearProgress variant={"indeterminate"} show={busy}/>
            {callout && (<Callout type={callout.type} title={callout.title} hideTitle={!callout.title} onClose={callout.onClose} canClose={!!callout.onClose}>
                    {callout.content}
                </Callout>)}
            {errors?.map((error, index) => (<ErrorPanel key={index} error={error}/>))}
            <PageHeaderSecondary breadcrumbsItems={header.breadcrumbs} showBreadcrumbBackIcon={header.showBreadcrumbBackIcon} title={header.title} titleComplementaryText={header.titleComplementaryText} titleChip={header.chip} titleAccessory={header.contextSelector} titleStatusIcon={header.logo} primaryAction={header.primaryAction} pageActions={header.pageActions} overflowActions={header.overflowActions}/>
            {hasFilterAndPaginationSection && (<div className={pageContentStyles.filtersAndPaginationSection}>
                    <div className={pageContentStyles.filtersAndPaginationRow}>
                        {hasFilters && <Filters inputs={filters.inputs} showAdvancedFiltersToggle={hasAdvancedFilters} isAdvancedFiltersVisible={isAdvancedFiltersVisible} onAdvancedFiltersVisibilityChanged={setIsAdvancedFiltersVisible}/>}
                        {pagination?.ui}
                    </div>
                    {filters?.filtersSummary && <FiltersSummary summary={filters.filtersSummary}/>}
                </div>)}
            <div className={pageContentStyles.sectionsWithSidebars}>
                {filters?.advancedFilters && isAdvancedFiltersVisible && <AdvancedFilters content={filters.advancedFilters.content} onResetFilter={filters.advancedFilters.onResetFilter} isResetEnabled={filters.advancedFilters.isResetEnabled}/>}
                <div className={pageContentStyles.contentBox}>{sections}</div>
                {sidebar && <Sidebar content={sidebar}/>}
            </div>
            {pagination?.alsoShowInFooter && <div className={pageContentStyles.footerPaginationSection}>{pagination.ui}</div>}
        </div>);
}
const pageContentStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        borderRadius: borderRadius.small,
        border: `1px solid ${themeTokens.color.border.primary}`,
        gap: space[8],
        minHeight: 640,
        maxWidth: 1280,
        marginLeft: "auto",
        marginRight: "auto",
        font: text.interface.body.default.medium,
    }),
    filtersAndPaginationSection: css({
        padding: `${space[8]} ${space[16]}`,
        gap: space[8],
    }),
    filtersAndPaginationRow: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    sectionsWithSidebars: css({
        display: "flex",
        flex: 1,
    }),
    contentBox: css({
        flex: 1,
    }),
    footerPaginationSection: css({
        display: "flex",
        justifyContent: "flex-end",
        padding: space[16],
    }),
};
interface ErrorPanelProps {
    error: Error;
}
function ErrorPanel({ error }: ErrorPanelProps) {
    // This split is specific to V8 engine error stack formatting
    const stack = error.stack?.split(" at ") ?? [];
    return (<Callout title={error.message} hideTitle={!error.message} type="danger">
            {stack.length > 0 && (<ul className={errorPanelStyles.list}>
                    {stack.map((detail, index) => (<li key={index}>{detail}</li>))}
                </ul>)}
        </Callout>);
}
const errorPanelStyles = {
    list: css({
        ...resetStyles.ul,
    }),
};
interface FiltersProps {
    inputs: ReactNode[];
    showAdvancedFiltersToggle: boolean;
    isAdvancedFiltersVisible: boolean;
    onAdvancedFiltersVisibilityChanged: (isVisible: boolean) => void;
}
function Filters({ inputs, showAdvancedFiltersToggle, isAdvancedFiltersVisible, onAdvancedFiltersVisibilityChanged }: FiltersProps) {
    return (<div className={filtersStyles.container}>
            {inputs}
            {showAdvancedFiltersToggle && (<ActionButton type={ActionButtonType.Ternary} label={`${isAdvancedFiltersVisible ? "Hide" : "Show"} advanced filters`} icon={<FilterIcon />} onClick={() => {
                onAdvancedFiltersVisibilityChanged(!isAdvancedFiltersVisible);
            }}/>)}
        </div>);
}
function FiltersSummary({ summary }: {
    summary: ReactNode;
}) {
    return <div className={filtersStyles.summary}>{summary}</div>;
}
const filtersStyles = {
    container: css({
        display: "flex",
        flexWrap: "wrap",
        columnGap: space[16],
        alignItems: "center",
    }),
    summary: css({
        padding: `0 ${space[16]}`,
    }),
};
interface AdvancedFilterProps {
    content: ReactNode;
    onResetFilter: () => void;
    isResetEnabled: boolean;
}
function AdvancedFilters({ content, onResetFilter, isResetEnabled }: AdvancedFilterProps) {
    return (<div className={advancedFiltersStyles.container}>
            <div className={advancedFiltersStyles.header}>
                <h4 className={advancedFiltersStyles.heading}>Advanced filter</h4>
                <ActionButton disabled={!isResetEnabled} label="Reset" type={ActionButtonType.Secondary} onClick={() => onResetFilter()}/>
            </div>
            {content}
        </div>);
}
const advancedFiltersStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        padding: `${space[8]} ${space[16]}`,
        gap: space[8],
        width: "17.5rem",
        borderRight: `1px solid ${themeTokens.color.border.primary}`,
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: space[16],
    }),
    heading: css({
        ...resetStyles.heading,
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
};
interface SidebarProps {
    content: ReactNode;
}
function Sidebar({ content }: SidebarProps) {
    return <div className={sidebarStyles.container}>{content}</div>;
}
const sidebarStyles = {
    container: css({
        width: "17.5rem",
        borderLeft: `1px solid ${themeTokens.color.border.primary}`,
    }),
};
