import { css, cx } from "@emotion/css";
import { MenuItem } from "@material-ui/core";
import { space } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React from "react";
import { menuItemClasses, menuItemStyles } from "../menuItemStyles";
export interface MenuItemInformationProps {
    compact?: boolean;
}
export function MenuItemInformation({ children, compact }: PropsWithChildren<MenuItemInformationProps>) {
    const classes = cx(menuItemInformationStyles, compact ? compactMenuItemStyles : nonCompactMenuItemStyles);
    return (<MenuItem component={"div"} role={"none"} disabled={true} className={classes}>
            {children}
        </MenuItem>);
}
MenuItemInformation.displayName = "MenuItemInformation";
const menuItemInformationStyles = cx(menuItemStyles, css({
    [`&.${menuItemClasses.root}`]: {
        // These information items aren't quite like single line buttons, but can instead have wrapping text content
        whiteSpace: "inherit",
        "&.Mui-disabled": {
            // Material UI sets a lower opacity on the menu items if they are disabled.
            // In this case, we don't really have anything that is "disabled" so we opt out of this style
            opacity: "inherit",
            "&.MuiButtonBase-root": {
                // Allow normal pointer events because we aren't trying to prevent button clicks, text selection, etc.
                pointerEvents: "inherit",
            },
        },
        [`&.${menuItemClasses.button}`]: {
            // Allow text selection, because why not?
            userSelect: "inherit",
            "&:hover": {
                backgroundColor: "inherit",
            },
            // MUI MenuItems have a tabIndex of -1 so this informational div menu item is focusable. We have to manually override this style so it doesn't look focused.
            "&:focus": {
                backgroundColor: "inherit",
            },
        },
    },
}));
const compactMenuItemStyles = css({
    [`&.${menuItemClasses.root}`]: {
        minHeight: "auto",
    },
});
const nonCompactMenuItemStyles = css({
    [`&.${menuItemClasses.root}`]: {
        minHeight: space[48], // copied from material-ui's base styles, that would otherwise be overriden by a media query
    },
});
