import { css, cx } from "@emotion/css";
import { Divider as MuiDivider } from "@material-ui/core";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
type DividerOrientation = "horizontal" | "vertical";
export interface DividerProps {
    /**
     * The orientation of the divider.
     * @remarks The vertical orientation requires a parent container using
     * a flexbox display to be visible.
     */
    orientation?: DividerOrientation;
}
export function Divider({ orientation = "horizontal" }: DividerProps) {
    //We default the divider to show as a flex item when the orientation is vertical. This allows the divider
    //to work in a flexbox layout without the consumer to do anything else. This means that we don't technically
    //support the divider with a vertical orientation outside of a flexbox layout. It should be noted that even
    //if we didn't enable this, a fixed height would be required for the vertical divider to be shown.
    return <MuiDivider classes={{ root: cx(rootDividerStyles, getDividerOrientationStyles(orientation)) }} orientation={orientation} flexItem={orientation === "vertical"}/>;
}
const dividerClasses = {
    root: "MuiDivider-root",
};
const rootDividerStyles = css({
    [`&.${dividerClasses.root}`]: {
        padding: 0,
        margin: 0,
    },
});
const horizontalDividerStyles = css({
    [`&.${dividerClasses.root}`]: {
        backgroundColor: "unset",
        borderTop: `solid 1px ${themeTokens.color.border.primary}`,
        height: "1px",
    },
});
const verticalDividerStyles = css({
    [`&.${dividerClasses.root}`]: {
        backgroundColor: themeTokens.color.border.primary,
    },
});
function getDividerOrientationStyles(orientation: DividerOrientation) {
    return orientation === "vertical" ? verticalDividerStyles : horizontalDividerStyles;
}
