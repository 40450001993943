import { css, cx } from "@emotion/css";
import { MenuItem, useForkRef } from "@material-ui/core";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren, Ref } from "react";
import React, { forwardRef, useEffect, useRef } from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../../routing/OctopusRoutingContext";
import { useIsUrlActive, useOctopusLinkComponent } from "../../../routing/OctopusRoutingContext";
import { menuItemClasses, menuItemStyles } from "../menuItemStyles";
export interface MenuItemInternalLinkProps {
    path: DesignSystemLinkHref;
    label: string;
    shortDescription?: string;
    icon?: JSX.Element;
    showLinkAsActive?: ShowLinkAsActive;
    onClick?: () => void;
    autoFocus?: boolean;
    size?: LinkSize;
}
export const MenuItemInternalLink = forwardRef(({ path, label, onClick, autoFocus, size = "default", icon, shortDescription, showLinkAsActive = "never" }: MenuItemInternalLinkProps, ref: Ref<HTMLAnchorElement>) => {
    // We can't use the default li component that MenuItem renders here, because the parent element (CustomMenu or MenuList) is not a ul
    return <MenuItem component={LinkWrapper} path={path} label={label} onClick={onClick} showLinkAsActive={showLinkAsActive} autoFocus={autoFocus} size={size} ref={ref} icon={icon} shortDescription={shortDescription}/>;
});
MenuItemInternalLink.displayName = "MenuItemInternalLink";
interface LinkWrapperProps {
    path: DesignSystemLinkHref;
    icon: JSX.Element | undefined;
    onClick: undefined | (() => void);
    label: string;
    shortDescription: string | undefined;
    showLinkAsActive: ShowLinkAsActive;
    className: string;
    autoFocus: boolean | undefined;
    size: LinkSize;
}
const LinkWrapper = forwardRef(({ path, showLinkAsActive, label, onClick, className, children, autoFocus, size, icon, shortDescription, ...other }: PropsWithChildren<LinkWrapperProps>, ref: Ref<HTMLAnchorElement>) => {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(path, showLinkAsActive);
    const sizeClass = size === "compact" ? compactStyles : size === "default" ? defaultSizeStyles : spaciousStyles;
    const classes = cx(className, menuItemLinkStyles, sizeClass);
    const focusRef = useRef<HTMLAnchorElement | null>(null);
    const combinedRef = useForkRef(focusRef, ref);
    useEffect(() => {
        if (autoFocus) {
            focusRef.current?.focus();
        }
    }, [autoFocus]);
    // Ideally we would wrap an anchor tag in an li like in this example: https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
    // <li role="none"><a role="menuitem">My Link</a></li>
    // But if we do that, we lose out on keyboard navigation support from material ui's MenuList component
    // Instead, we want to render an anchor tag so that we get the right native browser link behaviours,
    // but make it appear like a menuitem for the accessibility tree
    return (
    // eslint-disable-next-line react/forbid-elements
    <Link aria-disabled={undefined} role={"menuItem"} aria-label={label} href={path} aria-current={isActive ? "page" : undefined} onClick={onClick} tabIndex={-1} className={classes} ref={combinedRef} {...other}>
            {icon && <div className={iconStyles}>{icon}</div>}
            <div className={textContainerStyles}>
                <div className={labelStyles}>{label}</div>
                {shortDescription && <div className={descriptionStyles}>{shortDescription}</div>}
            </div>
            {/*The children is the ripple effect*/}
            {children}
        </Link>);
});
LinkWrapper.displayName = "LinkWrapper";
export type LinkSize = "default" | "compact" | "spacious";
const menuItemLinkStyles = cx(menuItemStyles, css({
    [`&.${menuItemClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        "&:hover": {
            color: "inherit",
        },
        "&[aria-current=page]": {
            color: themeTokens.color.text.selected,
        },
    },
}));
const compactStyles = css({
    [`&.${menuItemClasses.root}`]: {
        minHeight: "auto",
    },
});
const defaultSizeStyles = css({
    [`&.${menuItemClasses.root}`]: {
        minHeight: space[48],
    },
});
const spaciousStyles = css({
    [`&.${menuItemClasses.root}`]: {
        minHeight: space[64],
        paddingTop: space[12],
        paddingBottom: space[12],
    },
});
const iconStyles = css({
    paddingRight: space[8],
});
const labelStyles = css({
    wordBreak: "break-all",
    whiteSpace: "normal",
});
const textContainerStyles = css({
    display: "flex",
    flexDirection: "column",
});
const descriptionStyles = css({
    font: text.navigation.link.medium,
    wordBreak: "break-all",
    whiteSpace: "normal",
    color: themeTokens.color.text.tertiary,
});
