import { css, cx } from "@emotion/css";
import { List as MaterialList } from "@material-ui/core";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface RenderListRowProps<T> {
    /**
     * The current item being rendered.
     */
    item: T;
    /*
     * The index of the current item being rendered.
     */
    index: number;
}
export interface ListProps<T> {
    /**
     * An optional empty value to display when the list is empty.
     */
    empty?: React.ReactNode;
    /**
     * The items to use for the list.
     */
    items: ReadonlyArray<T>;
    /**
     * A render function which defines how the row should be displayed.
     */
    renderRow: (props: RenderListRowProps<T>) => React.ReactNode;
    /**
     * An optional function which defines the react key to use for each row.
     * Every row should have a unique row key and we default to the index of the row
     * for legacy reasons. Please treat this prop as required for any new usages.
     */
    rowKey?: (item: T) => string;
    /***
     * A boolean flag indicating whether vertical padding should be included above
     * and below this list.
     * @deprecated we do not want to include vertical padding as part of our components, as such this
     * prop will be removed in the near future. Padding should be handled in consumers, possibly using
     * layout components.
     */
    includeVerticalPadding?: boolean;
    /***
     * A boolean flag indicating whether the list should contain gaps between rows. This can be useful
     * when the items in the list are bordered and some space is required between each item.
     */
    includeRowGaps?: boolean;
    /**
     * The accessible name of the List component.
     */
    accessibleName?: string;
}
export function List<T>({ items, rowKey, renderRow, empty, includeRowGaps, includeVerticalPadding, accessibleName }: ListProps<T>) {
    if (items.length === 0) {
        return <>{empty}</>;
    }
    return (<MaterialList disablePadding classes={{ root: cx(listRootStyles, { [listRootPaddingStyles]: includeVerticalPadding, [listRootGapStyles]: includeRowGaps }) }} role="list" aria-label={accessibleName}>
            {items.map((item, index) => (<React.Fragment key={rowKey?.(item) ?? index}>{renderRow({ item, index })}</React.Fragment>))}
        </MaterialList>);
}
const materialListClasses = {
    root: "MuiList-root",
};
const listRootStyles = css({
    [`&.${materialListClasses.root}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
    },
});
const listRootPaddingStyles = css({
    [`&.${materialListClasses.root}`]: {
        paddingTop: space[8],
        paddingBottom: space[8],
    },
});
const listRootGapStyles = css({
    [`&.${materialListClasses.root}`]: {
        rowGap: space[16],
    },
});
