import MaterialMenuList from "@material-ui/core/MenuList";
import type { PropsWithChildren } from "react";
import React from "react";
import { flattenFragments } from "../../utils/flattenFragments";
export interface MenuListProps {
    accessibleName: string;
}
export function MenuList({ children, accessibleName }: PropsWithChildren<MenuListProps>) {
    // Material-ui MenuLists don't support fragments as children
    // This is so that they can inspect the props of their children in order to work out which one is selected
    // This is using a feature of MenuLists (selection) that we don't use, so for our usages we can allow fragments
    const childrenWithoutFragments = flattenFragments(children);
    return (<>
            {/*We disable autoFocus here because this feature relies on
        material-ui's menu cloning its children and adding an autoFocus prop.
        This kind of coupling is brittle and limits the kinds of things that can be children of the menu.

        Instead of doing this, we think it better to opt-out of this feature
        and let the children of the menu control which child is automatically focused first.

        By using the SimpleMenuItems component as a child of the menu, this behaviour is automatically provided in a less brittle way.

        Focusing on an element inside the menu upon opening the menu is particularly important,
        because the keyboard interactivity that is implemented in material-ui's menu relies on a child being focused.

        Another important constraint here is that the material-ui menu renders a ul,
        and the immediate descendants of the ul must be focusable.
        This maps to the children that are passed in. Without this, keyboard navigation won't work.
        They also must have an explicit tabIndex property defined and can't rely on the browser defaults.
        */}
            <MaterialMenuList component={"div"} autoFocus={false} aria-label={accessibleName}>
                {childrenWithoutFragments}
            </MaterialMenuList>
        </>);
}
MenuList.displayName = "MenuList";
