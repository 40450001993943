import type { AriaAttributes } from "react";
import React, { forwardRef } from "react";
import { navigationBarTextButtonStyles } from "./navigationBarStyles";
export type NavigationBarButtonAriaAttributes = Pick<AriaAttributes, "aria-controls" | "aria-haspopup" | "aria-expanded">;
export interface NavigationBarButtonProps extends NavigationBarButtonAriaAttributes {
    onClick?: React.MouseEventHandler;
    accessibleName: string;
    label: string;
    icon?: React.ReactNode;
}
export const NavigationBarButton = forwardRef<HTMLButtonElement, NavigationBarButtonProps>(({ onClick, accessibleName, label, icon, ...otherProps }, ref) => {
    const ariaAttributes = getNavigationButtonAriaAttributes(otherProps);
    return (<button className={navigationBarTextButtonStyles} onClick={onClick} aria-label={accessibleName} ref={ref} {...ariaAttributes}>
            {label}
            {icon}
        </button>);
});
NavigationBarButton.displayName = "NavigationBarButton";
export function getNavigationButtonAriaAttributes<T extends NavigationBarButtonAriaAttributes>(props: T): NavigationBarButtonAriaAttributes {
    const ariaAttributes: NavigationBarButtonAriaAttributes = {
        ["aria-controls"]: props["aria-controls"],
        ["aria-haspopup"]: props["aria-haspopup"],
        ["aria-expanded"]: props["aria-expanded"],
    };
    return ariaAttributes;
}
