import React, { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Snackbar } from "./Snackbar";
export type ShowSnackbar = (snackbarMessage: string) => void;
const showSnackbarContext = createContext<ShowSnackbar | null>(null);
export function GlobalSnackbarProvider({ children }: {
    children: ReactNode;
}) {
    const [open, setOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState<string | null>(null);
    const [messageQueue, setMessageQueue] = useState<string[]>([]);
    const showSnackbar = useCallback<ShowSnackbar>((snackbarMessage: string) => {
        setMessageQueue((prev) => [...prev, snackbarMessage]);
    }, []);
    // effect to enqueue the next message
    useEffect(() => {
        if (messageQueue.length === 0)
            return;
        if (currentMessage === null) {
            // show the next message
            const nextMessage = messageQueue[0];
            setMessageQueue((prev) => prev.slice(1));
            setCurrentMessage(nextMessage);
            setOpen(true);
        }
        else {
            // start the transition to close the current message.
            // The currentMessage will be set to null when the message transition completes, which re-fires this effect and shows the next message in the queue
            setOpen(false);
        }
    }, [currentMessage, messageQueue]);
    return (<showSnackbarContext.Provider value={showSnackbar}>
            <Snackbar open={open} content={currentMessage} autoHideDuration={3500} onClose={() => setOpen(false)} textAlign={"center"} onExited={() => setCurrentMessage(null)}/>
            {children}
        </showSnackbarContext.Provider>);
}
export function useShowSnackbar() {
    const showSnackbar = useContext(showSnackbarContext);
    if (showSnackbar === null) {
        throw new Error("SnackbarProvider not found");
    }
    return showSnackbar;
}
