import { css } from "@emotion/css";
import { Popper } from "@material-ui/core";
import * as React from "react";
import type { BeaconBaseProps } from "./BeaconBase";
import { BeaconBase } from "./BeaconBase";
export type BeaconPlacementType = "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
export interface BeaconProps extends BeaconBaseProps {
    placement: BeaconPlacementType;
    anchorElement: HTMLElement | null;
    open: boolean;
    beaconOffset?: BeaconOffset;
}
export interface BeaconOffset {
    x: number;
    y: number;
}
export function Beacon({ placement, anchorElement, open, size, ripple, onClick, beaconOffset }: BeaconProps) {
    const onBeaconClicked = () => {
        onClick?.();
    };
    return (<Popper placement={placement} open={open} anchorEl={anchorElement} className={css({ zIndex: 9999 })} modifiers={{
            flip: {
                enabled: false,
            },
            offset: { enabled: true, offset: beaconOffset === undefined ? "0,0" : `${beaconOffset.x},${beaconOffset.y}` },
        }}>
            <BeaconBase size={size} ripple={ripple} onClick={onBeaconClicked}></BeaconBase>
        </Popper>);
}
