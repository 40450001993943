import React from "react";
import { iconStyles } from "./iconStyles";
interface ChevronDownIconProps {
    size?: 20 | 24;
}
export function ChevronDownIcon({ size }: ChevronDownIconProps) {
    return (<svg className={iconStyles} style={{ width: size, height: size }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M18.5625 29.4375C19.3125 30.25 20.625 30.25 21.375 29.4375L33.375 17.4375C34.1875 16.6875 34.1875 15.375 33.375 14.625C32.625 13.8125 31.3125 13.8125 30.5625 14.625L20 25.1875L9.375 14.625C8.625 13.8125 7.3125 13.8125 6.5625 14.625C5.75 15.375 5.75 16.6875 6.5625 17.4375L18.5625 29.4375Z"/>
        </svg>);
}
