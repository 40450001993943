import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export function FormFieldTitle({ title }: {
    title: string;
}) {
    return <div className={formFieldTitleStyles}>{title}</div>;
}
const formFieldTitleStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.interface.body.default.small,
    marginBottom: space[4],
});
