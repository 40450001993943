import React from "react";
import { iconStyles } from "./iconStyles";
export function TenantsIcon() {
    return (<svg className={iconStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M18 16C16.8954 16 16 16.8954 16 18V22C16 23.1046 16.8954 24 18 24H22C23.1046 24 24 23.1046 24 22V18C24 16.8954 23.1046 16 22 16H18Z"/>
            <path d="M16 6C16 4.89543 16.8954 4 18 4H22C23.1046 4 24 4.89543 24 6V10C24 11.1046 23.1046 12 22 12H18C16.8954 12 16 11.1046 16 10V6Z"/>
            <path d="M18 28C16.8954 28 16 28.8954 16 30V34C16 35.1046 16.8954 36 18 36H22C23.1046 36 24 35.1046 24 34V30C24 28.8954 23.1046 28 22 28H18Z"/>
            <path d="M4 18C4 16.8954 4.89543 16 6 16H10C11.1046 16 12 16.8954 12 18V22C12 23.1046 11.1046 24 10 24H6C4.89543 24 4 23.1046 4 22V18Z"/>
            <path d="M30 16C28.8954 16 28 16.8954 28 18V22C28 23.1046 28.8954 24 30 24H34C35.1046 24 36 23.1046 36 22V18C36 16.8954 35.1046 16 34 16H30Z"/>
        </svg>);
}
