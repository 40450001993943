import { css, cx } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
interface CircleCheckIconProps {
    size: 16 | 20 | 24;
}
export function CircleCheckIcon({ size }: CircleCheckIconProps) {
    const sizeStyles = css({
        width: size,
        height: size,
    });
    return (<svg className={cx(iconStyles, sizeStyles)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0312 7C15.3438 7 11.0938 9.5 8.71875 13.5C6.40625 17.5625 6.40625 22.5 8.71875 26.5C11.0938 30.5625 15.3438 33 20.0312 33C24.6562 33 28.9062 30.5625 31.2812 26.5C33.5938 22.5 33.5938 17.5625 31.2812 13.5C28.9062 9.5 24.6562 7 20.0312 7ZM20.0312 36C14.2812 36 9.03125 33 6.15625 28C3.28125 23.0625 3.28125 17 6.15625 12C9.03125 7.0625 14.2812 4 20.0312 4C25.7188 4 30.9688 7.0625 33.8438 12C36.7188 17 36.7188 23.0625 33.8438 28C30.9688 33 25.7188 36 20.0312 36ZM27.0938 17.0625L19.0938 25.0625C18.4688 25.6875 17.5312 25.6875 16.9688 25.0625L12.9688 21.0625C12.3438 20.5 12.3438 19.5625 12.9688 19C13.5312 18.375 14.4688 18.375 15.0938 19L18.0312 21.9375L24.9688 15C25.5312 14.375 26.4688 14.375 27.0938 15C27.6562 15.5625 27.6562 16.5 27.0938 17.0625Z"/>
        </svg>);
}
