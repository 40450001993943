import { css } from "@emotion/css";
import MuiLinearProgress from "@material-ui/core/LinearProgress";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
/** Props used for the {@link IndeterminateLinearProgress} component */
export interface IndeterminateLinearProgressVariantProps {
    /**
     * The visibility of the component.
     *
     * When this is false, the progress bar is hidden and replaced with an empty space of the same size.
     * This is to prevent the layout shifting as a result of showing or hiding the progress bar.
     */
    show: boolean;
    /**
     * The linear progress variant.
     *
     * The indeterminate progress variant should be used when an action's overall completion can not be determined
     * such as loading data in the background.
     */
    variant: "indeterminate";
}
/** Props used for the {@link DeterminateLinearProgress} component */
export interface DeterminateLinearProgressVariantProps extends DeterminateLinearProgressProps {
    /**
     * The visibility of the component.
     *
     * When this is false, the progress bar is hidden and replaced with an empty space of the same size.
     * This is to prevent the layout shifting as a result of showing or hiding the progress bar.
     */
    show: boolean;
    /**
     * The linear progress variant.
     *
     * The indeterminate progress variant should be used when an action's overall completion can not be determined
     * such as loading data in the background.
     */
    variant: "determinate";
}
/** Props used for the {@link LinearProgress} component */
export type LinearProgressProps = IndeterminateLinearProgressVariantProps | DeterminateLinearProgressVariantProps;
/**
 * - Docs: https://www.octopus.design/932c0f1a9/p/913f63-progress
 * - Showcase: https://components.octopus.design/?story=progress--linear
 * ```tsx
 * <LinearProgress show={true} />
 * ```
 */
export function LinearProgress(props: LinearProgressProps) {
    if (props.show) {
        if (isDeterminateProgressProps(props)) {
            return <DeterminateLinearProgress value={props.value}/>;
        }
        return <IndeterminateLinearProgress />;
    }
    return <div className={hiddenSpacerStyle}/>;
}
export interface DeterminateLinearProgressProps {
    value: number;
}
function DeterminateLinearProgress(props: DeterminateLinearProgressProps) {
    return <MuiLinearProgress role="progressbar" variant={"determinate"} classes={{ bar: barStyles, root: rootStyles }} aria-label={"Progress"} value={props.value} aria-valuenow={props.value} aria-valuemin={0} aria-valuemax={100}/>;
}
function IndeterminateLinearProgress() {
    return <MuiLinearProgress role="progressbar" variant={"indeterminate"} classes={{ bar: barStyles, root: rootStyles }} aria-label={"Progress"}/>;
}
function isDeterminateProgressProps(props: LinearProgressProps): props is DeterminateLinearProgressVariantProps {
    return props.variant === "determinate";
}
LinearProgress.displayName = "LinearProgress";
const linearProgressClasses = {
    root: "MuiLinearProgress-root",
    bar: "MuiLinearProgress-bar",
};
const barStyles = css({
    [`&.${linearProgressClasses.bar}`]: {
        backgroundColor: themeTokens.color.progressBar.foreground.linear,
    },
});
const rootStyles = css({
    [`&.${linearProgressClasses.root}`]: {
        width: "100%",
        backgroundColor: themeTokens.color.progressBar.background.linear,
    },
});
const hiddenSpacerStyle = css({
    width: "100%",
    height: "0.25rem", //This matches <LinearProgress> height so we avoid jankiness from show/hide.
});
