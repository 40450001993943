import debounce from "lodash.debounce";
import { useMemo } from "react";
import useLatest from "./useLatest";
export function useDebouncedCallback(callback: () => void, waitMilliseconds: number): ReturnType<typeof debounce<() => void>> {
    const callbackRef = useLatest(callback);
    return useMemo(() => {
        const func = () => {
            callbackRef.current?.();
        };
        return debounce(func, waitMilliseconds);
    }, [callbackRef, waitMilliseconds]);
}
