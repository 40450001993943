import * as React from "react";
import { RadioButtonBase } from "./RadioButtonBase";
interface RadioButtonProps<T extends string> {
    /**
     * The classname to apply to the radio button group.
     *
     * @deprecated Please avoid using this. This property will be removed in the near future.
     */
    className?: string;
    /**
     * The value of the radio button.
     */
    value: T;
    /**
     * The label of the radio button.
     */
    label: React.ReactNode;
    /**
     * Controls whether the radio button is the default option.
     * The label of a default option will have "(default)" appended to it.
     */
    isDefault?: boolean;
    /**
     * Controls whether the radio button is disabled.
     */
    disabled?: boolean;
    /**
     * Controls whether the radio button should be automatically focused.
     */
    autoFocus?: boolean;
    /**
     * The accessible name of the radio button.
     */
    accessibleName?: string;
}
export function RadioButton<T extends string>({ isDefault, label, disabled, accessibleName, className, value, autoFocus }: RadioButtonProps<T>) {
    return <RadioButtonBase className={className} label={label} value={value} isDefault={isDefault} disabled={disabled} autoFocus={autoFocus} accessibleName={accessibleName}/>;
}
export default RadioButton;
