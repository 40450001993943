import React from "react";
import { iconStyles } from "./iconStyles";
interface ChevronUpIconProps {
    size?: 20 | 24;
}
export function ChevronUpIcon({ size }: ChevronUpIconProps) {
    return (<svg className={iconStyles} style={{ width: size, height: size }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M18.5625 10.625C19.3125 9.8125 20.625 9.8125 21.375 10.625L33.375 22.625C34.1875 23.375 34.1875 24.6875 33.375 25.4375C32.625 26.25 31.3125 26.25 30.5625 25.4375L20 14.875L9.375 25.4375C8.625 26.25 7.3125 26.25 6.5625 25.4375C5.75 24.6875 5.75 23.375 6.5625 22.625L18.5625 10.625Z"/>
        </svg>);
}
