import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { useOctopusLinkComponent, useIsUrlActive } from "../../routing/OctopusRoutingContext";
import { navigationBarTextLinkStyles } from "./navigationBarStyles";
export interface NavigationBarLinkProps {
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    label: string;
    accessibleName?: string;
}
export function NavigationBarLink({ href, showLinkAsActive, label, accessibleName }: NavigationBarLinkProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    return (<Link className={navigationBarTextLinkStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName}>
            {label}
        </Link>);
}
