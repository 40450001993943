import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { resetStyles } from "../../utils/resetStyles";
export interface NavigationBarActionProps {
    items: NavigationBarActionData[];
}
export interface NavigationBarActionData {
    /**
     * A locally unique identifier for the action.
     */
    key: string;
    /**
     * A custom react element to use for the action.
     */
    content: React.ReactElement;
}
export function NavigationBarActions({ items }: NavigationBarActionProps) {
    return <ul className={ulStyles}>{items.map((item) => renderActionItem(item))}</ul>;
}
function renderActionItem(item: NavigationBarActionData) {
    if (!item.content) {
        return null;
    }
    return <li key={item.key}>{item.content}</li>;
}
const ulStyles = css({
    ...resetStyles.ul,
    display: "flex",
    alignItems: "center",
    gap: space[16],
});
