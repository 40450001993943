import { css, cx } from "@emotion/css";
import { ListItem as MaterialListItem } from "@material-ui/core";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export interface ListItemProps {
    /**
     * A boolean flag which indicates whether the current list item is selected.
     */
    isSelected?: boolean;
}
export function ListItem({ children, isSelected }: React.PropsWithChildren<ListItemProps>) {
    return <MaterialListItem className={cx(listItemStyles, { [selectedListItemStyles]: isSelected })}>{children}</MaterialListItem>;
}
const listItemClasses = {
    root: "MuiListItem-root",
};
const listItemStyles = css({
    [`&.${listItemClasses.root}`]: {
        color: themeTokens.color.text.primary,
        display: "block",
        padding: "0",
        "&:hover": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
        //Some consumers like expanders can shift their content, which can hide dividers i.e. audit page.
        overflow: "hidden",
    },
    [`&.${listItemClasses.root} + &.${listItemClasses.root}`]: {
        borderTop: `solid 1px ${themeTokens.color.border.primary}`,
    },
});
const selectedListItemStyles = css({
    [`&.${listItemClasses.root}`]: {
        backgroundColor: themeTokens.color.background.primary.hovered,
    },
});
