import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function StarOffIcon() {
    return (<svg className={cx(iconStyles, starOffIconStyles)} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9687 4C20.5941 4 21.0944 4.37466 21.3445 4.8742L25.6595 13.6786L35.2274 15.1148C35.7902 15.1773 36.228 15.5519 36.4156 16.1139C36.6032 16.6759 36.4781 17.2379 36.0404 17.675L29.0989 24.5437L30.7248 34.2224C30.8499 34.7843 30.5998 35.4088 30.162 35.721C29.6617 36.0332 29.0364 36.0956 28.5361 35.8459L19.9687 31.2251L11.4639 35.8459C10.9011 36.0956 10.3383 36.0332 9.83798 35.721C9.40023 35.3463 9.15008 34.7843 9.27516 34.2224L10.9011 24.5437L3.95964 17.675C3.52189 17.2379 3.39682 16.6759 3.58442 16.1139C3.77203 15.6144 4.20978 15.1773 4.7726 15.1148L14.3405 13.6786L18.6555 4.8742C18.9056 4.37466 19.4059 4 19.9687 4ZM19.9687 8.93299L16.7169 15.7393C16.4667 16.1764 16.0915 16.4886 15.5912 16.551L8.21205 17.6126L13.5276 22.9202C13.9028 23.2949 14.0904 23.7944 13.9653 24.2315L12.7146 31.7246L19.2808 28.2278C19.7186 27.9781 20.2814 27.9781 20.7192 28.2278L27.2854 31.7246L26.0347 24.2939C25.9096 23.7944 26.0972 23.2949 26.4724 22.9826L31.7879 17.6126L24.4088 16.551C23.9085 16.4886 23.5333 16.1764 23.2831 15.7393L19.9687 8.93299Z"/>
        </svg>);
}
const starOffIconStyles = css({
    width: 20,
    height: 20,
    color: themeTokens.color.icon.secondary,
});
