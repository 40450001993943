import React from "react";
type HTMLAnchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;
export interface DesignSystemLinkProps extends Omit<HTMLAnchorAttributes, "href"> {
    href?: DesignSystemLinkHref;
}
/**
 * This is a copy paste of the Url from portal-routes.
 * We don't want to import this from portal-routes, nor have other packages import this from the design system.
 * We currently have Url as an intermediate object generated from routes which helps us switch between legacy and new routing.
 * To support portal passing around Urls as the href of links, we also need to support it here in the design system.
 * This is more ergonomic compared to needing to resolve the string href from the Url any time the design system needs a link.
 * Eventually there may be no need for this Url object and we can revert this back to a plain link with href as a string.
 */
export interface DesignSystemUrl {
    resolveWithSpaceId: (currentSpaceId: string | null) => string;
}
/**
 * This is a copy of LinkHref from portal-routes.
 * This allows us to use it in the design system while ownership of the type remains in portal-routes.
 */
export type DesignSystemLinkHref = string | DesignSystemUrl;
interface OctopusRoutingContextValue {
    Link: ReturnType<typeof React.forwardRef<HTMLAnchorElement, DesignSystemLinkProps>>;
    useIsUrlActive: () => (path: DesignSystemLinkHref, exact: boolean | undefined) => boolean;
}
const OctopusRoutingContext = React.createContext<OctopusRoutingContextValue | undefined>(undefined);
export interface OctopusRoutingProviderProps extends OctopusRoutingContextValue {
    children: React.ReactNode;
}
export function OctopusRoutingProvider({ Link, useIsUrlActive, children }: OctopusRoutingProviderProps) {
    const contextValue = React.useMemo(() => ({
        Link,
        useIsUrlActive,
    }), [Link, useIsUrlActive]);
    return <OctopusRoutingContext.Provider value={contextValue}>{children}</OctopusRoutingContext.Provider>;
}
export function useOctopusLinkComponent() {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    return contextValue.Link;
}
export function useIsUrlActive(): (path: DesignSystemLinkHref, showLinkAsActive: ShowLinkAsActive) => boolean {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    const isUrlActive = contextValue.useIsUrlActive();
    return (path, showLinkAsActive) => {
        if (showLinkAsActive === "never") {
            return false;
        }
        if (showLinkAsActive === "always") {
            return true;
        }
        return isUrlActive(path, showLinkAsActive === "if path matches exactly");
    };
}
export type ShowLinkAsActive = "never" | "if path matches exactly" | "if path partially matches" | "always";
