import { css } from "@emotion/css";
import React from "react";
import { useIllustrationThemeName } from "../../Theme";
export function EmptyStateTenantsIllustration() {
    const currentTheme = useIllustrationThemeName();
    return currentTheme === "light" ? <IllustrationInLightTheme /> : <IllustrationInDarkTheme />;
}
function IllustrationInDarkTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 474 462" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M211.153 13.6366C282.586 -7.96488 343.275 71.2634 389.383 130.01C434.957 188.076 479.808 258.149 458.573 328.874C438.246 396.573 362.317 425.874 294.574 445.78C241.578 461.352 189.174 445.437 138.761 422.85C85.7014 399.076 12.1835 376.457 10.0362 318.292C7.92003 260.971 99.2472 256.046 130.836 208.194C171.495 146.603 140.568 34.9817 211.153 13.6366Z" fill="#1F303F"/>
            <path opacity="0.5" d="M279.891 132.256C297.563 142.43 297.494 158.887 279.685 169.13C261.875 179.373 233.063 179.441 215.322 169.267C197.581 159.092 197.719 142.635 215.529 132.392C233.407 122.149 262.219 122.081 279.891 132.256Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M264.695 140.45C274.184 145.913 274.115 154.722 264.557 160.185C254.999 165.648 239.596 165.716 230.176 160.253C220.686 154.859 220.755 145.981 230.313 140.518C239.871 135.056 255.274 134.987 264.695 140.45Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path opacity="0.08" d="M252.931 166.816L276.986 152.886L247.69 136.011L223.562 149.87L252.931 166.816Z" fill="#282828"/>
            <path d="M246.252 153.82V163.011L216.883 146.065V136.873L246.252 153.82Z" fill="#AED0EB"/>
            <path d="M246.252 153.82L270.307 139.89L241.01 122.943L216.883 136.874L246.252 153.82Z" fill="#C5E6FF"/>
            <path d="M226.648 134.36L239.358 141.684L242.589 139.889L226.648 134.001V134.36Z" fill="#7AB2CE"/>
            <path d="M226.648 134.001L239.358 141.397L242.589 139.53L229.808 132.134L226.648 134.001Z" fill="#91C5E5"/>
            <path d="M224.138 127.539C223.635 128.759 223.922 129.693 225.143 130.411L247.188 143.192C247.977 143.695 249.342 143.049 249.701 142.043L255.445 123.446C256.235 121.435 255.23 120.214 254.368 119.712L232.539 107.146C231.749 106.643 230.816 106.571 230.241 107.935L224.138 127.539Z" fill="#9CC4E6"/>
            <path d="M223.489 127.323C222.987 128.544 223.274 129.477 224.495 130.195L246.539 142.977C247.329 143.48 248.693 142.833 249.053 141.828L254.797 123.23C255.587 121.22 254.582 119.999 253.72 119.496L231.891 106.93C231.101 106.428 230.167 106.356 229.593 107.72L223.489 127.323Z" fill="#B2DAF7"/>
            <path d="M225.498 128.544L246.609 140.679L253 121.435L231.961 109.3L225.498 128.544Z" fill="#F5F3F4"/>
            <path d="M253 121.435L246.609 140.679L225.498 128.544L227.581 122.297L231.961 109.3L242.085 115.116L253 121.435Z" fill="#EBF6FF"/>
            <path d="M241.586 115.403L240.939 117.414L237.492 127.467L228.086 122.081L231.461 111.956L232.179 109.946L241.586 115.403Z" fill="white"/>
            <path d="M241.586 115.403L240.939 117.414L231.461 111.956L232.179 109.946L241.586 115.403Z" fill="#EADEFC"/>
            <path d="M234.835 128.687L234.978 128.256L227.51 123.876L227.295 124.379L234.835 128.687Z" fill="#EADEFC"/>
            <path d="M234.548 129.477L234.692 129.046L227.224 124.666L227.08 125.169L234.548 129.477Z" fill="#EADEFC"/>
            <path d="M229.808 124.738L230.168 123.66L228.013 122.44L227.654 123.517L229.808 124.738Z" fill="#EADEFC"/>
            <path d="M232.178 126.102L232.537 125.025L230.382 123.804L230.023 124.882L232.178 126.102Z" fill="#C5E6FF"/>
            <path d="M234.621 127.538L234.98 126.461L232.826 125.24L232.467 126.246L234.621 127.538Z" fill="#EADEFC"/>
            <path d="M237.062 128.903L237.421 127.826L235.267 126.605L234.908 127.682L237.062 128.903Z" fill="#C5E6FF"/>
            <path d="M244.169 117.557L241.871 116.265L241.297 118.132L243.595 119.424L244.169 117.557Z" fill="#C5E6FF"/>
            <path d="M246.683 119.065L244.457 117.701L243.811 119.568L246.108 120.86L246.683 119.065Z" fill="#C5E6FF"/>
            <path d="M249.267 120.501L246.969 119.208L246.395 121.004L248.62 122.296L249.267 120.501Z" fill="#C5E6FF"/>
            <path d="M251.782 121.937L249.485 120.645L248.91 122.512L251.136 123.804L251.782 121.937Z" fill="#C5E6FF"/>
            <path d="M243.237 120.214L241.012 118.849L240.365 120.716L242.663 122.009L243.237 120.214Z" fill="#EADEFC"/>
            <path d="M245.751 121.65L243.525 120.358L242.879 122.153L245.177 123.517L245.751 121.65Z" fill="#EADEFC"/>
            <path d="M248.335 123.086L246.037 121.794L245.463 123.589L247.689 124.953L248.335 123.086Z" fill="#EADEFC"/>
            <path d="M250.847 124.594L248.549 123.23L247.975 125.097L250.272 126.389L250.847 124.594Z" fill="#EADEFC"/>
            <path d="M242.448 122.871L240.151 121.507L239.576 123.374L241.802 124.667L242.448 122.871Z" fill="#C5E6FF"/>
            <path d="M244.96 124.307L242.662 123.015L242.088 124.81L244.314 126.174L244.96 124.307Z" fill="#C5E6FF"/>
            <path d="M247.546 125.743L245.248 124.451L244.602 126.318L246.899 127.61L247.546 125.743Z" fill="#C5E6FF"/>
            <path d="M250.059 127.251L247.761 125.887L247.115 127.754L249.413 129.046L250.059 127.251Z" fill="#C5E6FF"/>
            <path d="M241.511 125.456L239.213 124.163L238.639 125.958L240.865 127.323L241.511 125.456Z" fill="#EADEFC"/>
            <path d="M244.029 126.892L241.731 125.6L241.156 127.467L243.382 128.759L244.029 126.892Z" fill="#EADEFC"/>
            <path d="M246.612 128.4L244.314 127.036L243.668 128.903L245.966 130.195L246.612 128.4Z" fill="#EADEFC"/>
            <path d="M249.124 129.836L246.826 128.543L246.252 130.339L248.478 131.703L249.124 129.836Z" fill="#EADEFC"/>
            <path d="M239.932 130.052L237.635 128.759L236.988 130.626L239.286 131.919L239.932 130.052Z" fill="#C5E6FF"/>
            <path d="M242.448 131.56L240.151 130.195L239.576 132.062L241.802 133.355L242.448 131.56Z" fill="#C5E6FF"/>
            <path d="M244.96 132.996L242.734 131.703L242.088 133.498L244.386 134.863L244.96 132.996Z" fill="#C5E6FF"/>
            <path d="M247.546 134.432L245.248 133.139L244.602 135.006L246.899 136.299L247.546 134.432Z" fill="#C5E6FF"/>
            <path d="M238.999 132.708L236.701 131.344L236.127 133.211L238.353 134.503L238.999 132.708Z" fill="#EADEFC"/>
            <path d="M241.511 134.144L239.213 132.852L238.639 134.647L240.865 136.011L241.511 134.144Z" fill="#EADEFC"/>
            <path d="M244.1 135.58L241.802 134.288L241.156 136.083L243.454 137.447L244.1 135.58Z" fill="#EADEFC"/>
            <path d="M246.612 137.088L244.314 135.724L243.668 137.591L245.966 138.883L246.612 137.088Z" fill="#EADEFC"/>
            <path d="M228.228 129.405L228.587 128.328L226.433 127.107L226.074 128.184L228.228 129.405Z" fill="#EADEFC"/>
            <path d="M230.6 130.769L230.959 129.764L228.804 128.472L228.445 129.549L230.6 130.769Z" fill="#C5E6FF"/>
            <path d="M233.039 132.206L233.398 131.129L231.244 129.908L230.885 130.913L233.039 132.206Z" fill="#EADEFC"/>
            <path d="M235.48 133.57L235.839 132.493L233.685 131.272L233.326 132.35L235.48 133.57Z" fill="#C5E6FF"/>
            <path d="M246.252 153.82V163.011L270.307 149.08V139.889L246.252 153.82Z" fill="#76A1C2"/>
            <path d="M246.252 155.328V156.333L216.883 139.387V138.382L246.252 155.328Z" fill="#416F91"/>
            <path d="M249.339 153.532L246.252 155.328V163.011L247.473 162.293L249.339 153.532Z" fill="#416F91"/>
            <path d="M245.535 157.913C245.535 157.985 245.535 158.056 245.463 158.128C245.391 158.2 245.248 158.272 245.104 158.128L225.788 146.998C225.645 146.926 225.501 146.711 225.429 146.567C225.357 146.495 225.357 146.352 225.357 146.28C225.357 146.136 225.429 146.065 225.501 145.993C225.573 145.921 225.717 145.921 225.788 145.993L245.104 157.123C245.32 157.338 245.535 157.697 245.535 157.913Z" fill="#274B66"/>
            <path d="M245.462 158.2C245.39 158.271 245.246 158.343 245.103 158.2L225.787 147.07C225.643 146.998 225.5 146.782 225.428 146.639L225.499 146.567C225.571 146.495 225.715 146.495 225.787 146.567L245.103 157.697C245.246 157.841 245.39 157.984 245.462 158.2Z" fill="#76A1C2"/>
            <path d="M227.44 150.086C227.44 150.732 226.937 151.02 226.435 150.661C225.86 150.302 225.357 149.512 225.357 148.865C225.357 148.219 225.86 147.932 226.435 148.291C227.009 148.65 227.44 149.44 227.44 150.086Z" fill="#0D80D8"/>
            <path d="M226.506 150.23L226.291 150.158V148.793L226.506 148.865V150.23Z" fill="white"/>
            <path opacity="0.08" d="M273.968 158.63L260.684 166.314L271.024 172.274L284.38 164.59L273.968 158.63Z" fill="#282828"/>
            <path d="M267.508 160.857V170.264L280.864 162.58V153.174L267.508 160.857Z" fill="#76A1C2"/>
            <path d="M270.953 165.452C270.953 165.596 270.881 165.811 270.737 165.811L268.081 167.319C267.937 167.391 267.865 167.319 267.865 167.176V165.883C267.865 165.74 267.937 165.524 268.081 165.524L270.737 164.016C270.881 163.944 270.953 164.016 270.953 164.16V165.452Z" fill="#173B56"/>
            <path d="M267.508 160.857V170.264L257.168 164.304V154.897L267.508 160.857Z" fill="#AED0EB"/>
            <path d="M261.691 162.94C261.691 163.442 261.332 163.658 260.901 163.37C260.47 163.155 260.111 162.509 260.111 162.006C260.111 161.503 260.47 161.288 260.901 161.575C261.404 161.862 261.691 162.437 261.691 162.94Z" fill="white"/>
            <path d="M270.452 147.213L257.168 154.896L267.508 160.856L280.864 153.173L270.452 147.213Z" fill="#D2E7F7"/>
            <path d="M267.505 165.093V166.96L263.628 164.734C263.197 164.518 262.838 163.872 262.838 163.37V163.298C262.838 162.795 263.197 162.58 263.628 162.867L267.505 165.093Z" fill="#76A1C2"/>
            <path d="M264.346 164.159C264.346 164.447 264.131 164.59 263.915 164.447C263.7 164.303 263.484 163.944 263.484 163.657C263.484 163.37 263.7 163.226 263.915 163.37C264.131 163.513 264.346 163.872 264.346 164.159Z" fill="#173B56"/>
            <path d="M261.69 162.939C261.69 163.37 261.403 163.586 260.972 163.37C260.613 163.155 260.254 162.58 260.254 162.149C260.254 161.719 260.541 161.503 260.972 161.719C261.331 161.934 261.69 162.437 261.69 162.939Z" fill="#0D80D8"/>
            <path d="M265.713 164.949C265.713 165.236 265.498 165.38 265.282 165.236C265.067 165.093 264.852 164.734 264.852 164.447C264.852 164.159 265.067 164.016 265.282 164.159C265.498 164.231 265.713 164.59 265.713 164.949Z" fill="#173B56"/>
            <path d="M267.147 165.74C267.147 166.027 266.931 166.17 266.716 166.027C266.429 165.883 266.285 165.524 266.285 165.237C266.285 164.95 266.501 164.806 266.716 164.95C266.931 165.093 267.147 165.452 267.147 165.74Z" fill="#173B56"/>
            <path d="M261.404 162.867L260.471 162.293V162.149L261.404 162.724V162.867Z" fill="white"/>
            <path d="M260.974 162.508L260.902 162.437V162.149L260.974 162.221V162.508Z" fill="white"/>
            <path d="M260.543 162.58L260.471 162.508V162.149L260.543 162.221V162.58Z" fill="white"/>
            <path d="M261.402 163.082L261.33 163.01V162.651L261.402 162.723V163.082Z" fill="white"/>
            <path d="M270.235 158.057V157.698L268.584 157.123L268.512 157.051H268.44L266.788 156.118V156.046H266.717L266.573 155.974L265.137 155.113H265.065L264.993 155.041L264.85 154.969L263.414 154.107H263.342L263.27 154.036L261.69 153.174V153.102L259.68 153.892V154.251L259.751 154.323L261.403 155.256L261.475 155.328L263.055 156.262L263.198 156.333L264.778 157.267L265.352 156.98L264.778 157.267L264.922 157.339L266.501 158.272L268.153 157.339L267.937 157.41L266.501 158.272H266.573L268.225 159.206L269.948 158.272L270.235 158.057Z" fill="#416F91"/>
            <path d="M261.405 154.897L259.754 153.963L261.765 152.814L263.344 153.748L261.405 154.897Z" fill="#416F91"/>
            <path d="M263.27 153.676L261.259 154.825L259.68 153.892L261.69 152.743L263.27 153.676Z" fill="#76A1C2"/>
            <path d="M261.837 154.394L260.4 153.533L260.544 153.461L262.052 154.323L261.837 154.394Z" fill="url(#paint0_linear_1343_4733)"/>
            <path d="M263.272 153.676L262.698 154.035C262.626 153.963 262.698 153.891 262.77 153.819L263.129 153.604L263.272 153.676Z" fill="#416F91"/>
            <path d="M261.833 152.815L261.474 153.03C261.402 153.102 261.259 153.102 261.115 153.102L261.69 152.743L261.833 152.815Z" fill="#5E95AA"/>
            <path d="M261.689 152.743L261.474 152.887C261.402 152.887 261.33 152.958 261.33 152.958L261.689 152.743Z" fill="white"/>
            <path d="M263.344 153.676L263.129 153.819C263.057 153.819 263.057 153.891 263.057 153.891L263.344 153.676Z" fill="white"/>
            <path d="M263.056 155.902L261.477 154.969L263.487 153.748L265.067 154.753L263.056 155.902Z" fill="#416F91"/>
            <path d="M264.995 154.681L262.984 155.83L261.404 154.897L263.343 153.748L264.995 154.681Z" fill="#76A1C2"/>
            <path d="M263.557 155.399L262.121 154.538L262.265 154.466L263.701 155.328L263.557 155.399Z" fill="url(#paint1_linear_1343_4733)"/>
            <path d="M264.995 154.681L264.42 155.04C264.349 154.969 264.42 154.897 264.492 154.825L264.851 154.61L264.995 154.681Z" fill="#416F91"/>
            <path d="M263.486 153.82L263.127 154.035C263.055 154.107 262.911 154.107 262.768 154.107L263.342 153.748L263.486 153.82Z" fill="#416F91"/>
            <path d="M263.416 153.748L263.2 153.891C263.128 153.891 263.057 153.963 263.057 153.963L263.416 153.748Z" fill="white"/>
            <path d="M265.067 154.681L264.851 154.825C264.779 154.825 264.779 154.897 264.779 154.897L265.067 154.681Z" fill="white"/>
            <path d="M264.779 156.835L263.199 155.902L265.21 154.753L266.79 155.686L264.779 156.835Z" fill="#416F91"/>
            <path d="M266.719 155.686L264.708 156.835L263.057 155.902L265.067 154.753L266.719 155.686Z" fill="#76A1C2"/>
            <path d="M265.28 156.404L263.844 155.543L263.987 155.471L265.423 156.332L265.28 156.404Z" fill="url(#paint2_linear_1343_4733)"/>
            <path d="M266.718 155.687L266.143 156.046C266.071 155.974 266.143 155.902 266.215 155.831L266.574 155.615L266.718 155.687Z" fill="#416F91"/>
            <path d="M265.21 154.824L264.851 155.04C264.779 155.112 264.636 155.112 264.492 155.112L265.067 154.753L265.21 154.824Z" fill="#416F91"/>
            <path d="M265.138 154.753L264.923 154.896C264.851 154.896 264.779 154.968 264.779 154.968L265.138 154.753Z" fill="white"/>
            <path d="M266.791 155.687L266.576 155.83C266.504 155.83 266.504 155.902 266.504 155.902L266.791 155.687Z" fill="white"/>
            <path d="M266.5 157.841L264.92 156.907L266.93 155.758L268.51 156.692L266.5 157.841Z" fill="#416F91"/>
            <path d="M268.441 156.62L266.431 157.841L264.779 156.836L266.79 155.687L268.441 156.62Z" fill="#76A1C2"/>
            <path d="M267.004 157.41L265.568 156.548L265.712 156.476L267.148 157.266L267.004 157.41Z" fill="url(#paint3_linear_1343_4733)"/>
            <path d="M268.438 156.62L267.864 156.979C267.792 156.907 267.864 156.835 267.936 156.763L268.295 156.548L268.438 156.62Z" fill="#416F91"/>
            <path d="M266.933 155.759L266.574 155.974C266.502 156.046 266.358 156.046 266.215 156.046L266.789 155.687L266.933 155.759Z" fill="#416F91"/>
            <path d="M266.863 155.758L266.647 155.902C266.576 155.902 266.504 155.974 266.504 155.974L266.863 155.758Z" fill="white"/>
            <path d="M268.51 156.692L268.295 156.836C268.223 156.836 268.223 156.908 268.223 156.908L268.51 156.692Z" fill="white"/>
            <path d="M268.224 158.846L266.572 157.913L268.583 156.764L270.234 157.697L268.224 158.846Z" fill="#416F91"/>
            <path d="M270.166 157.626L268.155 158.775L266.504 157.841L268.515 156.692L270.166 157.626Z" fill="#76A1C2"/>
            <path d="M268.729 158.344L267.221 157.554L267.364 157.41L268.872 158.272L268.729 158.344Z" fill="url(#paint4_linear_1343_4733)"/>
            <path d="M270.165 157.625L269.59 157.984C269.519 157.912 269.59 157.841 269.662 157.769L270.021 157.553L270.165 157.625Z" fill="#416F91"/>
            <path d="M268.656 156.764L268.297 156.979C268.225 157.051 268.081 157.051 267.938 157.051L268.512 156.692L268.656 156.764Z" fill="#416F91"/>
            <path d="M268.582 156.692L268.366 156.836C268.294 156.836 268.223 156.908 268.223 156.908L268.582 156.692Z" fill="white"/>
            <path d="M270.162 157.697L269.947 157.841C269.875 157.841 269.875 157.913 269.875 157.913L270.162 157.697Z" fill="white"/>
            <path d="M270.81 164.303V165.381C270.81 165.524 270.738 165.668 270.595 165.74L268.225 167.104C268.153 167.176 268.082 167.104 268.01 167.104V165.955C268.01 165.811 268.082 165.668 268.225 165.596L270.595 164.232H270.667C270.739 164.16 270.81 164.232 270.81 164.303Z" fill="#295259"/>
            <path d="M270.739 164.231V165.309C270.739 165.452 270.667 165.596 270.523 165.668L268.154 167.032H268.082V165.883C268.082 165.739 268.154 165.596 268.297 165.524L270.667 164.16H270.739C270.667 164.16 270.739 164.231 270.739 164.231Z" fill="#416F91"/>
            <path d="M269.518 166.458V167.319L268.297 166.601V165.74L269.589 165.883L269.805 165.74C269.589 166.027 269.446 166.242 269.518 166.458Z" fill="#DBDBDB"/>
            <path d="M270.954 164.662C270.882 164.734 270.164 165.237 269.805 165.811C269.662 166.099 269.518 166.314 269.59 166.529L269.087 166.242L268.369 165.811L270.667 164.519L270.954 164.662Z" fill="url(#paint5_linear_1343_4733)"/>
            <path d="M270.953 164.663V165.524C270.881 165.596 269.661 166.458 269.517 167.176V166.53C269.445 166.314 269.589 166.099 269.733 165.812C270.163 165.237 270.881 164.734 270.953 164.663Z" fill="#C4C4C4"/>
            <path d="M263.844 140.32L268.152 137.376L268.942 137.232V137.52L263.844 140.32Z" fill="#6EA5BA"/>
            <path d="M268.943 137.233C268.943 137.304 268.871 137.376 268.8 137.376L263.558 140.464L253.361 146.352C253.146 146.496 251.853 147.357 251.853 148.075C251.71 148.075 244.17 143.48 244.17 143.48C244.242 142.977 244.385 142.331 244.744 142.116L245.319 141.756L260.829 132.781C260.973 132.709 261.26 132.709 261.403 132.781L268.8 137.017C268.871 137.089 268.943 137.161 268.943 137.233Z" fill="#AED0EB"/>
            <path d="M268.943 137.232V138.597C268.943 138.669 268.872 138.74 268.8 138.74C268.8 138.74 263.558 142.546 261.547 143.91C259.321 145.418 252.141 149.296 252.141 149.296C252.069 149.368 251.925 149.368 251.854 149.368V148.075C251.854 147.357 253.074 146.495 253.362 146.352L263.558 140.464L268.8 137.448C268.872 137.376 268.943 137.304 268.943 137.232Z" fill="#76A1C2"/>
            <path d="M266.859 137.735C266.788 137.951 266.644 138.094 266.357 138.238L262.336 140.536C261.617 140.966 260.397 140.966 259.679 140.536L255.514 138.094C255.155 137.879 255.011 137.663 254.939 137.376C254.939 137.089 255.083 136.802 255.514 136.586L259.535 134.288C260.253 133.858 261.474 133.858 262.192 134.288L266.357 136.73C266.859 137.017 267.003 137.376 266.859 137.735Z" fill="#6EA5BA"/>
            <path d="M266.859 137.735C266.788 137.951 266.644 138.094 266.357 138.238L262.336 140.536C261.617 140.967 260.397 140.967 259.679 140.536L255.514 138.094C255.155 137.879 255.011 137.663 254.939 137.376C255.011 137.161 255.155 137.017 255.442 136.874L259.463 134.576C260.181 134.145 261.402 134.145 262.12 134.576L266.285 137.017C266.644 137.233 266.859 137.448 266.859 137.735Z" fill="#D2E7F7"/>
            <path d="M251.923 148.075V149.44C251.779 149.44 251.707 149.44 251.635 149.368L244.239 145.131C244.167 145.059 244.096 145.059 244.096 144.988V144.629C244.096 144.629 244.167 143.695 244.167 143.48C244.239 143.48 251.779 148.075 251.923 148.075Z" fill="#6DA8BF"/>
            <path d="M259.248 140.895L254.796 138.382C254.15 138.023 253.001 138.023 252.355 138.382L246.323 141.828C246.036 141.972 245.892 142.187 245.82 142.403L252.355 138.741C252.929 138.382 253.934 138.382 254.581 138.741L258.53 141.038C259.104 141.397 259.104 141.972 258.53 142.331L251.852 146.137C252.355 146.137 252.857 146.065 253.216 145.849L259.248 142.403C259.966 141.972 259.966 141.326 259.248 140.895Z" fill="#6EA5BA"/>
            <path d="M246.467 140.823C246.467 140.823 245.964 141.757 245.893 142.475C245.893 142.475 247.759 141.182 248.693 140.68C249.698 140.177 252.283 138.31 253.576 138.382C254.868 138.454 258.602 140.68 258.602 140.68C258.602 140.68 259.177 140.608 259.177 139.962C259.177 139.315 255.73 137.52 254.797 137.089C253.863 136.659 252.642 137.089 251.278 137.879C250.057 138.669 246.754 140.249 246.467 140.823Z" fill="#76A1C2"/>
            <path d="M254.294 139.387C254.438 139.459 254.438 139.602 254.294 139.674L253.72 140.033C253.576 140.105 253.361 140.105 253.217 140.033L252.356 139.531C252.212 139.459 252.212 139.315 252.356 139.243L252.93 138.884C253.074 138.813 253.289 138.813 253.433 138.884L254.294 139.387Z" fill="#6EA5BA"/>
            <path d="M252.859 140.177C253.003 140.248 253.003 140.392 252.859 140.464L252.284 140.823C252.141 140.895 251.925 140.895 251.782 140.823L250.92 140.32C250.777 140.248 250.777 140.105 250.92 140.033L251.495 139.674C251.638 139.602 251.854 139.602 251.997 139.674L252.859 140.177Z" fill="#6EA5BA"/>
            <path d="M251.279 141.038C251.423 141.11 251.423 141.253 251.279 141.325L250.705 141.684C250.561 141.756 250.346 141.756 250.202 141.684L249.34 141.181C249.197 141.11 249.197 140.966 249.34 140.894L249.915 140.535C250.058 140.463 250.274 140.463 250.417 140.535L251.279 141.038Z" fill="#6EA5BA"/>
            <path d="M249.771 141.9C249.915 141.972 249.915 142.116 249.771 142.187L249.197 142.546C249.053 142.618 248.838 142.618 248.694 142.546L247.832 142.044C247.689 141.972 247.689 141.828 247.832 141.757L248.407 141.398C248.55 141.326 248.766 141.326 248.909 141.398L249.771 141.9Z" fill="#6EA5BA"/>
            <path d="M248.336 142.69C248.479 142.762 248.479 142.905 248.336 142.977L247.761 143.336C247.617 143.408 247.402 143.408 247.258 143.336L246.397 142.833C246.253 142.762 246.253 142.618 246.397 142.546L246.971 142.187C247.115 142.115 247.33 142.115 247.474 142.187L248.336 142.69Z" fill="#6EA5BA"/>
            <path d="M254.294 139.243C254.438 139.314 254.438 139.458 254.294 139.53L253.72 139.889C253.576 139.961 253.361 139.961 253.217 139.889L252.356 139.386C252.212 139.314 252.212 139.171 252.356 139.099L252.93 138.74C253.074 138.668 253.289 138.668 253.433 138.74L254.294 139.243Z" fill="#E5F4FF"/>
            <path d="M252.859 140.033C253.003 140.105 253.003 140.248 252.859 140.32L252.284 140.679C252.141 140.751 251.925 140.751 251.782 140.679L250.92 140.176C250.777 140.105 250.777 139.961 250.92 139.889L251.495 139.53C251.638 139.458 251.854 139.458 251.997 139.53L252.859 140.033Z" fill="#E5F4FF"/>
            <path d="M251.279 140.966C251.423 141.038 251.423 141.182 251.279 141.253L250.705 141.612C250.561 141.684 250.346 141.684 250.202 141.612L249.34 141.11C249.197 141.038 249.197 140.894 249.34 140.823L249.915 140.464C250.058 140.392 250.274 140.392 250.417 140.464L251.279 140.966Z" fill="#E5F4FF"/>
            <path d="M249.771 141.756C249.915 141.828 249.915 141.971 249.771 142.043L249.197 142.402C249.053 142.474 248.838 142.474 248.694 142.402L247.832 141.899C247.689 141.828 247.689 141.684 247.832 141.612L248.407 141.253C248.55 141.181 248.766 141.181 248.909 141.253L249.771 141.756Z" fill="#E5F4FF"/>
            <path d="M248.336 142.618C248.479 142.69 248.479 142.834 248.336 142.905L247.761 143.265C247.617 143.336 247.402 143.336 247.258 143.265L246.397 142.762C246.253 142.69 246.253 142.546 246.397 142.475L246.971 142.116C247.115 142.044 247.33 142.044 247.474 142.116L248.336 142.618Z" fill="#E5F4FF"/>
            <path d="M255.947 140.464C256.091 140.536 256.091 140.679 255.947 140.751L255.373 141.11C255.229 141.182 255.014 141.182 254.87 141.11L254.008 140.608C253.864 140.536 253.864 140.392 254.008 140.32L254.583 139.961C254.726 139.89 254.942 139.89 255.085 139.961L255.947 140.464Z" fill="#6EA5BA"/>
            <path d="M254.51 141.254C254.653 141.325 254.653 141.469 254.51 141.541L253.935 141.9C253.791 141.972 253.576 141.972 253.432 141.9L252.571 141.397C252.427 141.325 252.427 141.182 252.571 141.11L253.145 140.751C253.289 140.679 253.504 140.679 253.648 140.751L254.51 141.254Z" fill="#6EA5BA"/>
            <path d="M252.931 142.187C253.075 142.259 253.075 142.402 252.931 142.474L252.357 142.833C252.213 142.905 251.998 142.905 251.854 142.833L250.992 142.33C250.849 142.259 250.849 142.115 250.992 142.043L251.567 141.684C251.711 141.612 251.926 141.612 252.07 141.684L252.931 142.187Z" fill="#6EA5BA"/>
            <path d="M251.421 142.977C251.565 143.049 251.565 143.193 251.421 143.265L250.847 143.624C250.703 143.695 250.488 143.695 250.344 143.624L249.483 143.121C249.339 143.049 249.339 142.906 249.483 142.834L250.057 142.475C250.201 142.403 250.416 142.403 250.56 142.475L251.421 142.977Z" fill="#6EA5BA"/>
            <path d="M249.986 143.838C250.13 143.91 250.13 144.054 249.986 144.126L249.411 144.485C249.268 144.557 249.052 144.557 248.909 144.485L248.047 143.982C247.904 143.91 247.904 143.767 248.047 143.695L248.621 143.336C248.765 143.264 248.981 143.264 249.124 143.336L249.986 143.838Z" fill="#6EA5BA"/>
            <path d="M255.947 140.32C256.091 140.391 256.091 140.535 255.947 140.607L255.373 140.966C255.229 141.038 255.014 141.038 254.87 140.966L254.008 140.463C253.864 140.391 253.864 140.248 254.008 140.176L254.583 139.817C254.726 139.745 254.942 139.745 255.085 139.817L255.947 140.32Z" fill="#E5F4FF"/>
            <path d="M254.51 141.182C254.653 141.254 254.653 141.397 254.51 141.469L253.935 141.828C253.791 141.9 253.576 141.9 253.432 141.828L252.571 141.326C252.427 141.254 252.427 141.11 252.571 141.038L253.145 140.679C253.289 140.608 253.504 140.608 253.648 140.679L254.51 141.182Z" fill="#E5F4FF"/>
            <path d="M252.931 142.043C253.075 142.115 253.075 142.259 252.931 142.331L252.357 142.69C252.213 142.761 251.998 142.761 251.854 142.69L250.992 142.187C250.849 142.115 250.849 141.972 250.992 141.9L251.567 141.541C251.711 141.469 251.926 141.469 252.07 141.541L252.931 142.043Z" fill="#E5F4FF"/>
            <path d="M251.421 142.905C251.565 142.977 251.565 143.121 251.421 143.192L250.847 143.551C250.703 143.623 250.488 143.623 250.344 143.551L249.483 143.049C249.339 142.977 249.339 142.833 249.483 142.762L250.057 142.402C250.201 142.331 250.416 142.331 250.56 142.402L251.421 142.905Z" fill="#E5F4FF"/>
            <path d="M249.986 143.695C250.13 143.767 250.13 143.911 249.986 143.983L249.411 144.342C249.268 144.413 249.052 144.413 248.909 144.342L248.047 143.839C247.904 143.767 247.904 143.624 248.047 143.552L248.621 143.193C248.765 143.121 248.981 143.121 249.124 143.193L249.986 143.695Z" fill="#E5F4FF"/>
            <path d="M257.671 141.469C257.815 141.541 257.815 141.684 257.671 141.756L257.097 142.115C256.953 142.187 256.738 142.187 256.594 142.115L255.733 141.612C255.589 141.541 255.589 141.397 255.733 141.325L256.307 140.966C256.451 140.894 256.666 140.894 256.81 140.966L257.671 141.469Z" fill="#6EA5BA"/>
            <path d="M256.234 142.259C256.378 142.331 256.378 142.475 256.234 142.546L255.659 142.905C255.516 142.977 255.3 142.977 255.157 142.905L254.295 142.403C254.152 142.331 254.152 142.187 254.295 142.116L254.87 141.756C255.013 141.685 255.229 141.685 255.372 141.756L256.234 142.259Z" fill="#6EA5BA"/>
            <path d="M254.652 143.12C254.796 143.192 254.796 143.336 254.652 143.407L254.077 143.766C253.934 143.838 253.718 143.838 253.575 143.766L252.713 143.264C252.57 143.192 252.57 143.048 252.713 142.977L253.287 142.618C253.431 142.546 253.647 142.546 253.79 142.618L254.652 143.12Z" fill="#6EA5BA"/>
            <path d="M253.144 143.982C253.288 144.054 253.288 144.197 253.144 144.269L252.57 144.628C252.426 144.7 252.211 144.7 252.067 144.628L251.205 144.126C251.062 144.054 251.062 143.91 251.205 143.838L251.78 143.479C251.923 143.408 252.139 143.408 252.282 143.479L253.144 143.982Z" fill="#6EA5BA"/>
            <path d="M251.709 144.773C251.852 144.844 251.852 144.988 251.709 145.06L251.134 145.419C250.99 145.491 250.775 145.491 250.631 145.419L249.77 144.916C249.626 144.844 249.626 144.701 249.77 144.629L250.344 144.27C250.488 144.198 250.703 144.198 250.847 144.27L251.709 144.773Z" fill="#6EA5BA"/>
            <path d="M257.671 141.325C257.815 141.397 257.815 141.54 257.671 141.612L257.097 141.971C256.953 142.043 256.738 142.043 256.594 141.971L255.733 141.469C255.589 141.397 255.589 141.253 255.733 141.181L256.307 140.822C256.451 140.751 256.666 140.751 256.81 140.822L257.671 141.325Z" fill="#E5F4FF"/>
            <path d="M256.234 142.115C256.378 142.187 256.378 142.33 256.234 142.402L255.659 142.761C255.516 142.833 255.3 142.833 255.157 142.761L254.295 142.258C254.152 142.187 254.152 142.043 254.295 141.971L254.87 141.612C255.013 141.54 255.229 141.54 255.372 141.612L256.234 142.115Z" fill="#E5F4FF"/>
            <path d="M254.652 143.049C254.796 143.121 254.796 143.264 254.652 143.336L254.077 143.695C253.934 143.767 253.718 143.767 253.575 143.695L252.713 143.192C252.57 143.121 252.57 142.977 252.713 142.905L253.287 142.546C253.431 142.474 253.647 142.474 253.79 142.546L254.652 143.049Z" fill="#E5F4FF"/>
            <path d="M253.144 143.838C253.288 143.91 253.288 144.054 253.144 144.126L252.57 144.485C252.426 144.557 252.211 144.557 252.067 144.485L251.205 143.982C251.062 143.91 251.062 143.767 251.205 143.695L251.78 143.336C251.923 143.264 252.139 143.264 252.282 143.336L253.144 143.838Z" fill="#E5F4FF"/>
            <path d="M251.709 144.7C251.852 144.772 251.852 144.916 251.709 144.988L251.134 145.347C250.99 145.418 250.775 145.418 250.631 145.347L249.77 144.844C249.626 144.772 249.626 144.628 249.77 144.557L250.344 144.198C250.488 144.126 250.703 144.126 250.847 144.198L251.709 144.7Z" fill="#E5F4FF"/>
            <path d="M252.213 144.341C252.213 144.341 251.639 144.987 251.926 146.136C251.926 146.136 257.527 143.192 258.389 142.617C259.25 142.043 259.753 141.54 259.753 141.54V140.032C259.753 140.032 256.163 142.402 254.727 143.192C253.362 143.982 252.213 144.341 252.213 144.341Z" fill="#76A1C2"/>
            <path d="M259.322 139.387L255.086 136.946C254.439 136.587 253.362 136.587 252.716 136.946L246.972 140.249C246.684 140.392 246.541 140.608 246.469 140.823L252.788 137.305C253.362 136.946 254.296 136.946 254.87 137.305L258.676 139.459C259.25 139.818 259.25 140.32 258.676 140.679L252.213 144.342C252.716 144.342 253.147 144.27 253.506 144.054L259.25 140.751C259.968 140.32 259.968 139.746 259.322 139.387Z" fill="#D7F9FF"/>
            <path d="M251.206 148.363C251.158 148.458 251.11 148.482 251.062 148.434L245.03 144.916C244.958 144.916 244.887 144.844 244.887 144.701V144.629C244.887 144.557 244.959 144.485 244.959 144.485H245.03L251.062 148.004C251.134 148.147 251.206 148.219 251.206 148.363Z" fill="#152F35"/>
            <path d="M251.206 148.434C251.206 148.506 251.134 148.506 251.062 148.434L245.03 144.915C244.958 144.915 244.887 144.844 244.887 144.7L244.959 144.628H245.03L251.062 148.147C251.134 148.29 251.134 148.362 251.206 148.434Z" fill="#254B56"/>
            <path d="M249.697 147.428C249.912 147.572 249.912 147.716 249.697 147.859L244.096 151.091C243.881 151.234 243.522 151.234 243.306 151.091L240.29 149.367C240.075 149.224 240.075 149.08 240.29 148.936L245.891 145.705C246.107 145.562 246.466 145.562 246.681 145.705L249.697 147.428Z" fill="#124164"/>
            <path d="M249.697 147.285C249.912 147.429 249.912 147.573 249.697 147.716L244.096 150.948C243.881 151.091 243.522 151.091 243.306 150.948L240.29 149.224C240.075 149.081 240.075 148.937 240.29 148.793L245.891 145.562C246.107 145.418 246.466 145.418 246.681 145.562L249.697 147.285Z" fill="#0D80D8"/>
            <path d="M245.032 146.711C245.104 146.783 245.104 146.854 245.032 146.854L241.585 148.865C241.514 148.937 241.37 148.937 241.298 148.865C241.226 148.793 241.226 148.721 241.298 148.721L244.745 146.711C244.817 146.639 244.96 146.639 245.032 146.711Z" fill="#475158"/>
            <path d="M245.39 147.142H245.175L242.59 148.65L241.8 149.08C241.728 149.08 241.728 149.152 241.8 149.224H242.015L244.601 147.716L245.39 147.285V147.142Z" fill="#475158"/>
            <path d="M245.748 147.357H245.532L242.947 148.865L242.157 149.296C242.086 149.296 242.086 149.368 242.157 149.44H242.373L244.958 147.932L245.748 147.501C245.796 147.453 245.796 147.405 245.748 147.357Z" fill="#475158"/>
            <path d="M246.467 147.788H246.323L244.384 148.937L243.81 149.296C243.738 149.296 243.738 149.368 243.81 149.368H243.953L245.892 148.219L246.467 147.86V147.788Z" fill="#475158"/>
            <path d="M247.044 146.136C247.116 146.136 247.116 146.208 247.044 146.208L246.182 146.711H245.967L245.464 146.424C245.392 146.424 245.392 146.352 245.464 146.352L246.326 145.849H246.541L247.044 146.136Z" fill="#475158"/>
            <path opacity="0.5" d="M247.901 147.86C248.117 148.003 247.973 148.219 247.614 148.434C247.255 148.65 246.824 148.722 246.609 148.578C246.394 148.434 246.537 148.219 246.896 148.003C247.255 147.788 247.686 147.716 247.901 147.86Z" fill="#AAB3BA"/>
            <path opacity="0.5" d="M248.552 147.501C248.767 147.645 248.624 147.86 248.265 148.075C247.906 148.291 247.475 148.363 247.259 148.219C247.044 148.075 247.188 147.86 247.547 147.645C247.906 147.429 248.337 147.357 248.552 147.501Z" fill="#657079"/>
            <path opacity="0.5" d="M393.797 326.201C411.469 336.422 411.401 352.952 393.591 363.241C375.781 373.529 346.97 373.598 329.229 363.378C311.488 353.158 311.625 336.627 329.435 326.339C347.313 316.05 376.125 316.05 393.797 326.201Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M378.601 334.433C388.09 339.852 388.022 348.768 378.464 354.256C368.906 359.743 353.503 359.811 344.082 354.324C334.593 348.837 334.661 339.989 344.22 334.501C353.778 329.014 369.181 328.946 378.601 334.433Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M368.074 338.704L350.338 329.5V311.765L368.074 320.968V338.704Z" fill="#DBE2F3"/>
            <path d="M378.542 332.661L368.074 338.704V320.968L378.542 314.925V332.661Z" fill="#BACAE3"/>
            <path d="M377.397 321.205L375.225 322.469V322.232L377.16 321.087L377.397 321.205Z" fill="#DBE2F3"/>
            <path d="M377.158 321.087V317.848L377.395 317.729V321.205L377.158 321.087Z" fill="#F4F4FC"/>
            <path d="M377.16 321.087L375.225 322.232V318.954L377.16 317.848V321.087Z" fill="#0E7FD5"/>
            <path d="M376.211 318.401V321.64" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.16 319.468L375.225 320.574" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M374.393 322.943L372.26 324.207V323.97L374.195 322.825L374.393 322.943Z" fill="#DBE2F3"/>
            <path d="M374.195 322.825V319.586L374.393 319.468V322.944L374.195 322.825Z" fill="#F4F4FC"/>
            <path d="M374.195 322.825L372.26 323.971V320.731L374.195 319.586V322.825Z" fill="#0E7FD5"/>
            <path d="M373.207 320.139V323.417" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M374.195 321.205L372.26 322.351" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.43 324.642L369.258 325.906V325.669L371.193 324.523L371.43 324.642Z" fill="#DBE2F3"/>
            <path d="M371.191 324.524V321.285L371.428 321.166V324.642L371.191 324.524Z" fill="#F4F4FC"/>
            <path d="M371.193 324.523L369.258 325.669V322.39L371.193 321.284V324.523Z" fill="#0E7FD5"/>
            <path d="M370.246 321.837V325.076" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.193 322.904L369.258 324.01" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.643 317.966L353.815 319.23V318.993L351.84 317.848L351.643 317.966Z" fill="#C5D1E8"/>
            <path d="M351.84 317.848V314.609L351.643 314.49V317.967L351.84 317.848Z" fill="#F4F4FC"/>
            <path d="M351.84 317.848L353.815 318.993V315.715L351.84 314.609V317.848Z" fill="#0E7FD5"/>
            <path d="M352.826 315.162V318.401" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.84 316.229L353.815 317.374" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M354.605 319.744L356.778 320.969V320.732L354.842 319.625L354.605 319.744Z" fill="#C5D1E8"/>
            <path d="M354.842 319.626V316.347L354.605 316.229V319.744L354.842 319.626Z" fill="#F4F4FC"/>
            <path d="M354.84 319.626L356.775 320.732V317.493L354.84 316.347V319.626Z" fill="#0E7FD5"/>
            <path d="M355.791 316.9V320.178" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M354.84 317.967L356.775 319.112" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M357.609 321.403L359.782 322.667V322.43L357.807 321.285L357.609 321.403Z" fill="#C5D1E8"/>
            <path d="M357.807 321.285V318.046L357.609 317.927V321.403L357.807 321.285Z" fill="#F4F4FC"/>
            <path d="M357.803 321.285L359.778 322.43V319.152L357.803 318.046V321.285Z" fill="#0E7FD5"/>
            <path d="M358.793 318.599V321.838" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M357.803 319.665L359.778 320.811" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.643 328.395L353.578 329.54V321.008L351.643 319.863V323.141V328.395Z" fill="#0E7FD5"/>
            <path d="M354.328 329.896L356.303 331.002V322.47L354.328 321.364V324.603V329.896Z" fill="#0E7FD5"/>
            <path d="M360.806 333.53L362.742 334.675L362.623 325.748L360.688 324.642V327.881L360.806 333.53Z" fill="#0E7FD5"/>
            <path d="M363.77 335.307L365.745 336.453L365.627 327.526L363.691 326.42V329.659L363.77 335.307Z" fill="#0E7FD5"/>
            <path d="M379.252 315.201L368.034 321.679L349.666 311.567V310.698L379.252 314.332V315.201Z" fill="#B6BAD5"/>
            <path d="M379.252 314.332L368.034 320.811L349.666 310.698L360.687 304.457L379.252 314.332Z" fill="#F4F4FC"/>
            <path d="M377.752 314.372L368.074 319.981L351.168 310.698L360.49 305.247L377.752 314.372Z" fill="#DBE2F3"/>
            <path d="M360.489 306.116L377.001 314.767L377.751 314.332L360.489 305.247L351.049 310.698L351.799 311.093L360.489 306.116Z" fill="#B6BAD5"/>
            <path d="M360.648 322.983L362.821 324.247V323.971L360.885 322.865L360.648 322.983Z" fill="#C5D1E8"/>
            <path d="M360.885 322.864V319.625L360.648 319.507V322.983L360.885 322.864Z" fill="#F4F4FC"/>
            <path d="M360.887 322.864L362.822 323.97V320.731L360.887 319.625V322.864Z" fill="#0E7FD5"/>
            <path d="M361.832 320.178V323.418" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.887 321.245L362.822 322.351" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M363.652 324.721L365.785 325.985V325.748L363.85 324.603L363.652 324.721Z" fill="#C5D1E8"/>
            <path d="M363.85 324.603V321.364L363.652 321.245V324.721L363.85 324.603Z" fill="#F4F4FC"/>
            <path d="M363.846 324.603L365.781 325.748V322.47L363.846 321.364V324.603Z" fill="#0E7FD5"/>
            <path d="M364.836 321.916V325.155" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M363.846 322.983L365.781 324.089" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M356.777 332.898L356.817 327.565L360.174 329.303L360.293 334.715L356.777 332.898Z" fill="#0E7FD5"/>
            <path d="M356.894 327.012L355.748 328.71L359.422 330.765L360.528 328.908L356.894 327.012Z" fill="#274B66"/>
            <path d="M360.528 329.896V328.908L359.383 330.804L360.528 329.896Z" fill="#0F2535"/>
            <path d="M355.946 329.026C356.055 329.026 356.143 328.938 356.143 328.829C356.143 328.72 356.055 328.631 355.946 328.631C355.836 328.631 355.748 328.72 355.748 328.829C355.748 328.938 355.836 329.026 355.946 329.026Z" fill="#274B66"/>
            <path d="M356.262 329.185C356.371 329.185 356.459 329.096 356.459 328.987C356.459 328.878 356.371 328.79 356.262 328.79C356.153 328.79 356.064 328.878 356.064 328.987C356.064 329.096 356.153 329.185 356.262 329.185Z" fill="#274B66"/>
            <path d="M356.537 329.343C356.646 329.343 356.735 329.254 356.735 329.145C356.735 329.036 356.646 328.948 356.537 328.948C356.428 328.948 356.34 329.036 356.34 329.145C356.34 329.254 356.428 329.343 356.537 329.343Z" fill="#274B66"/>
            <path d="M356.856 329.54C356.965 329.54 357.053 329.452 357.053 329.343C357.053 329.234 356.965 329.145 356.856 329.145C356.747 329.145 356.658 329.234 356.658 329.343C356.658 329.452 356.747 329.54 356.856 329.54Z" fill="#274B66"/>
            <path d="M357.172 329.698C357.281 329.698 357.37 329.61 357.37 329.501C357.37 329.392 357.281 329.303 357.172 329.303C357.063 329.303 356.975 329.392 356.975 329.501C356.975 329.61 357.063 329.698 357.172 329.698Z" fill="#274B66"/>
            <path d="M357.448 329.856C357.557 329.856 357.645 329.768 357.645 329.659C357.645 329.55 357.557 329.461 357.448 329.461C357.338 329.461 357.25 329.55 357.25 329.659C357.25 329.768 357.338 329.856 357.448 329.856Z" fill="#274B66"/>
            <path d="M357.725 330.014C357.834 330.014 357.922 329.925 357.922 329.816C357.922 329.707 357.834 329.619 357.725 329.619C357.616 329.619 357.527 329.707 357.527 329.816C357.527 329.925 357.616 330.014 357.725 330.014Z" fill="#274B66"/>
            <path d="M358.08 330.211C358.189 330.211 358.278 330.123 358.278 330.014C358.278 329.905 358.189 329.816 358.08 329.816C357.971 329.816 357.883 329.905 357.883 330.014C357.883 330.123 357.971 330.211 358.08 330.211Z" fill="#274B66"/>
            <path d="M358.358 330.37C358.467 330.37 358.555 330.281 358.555 330.172C358.555 330.063 358.467 329.975 358.358 329.975C358.249 329.975 358.16 330.063 358.16 330.172C358.16 330.281 358.249 330.37 358.358 330.37Z" fill="#274B66"/>
            <path d="M358.631 330.528C358.74 330.528 358.829 330.439 358.829 330.33C358.829 330.221 358.74 330.133 358.631 330.133C358.522 330.133 358.434 330.221 358.434 330.33C358.434 330.439 358.522 330.528 358.631 330.528Z" fill="#274B66"/>
            <path d="M358.989 330.686C359.098 330.686 359.186 330.597 359.186 330.488C359.186 330.379 359.098 330.291 358.989 330.291C358.879 330.291 358.791 330.379 358.791 330.488C358.791 330.597 358.879 330.686 358.989 330.686Z" fill="#274B66"/>
            <path d="M359.266 330.883C359.375 330.883 359.463 330.795 359.463 330.686C359.463 330.577 359.375 330.488 359.266 330.488C359.157 330.488 359.068 330.577 359.068 330.686C359.068 330.795 359.157 330.883 359.266 330.883Z" fill="#274B66"/>
            <path d="M356.896 326.38L360.333 328.197L360.372 324.523L356.936 322.706L356.896 326.38Z" fill="white"/>
            <path d="M363.568 335.228L365.741 336.492V336.255L363.805 335.11L363.568 335.228Z" fill="#C5D1E8"/>
            <path d="M363.805 335.11L363.766 326.459L363.568 326.341V335.228L363.805 335.11Z" fill="#F4F4FC"/>
            <path d="M360.605 333.49L362.778 334.715V334.478L360.803 333.332L360.605 333.49Z" fill="#C5D1E8"/>
            <path d="M360.805 333.332V324.682L360.568 324.563L360.608 333.49L360.805 333.332Z" fill="#F4F4FC"/>
            <path d="M354.127 329.935L356.299 331.199V330.962L354.364 329.816L354.127 329.935Z" fill="#C5D1E8"/>
            <path d="M354.364 329.817L354.324 321.364L354.127 321.245V329.935L354.364 329.817Z" fill="#F4F4FC"/>
            <path d="M351.445 328.434L353.618 329.659V329.422L351.643 328.316L351.445 328.434Z" fill="#C5D1E8"/>
            <path d="M351.639 328.315V319.823L351.402 319.704L351.442 328.434L351.639 328.315Z" fill="#F4F4FC"/>
            <path d="M377.473 332.147L375.537 333.293V324.089L377.473 322.983V326.222V332.147Z" fill="#0E7FD5"/>
            <path d="M374.394 333.767L372.459 334.873V325.669L374.394 324.563V327.802V333.767Z" fill="#0E7FD5"/>
            <path d="M371.391 335.425L369.455 336.571V327.367L371.391 326.261V329.5V335.425Z" fill="#0E7FD5"/>
            <path d="M353.932 311.37L368.587 319.428L368.626 313.819L353.893 305.366L353.932 311.37Z" fill="#D5DCE1"/>
            <path d="M354.444 310.975L368.071 318.559V314.135L354.404 306.274L354.444 310.975Z" fill="white"/>
            <path d="M369.021 313.622L354.367 305.208L353.893 305.366L368.626 313.819L369.021 313.622Z" fill="#F9FAFA"/>
            <path d="M368.586 319.428L368.625 313.819L369.02 313.621L368.981 318.954L368.586 319.428Z" fill="#AAB3BA"/>
            <path d="M358.19 311.564C358.801 311.106 358.766 310.027 358.112 309.154C357.458 308.281 356.432 307.945 355.821 308.403C355.21 308.861 355.245 309.939 355.899 310.812C356.553 311.685 357.579 312.022 358.19 311.564Z" fill="#D5DCE1"/>
            <path d="M344.497 355.63L326.762 346.426V328.691L344.497 337.894V355.63Z" fill="#DBE2F3"/>
            <path d="M354.966 349.587L344.498 355.63V337.894L354.966 331.851V349.587Z" fill="#BACAE3"/>
            <path d="M353.821 338.131L351.648 339.395V339.158L353.584 338.013L353.821 338.131Z" fill="#DBE2F3"/>
            <path d="M353.582 338.013V334.774L353.819 334.655V338.131L353.582 338.013Z" fill="#F4F4FC"/>
            <path d="M353.584 338.013L351.648 339.158V335.88L353.584 334.774V338.013Z" fill="#0E7FD5"/>
            <path d="M352.635 335.327V338.566" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.584 336.394L351.648 337.5" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M350.817 339.869L348.684 341.133V340.896L350.619 339.751L350.817 339.869Z" fill="#DBE2F3"/>
            <path d="M350.619 339.751V336.512L350.817 336.394V339.87L350.619 339.751Z" fill="#F4F4FC"/>
            <path d="M350.619 339.751L348.684 340.897V337.657L350.619 336.512V339.751Z" fill="#0E7FD5"/>
            <path d="M349.631 337.065V340.343" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M350.619 338.131L348.684 339.277" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.854 341.568L345.682 342.832V342.595L347.617 341.449L347.854 341.568Z" fill="#DBE2F3"/>
            <path d="M347.615 341.45V338.211L347.852 338.092V341.568L347.615 341.45Z" fill="#F4F4FC"/>
            <path d="M347.617 341.449L345.682 342.595V339.316L347.617 338.21V341.449Z" fill="#0E7FD5"/>
            <path d="M346.67 338.763V342.002" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.617 339.83L345.682 340.936" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.066 334.892L330.239 336.156V335.919L328.264 334.774L328.066 334.892Z" fill="#C5D1E8"/>
            <path d="M328.264 334.774V331.535L328.066 331.417V334.893L328.264 334.774Z" fill="#F4F4FC"/>
            <path d="M328.264 334.774L330.239 335.919V332.641L328.264 331.535V334.774Z" fill="#0E7FD5"/>
            <path d="M329.25 332.088V335.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.264 333.155L330.239 334.3" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M331.029 336.67L333.202 337.895V337.658L331.266 336.552L331.029 336.67Z" fill="#C5D1E8"/>
            <path d="M331.266 336.552V333.273L331.029 333.155V336.67L331.266 336.552Z" fill="#F4F4FC"/>
            <path d="M331.264 336.552L333.199 337.658V334.419L331.264 333.273V336.552Z" fill="#0E7FD5"/>
            <path d="M332.215 333.826V337.104" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M331.264 334.893L333.199 336.038" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M334.033 338.329L336.206 339.593V339.356L334.231 338.211L334.033 338.329Z" fill="#C5D1E8"/>
            <path d="M334.231 338.211V334.972L334.033 334.853V338.329L334.231 338.211Z" fill="#F4F4FC"/>
            <path d="M334.227 338.211L336.202 339.356V336.078L334.227 334.972V338.211Z" fill="#0E7FD5"/>
            <path d="M335.217 335.525V338.764" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M334.227 336.591L336.202 337.737" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.066 345.321L330.002 346.466V337.934L328.066 336.789V340.067V345.321Z" fill="#0E7FD5"/>
            <path d="M330.752 346.822L332.727 347.928V339.396L330.752 338.29V341.529V346.822Z" fill="#0E7FD5"/>
            <path d="M337.23 350.456L339.165 351.601L339.047 342.674L337.111 341.568V344.807L337.23 350.456Z" fill="#0E7FD5"/>
            <path d="M340.194 352.233L342.169 353.379L342.051 344.452L340.115 343.346V346.585L340.194 352.233Z" fill="#0E7FD5"/>
            <path d="M355.676 332.127L344.458 338.605L326.09 328.493V327.624L355.676 331.258V332.127Z" fill="#B6BAD5"/>
            <path d="M355.676 331.258L344.458 337.737L326.09 327.624L337.11 321.383L355.676 331.258Z" fill="#F4F4FC"/>
            <path d="M354.176 331.298L344.498 336.907L327.592 327.624L336.914 322.173L354.176 331.298Z" fill="#DBE2F3"/>
            <path d="M336.913 323.042L353.425 331.693L354.175 331.258L336.913 322.173L327.473 327.624L328.223 328.019L336.913 323.042Z" fill="#B6BAD5"/>
            <path d="M337.072 339.909L339.245 341.173V340.897L337.309 339.791L337.072 339.909Z" fill="#C5D1E8"/>
            <path d="M337.309 339.79V336.551L337.072 336.433V339.909L337.309 339.79Z" fill="#F4F4FC"/>
            <path d="M337.311 339.79L339.246 340.896V337.657L337.311 336.551V339.79Z" fill="#0E7FD5"/>
            <path d="M338.256 337.104V340.344" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M337.311 338.171L339.246 339.277" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M340.076 341.647L342.209 342.911V342.674L340.274 341.529L340.076 341.647Z" fill="#C5D1E8"/>
            <path d="M340.274 341.529V338.29L340.076 338.171V341.647L340.274 341.529Z" fill="#F4F4FC"/>
            <path d="M340.27 341.529L342.205 342.674V339.396L340.27 338.29V341.529Z" fill="#0E7FD5"/>
            <path d="M341.26 338.842V342.081" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M340.27 339.909L342.205 341.015" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M333.201 349.824L333.241 344.491L336.598 346.229L336.717 351.641L333.201 349.824Z" fill="#0E7FD5"/>
            <path d="M333.317 343.938L332.172 345.637L335.845 347.691L336.951 345.834L333.317 343.938Z" fill="#274B66"/>
            <path d="M336.952 346.822V345.834L335.807 347.73L336.952 346.822Z" fill="#0F2535"/>
            <path d="M332.369 345.952C332.478 345.952 332.567 345.864 332.567 345.755C332.567 345.646 332.478 345.557 332.369 345.557C332.26 345.557 332.172 345.646 332.172 345.755C332.172 345.864 332.26 345.952 332.369 345.952Z" fill="#274B66"/>
            <path d="M332.686 346.111C332.795 346.111 332.883 346.022 332.883 345.913C332.883 345.804 332.795 345.716 332.686 345.716C332.577 345.716 332.488 345.804 332.488 345.913C332.488 346.022 332.577 346.111 332.686 346.111Z" fill="#274B66"/>
            <path d="M332.961 346.269C333.07 346.269 333.159 346.18 333.159 346.071C333.159 345.962 333.07 345.874 332.961 345.874C332.852 345.874 332.764 345.962 332.764 346.071C332.764 346.18 332.852 346.269 332.961 346.269Z" fill="#274B66"/>
            <path d="M333.28 346.466C333.389 346.466 333.477 346.378 333.477 346.269C333.477 346.16 333.389 346.071 333.28 346.071C333.17 346.071 333.082 346.16 333.082 346.269C333.082 346.378 333.17 346.466 333.28 346.466Z" fill="#274B66"/>
            <path d="M333.596 346.624C333.705 346.624 333.793 346.536 333.793 346.427C333.793 346.318 333.705 346.229 333.596 346.229C333.487 346.229 333.398 346.318 333.398 346.427C333.398 346.536 333.487 346.624 333.596 346.624Z" fill="#274B66"/>
            <path d="M333.871 346.782C333.98 346.782 334.069 346.694 334.069 346.585C334.069 346.476 333.98 346.387 333.871 346.387C333.762 346.387 333.674 346.476 333.674 346.585C333.674 346.694 333.762 346.782 333.871 346.782Z" fill="#274B66"/>
            <path d="M334.149 346.94C334.258 346.94 334.346 346.852 334.346 346.742C334.346 346.633 334.258 346.545 334.149 346.545C334.04 346.545 333.951 346.633 333.951 346.742C333.951 346.852 334.04 346.94 334.149 346.94Z" fill="#274B66"/>
            <path d="M334.504 347.137C334.613 347.137 334.702 347.049 334.702 346.94C334.702 346.831 334.613 346.742 334.504 346.742C334.395 346.742 334.307 346.831 334.307 346.94C334.307 347.049 334.395 347.137 334.504 347.137Z" fill="#274B66"/>
            <path d="M334.781 347.296C334.891 347.296 334.979 347.207 334.979 347.098C334.979 346.989 334.891 346.901 334.781 346.901C334.672 346.901 334.584 346.989 334.584 347.098C334.584 347.207 334.672 347.296 334.781 347.296Z" fill="#274B66"/>
            <path d="M335.055 347.454C335.164 347.454 335.252 347.365 335.252 347.256C335.252 347.147 335.164 347.059 335.055 347.059C334.946 347.059 334.857 347.147 334.857 347.256C334.857 347.365 334.946 347.454 335.055 347.454Z" fill="#274B66"/>
            <path d="M335.412 347.612C335.521 347.612 335.61 347.523 335.61 347.414C335.61 347.305 335.521 347.217 335.412 347.217C335.303 347.217 335.215 347.305 335.215 347.414C335.215 347.523 335.303 347.612 335.412 347.612Z" fill="#274B66"/>
            <path d="M335.69 347.809C335.799 347.809 335.887 347.721 335.887 347.612C335.887 347.503 335.799 347.414 335.69 347.414C335.581 347.414 335.492 347.503 335.492 347.612C335.492 347.721 335.581 347.809 335.69 347.809Z" fill="#274B66"/>
            <path d="M333.32 343.306L336.757 345.123L336.796 341.449L333.36 339.632L333.32 343.306Z" fill="white"/>
            <path d="M339.992 352.154L342.165 353.418V353.181L340.229 352.036L339.992 352.154Z" fill="#C5D1E8"/>
            <path d="M340.229 352.036L340.19 343.385L339.992 343.267V352.154L340.229 352.036Z" fill="#F4F4FC"/>
            <path d="M337.029 350.416L339.202 351.641V351.404L337.227 350.258L337.029 350.416Z" fill="#C5D1E8"/>
            <path d="M337.229 350.258V341.608L336.992 341.489L337.032 350.416L337.229 350.258Z" fill="#F4F4FC"/>
            <path d="M330.551 346.861L332.723 348.125V347.888L330.788 346.742L330.551 346.861Z" fill="#C5D1E8"/>
            <path d="M330.788 346.743L330.748 338.29L330.551 338.171V346.861L330.788 346.743Z" fill="#F4F4FC"/>
            <path d="M327.869 345.36L330.042 346.585V346.348L328.067 345.242L327.869 345.36Z" fill="#C5D1E8"/>
            <path d="M328.063 345.242V336.749L327.826 336.63L327.866 345.36L328.063 345.242Z" fill="#F4F4FC"/>
            <path d="M353.896 349.073L351.961 350.219V341.015L353.896 339.909V343.148V349.073Z" fill="#0E7FD5"/>
            <path d="M350.818 350.693L348.883 351.799V342.595L350.818 341.489V344.728V350.693Z" fill="#0E7FD5"/>
            <path d="M347.814 352.351L345.879 353.497V344.293L347.814 343.187V346.426V352.351Z" fill="#0E7FD5"/>
            <path d="M330.356 328.296L345.011 336.354L345.05 330.745L330.316 322.292L330.356 328.296Z" fill="#D5DCE1"/>
            <path d="M330.868 327.901L344.495 335.485V331.061L330.828 323.2L330.868 327.901Z" fill="white"/>
            <path d="M345.445 330.548L330.79 322.134L330.316 322.292L345.05 330.745L345.445 330.548Z" fill="#F9FAFA"/>
            <path d="M345.01 336.354L345.049 330.745L345.444 330.547L345.405 335.88L345.01 336.354Z" fill="#AAB3BA"/>
            <path d="M334.614 328.49C335.225 328.032 335.19 326.953 334.536 326.08C333.882 325.208 332.856 324.871 332.245 325.329C331.634 325.787 331.669 326.865 332.323 327.738C332.977 328.611 334.002 328.948 334.614 328.49Z" fill="#D5DCE1"/>
            <path d="M383.521 362.483L365.785 353.279V335.544L383.521 344.747V362.483Z" fill="#DBE2F3"/>
            <path d="M393.989 356.44L383.521 362.483V344.747L393.989 338.704V356.44Z" fill="#BACAE3"/>
            <path d="M392.844 344.984L390.672 346.248V346.011L392.607 344.866L392.844 344.984Z" fill="#DBE2F3"/>
            <path d="M392.605 344.866V341.627L392.843 341.508V344.984L392.605 344.866Z" fill="#F4F4FC"/>
            <path d="M392.607 344.866L390.672 346.011V342.733L392.607 341.627V344.866Z" fill="#0E7FD5"/>
            <path d="M391.658 342.18V345.419" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M392.607 343.247L390.672 344.353" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.84 346.723L387.707 347.987V347.75L389.643 346.604L389.84 346.723Z" fill="#DBE2F3"/>
            <path d="M389.643 346.604V343.365L389.84 343.247V346.723L389.643 346.604Z" fill="#F4F4FC"/>
            <path d="M389.643 346.604L387.707 347.75V344.511L389.643 343.365V346.604Z" fill="#0E7FD5"/>
            <path d="M388.654 343.918V347.196" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.643 344.984L387.707 346.13" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M386.878 348.421L384.705 349.685V349.448L386.641 348.302L386.878 348.421Z" fill="#DBE2F3"/>
            <path d="M386.639 348.303V345.064L386.876 344.945V348.421L386.639 348.303Z" fill="#F4F4FC"/>
            <path d="M386.641 348.303L384.705 349.448V346.169L386.641 345.063V348.303Z" fill="#0E7FD5"/>
            <path d="M385.693 345.616V348.855" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M386.641 346.683L384.705 347.789" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.09 341.745L369.262 343.009V342.772L367.287 341.627L367.09 341.745Z" fill="#C5D1E8"/>
            <path d="M367.287 341.627V338.388L367.09 338.27V341.746L367.287 341.627Z" fill="#F4F4FC"/>
            <path d="M367.287 341.627L369.262 342.773V339.494L367.287 338.388V341.627Z" fill="#0E7FD5"/>
            <path d="M368.273 338.941V342.18" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.287 340.008L369.262 341.153" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M370.053 343.523L372.225 344.748V344.511L370.29 343.405L370.053 343.523Z" fill="#C5D1E8"/>
            <path d="M370.29 343.405V340.126L370.053 340.008V343.523L370.29 343.405Z" fill="#F4F4FC"/>
            <path d="M370.287 343.405L372.223 344.511V341.272L370.287 340.126V343.405Z" fill="#0E7FD5"/>
            <path d="M371.238 340.679V343.957" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M370.287 341.746L372.223 342.891" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.057 345.182L375.229 346.446V346.209L373.254 345.064L373.057 345.182Z" fill="#C5D1E8"/>
            <path d="M373.254 345.064V341.825L373.057 341.706V345.182L373.254 345.064Z" fill="#F4F4FC"/>
            <path d="M373.25 345.064L375.225 346.209V342.931L373.25 341.825V345.064Z" fill="#0E7FD5"/>
            <path d="M374.24 342.378V345.617" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.25 343.444L375.225 344.59" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.09 352.174L369.025 353.319V344.787L367.09 343.642V346.92V352.174Z" fill="#0E7FD5"/>
            <path d="M369.775 353.675L371.75 354.781V346.249L369.775 345.143V348.382V353.675Z" fill="#0E7FD5"/>
            <path d="M376.253 357.309L378.189 358.454L378.07 349.527L376.135 348.421V351.66L376.253 357.309Z" fill="#0E7FD5"/>
            <path d="M379.218 359.086L381.193 360.232L381.074 351.305L379.139 350.199V353.438L379.218 359.086Z" fill="#0E7FD5"/>
            <path d="M394.699 338.98L383.481 345.458L365.113 335.346V334.477L394.699 338.111V338.98Z" fill="#B6BAD5"/>
            <path d="M394.699 338.111L383.481 344.59L365.113 334.477L376.134 328.236L394.699 338.111Z" fill="#F4F4FC"/>
            <path d="M393.199 338.151L383.521 343.76L366.615 334.477L375.937 329.026L393.199 338.151Z" fill="#DBE2F3"/>
            <path d="M375.937 329.895L392.448 338.546L393.198 338.112L375.937 329.026L366.496 334.477L367.247 334.872L375.937 329.895Z" fill="#B6BAD5"/>
            <path d="M376.096 346.762L378.268 348.026V347.75L376.333 346.644L376.096 346.762Z" fill="#C5D1E8"/>
            <path d="M376.333 346.643V343.404L376.096 343.286V346.762L376.333 346.643Z" fill="#F4F4FC"/>
            <path d="M376.334 346.643L378.27 347.749V344.51L376.334 343.404V346.643Z" fill="#0E7FD5"/>
            <path d="M377.279 343.958V347.197" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M376.334 345.024L378.27 346.13" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.1 348.5L381.233 349.764V349.527L379.297 348.382L379.1 348.5Z" fill="#C5D1E8"/>
            <path d="M379.297 348.382V345.143L379.1 345.024V348.5L379.297 348.382Z" fill="#F4F4FC"/>
            <path d="M379.293 348.382L381.229 349.527V346.249L379.293 345.143V348.382Z" fill="#0E7FD5"/>
            <path d="M380.283 345.695V348.934" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.293 346.762L381.229 347.868" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M372.225 356.677L372.264 351.344L375.622 353.082L375.74 358.494L372.225 356.677Z" fill="#0E7FD5"/>
            <path d="M372.341 350.791L371.195 352.49L374.869 354.544L375.975 352.687L372.341 350.791Z" fill="#274B66"/>
            <path d="M375.976 353.675V352.687L374.83 354.583L375.976 353.675Z" fill="#0F2535"/>
            <path d="M371.393 352.805C371.502 352.805 371.59 352.717 371.59 352.608C371.59 352.499 371.502 352.41 371.393 352.41C371.284 352.41 371.195 352.499 371.195 352.608C371.195 352.717 371.284 352.805 371.393 352.805Z" fill="#274B66"/>
            <path d="M371.709 352.964C371.818 352.964 371.907 352.875 371.907 352.766C371.907 352.657 371.818 352.569 371.709 352.569C371.6 352.569 371.512 352.657 371.512 352.766C371.512 352.875 371.6 352.964 371.709 352.964Z" fill="#274B66"/>
            <path d="M371.985 353.122C372.094 353.122 372.182 353.033 372.182 352.924C372.182 352.815 372.094 352.727 371.985 352.727C371.876 352.727 371.787 352.815 371.787 352.924C371.787 353.033 371.876 353.122 371.985 353.122Z" fill="#274B66"/>
            <path d="M372.303 353.319C372.412 353.319 372.5 353.231 372.5 353.122C372.5 353.013 372.412 352.924 372.303 352.924C372.194 352.924 372.105 353.013 372.105 353.122C372.105 353.231 372.194 353.319 372.303 353.319Z" fill="#274B66"/>
            <path d="M372.619 353.477C372.728 353.477 372.817 353.389 372.817 353.28C372.817 353.171 372.728 353.082 372.619 353.082C372.51 353.082 372.422 353.171 372.422 353.28C372.422 353.389 372.51 353.477 372.619 353.477Z" fill="#274B66"/>
            <path d="M372.895 353.635C373.004 353.635 373.092 353.547 373.092 353.438C373.092 353.329 373.004 353.24 372.895 353.24C372.786 353.24 372.697 353.329 372.697 353.438C372.697 353.547 372.786 353.635 372.895 353.635Z" fill="#274B66"/>
            <path d="M373.172 353.793C373.281 353.793 373.37 353.705 373.37 353.595C373.37 353.486 373.281 353.398 373.172 353.398C373.063 353.398 372.975 353.486 372.975 353.595C372.975 353.705 373.063 353.793 373.172 353.793Z" fill="#274B66"/>
            <path d="M373.528 353.99C373.637 353.99 373.725 353.902 373.725 353.793C373.725 353.684 373.637 353.595 373.528 353.595C373.419 353.595 373.33 353.684 373.33 353.793C373.33 353.902 373.419 353.99 373.528 353.99Z" fill="#274B66"/>
            <path d="M373.805 354.149C373.914 354.149 374.002 354.06 374.002 353.951C374.002 353.842 373.914 353.754 373.805 353.754C373.696 353.754 373.607 353.842 373.607 353.951C373.607 354.06 373.696 354.149 373.805 354.149Z" fill="#274B66"/>
            <path d="M374.078 354.307C374.187 354.307 374.276 354.218 374.276 354.109C374.276 354 374.187 353.912 374.078 353.912C373.969 353.912 373.881 354 373.881 354.109C373.881 354.218 373.969 354.307 374.078 354.307Z" fill="#274B66"/>
            <path d="M374.436 354.465C374.545 354.465 374.633 354.376 374.633 354.267C374.633 354.158 374.545 354.07 374.436 354.07C374.327 354.07 374.238 354.158 374.238 354.267C374.238 354.376 374.327 354.465 374.436 354.465Z" fill="#274B66"/>
            <path d="M374.713 354.662C374.822 354.662 374.911 354.574 374.911 354.465C374.911 354.356 374.822 354.267 374.713 354.267C374.604 354.267 374.516 354.356 374.516 354.465C374.516 354.574 374.604 354.662 374.713 354.662Z" fill="#274B66"/>
            <path d="M372.344 350.159L375.78 351.976L375.82 348.302L372.383 346.485L372.344 350.159Z" fill="white"/>
            <path d="M379.016 359.007L381.188 360.271V360.034L379.253 358.889L379.016 359.007Z" fill="#C5D1E8"/>
            <path d="M379.253 358.889L379.213 350.238L379.016 350.12V359.008L379.253 358.889Z" fill="#F4F4FC"/>
            <path d="M376.053 357.269L378.225 358.494V358.257L376.25 357.111L376.053 357.269Z" fill="#C5D1E8"/>
            <path d="M376.253 357.111V348.461L376.016 348.342L376.055 357.269L376.253 357.111Z" fill="#F4F4FC"/>
            <path d="M369.574 353.714L371.747 354.978V354.741L369.811 353.595L369.574 353.714Z" fill="#C5D1E8"/>
            <path d="M369.811 353.596L369.772 345.143L369.574 345.024V353.714L369.811 353.596Z" fill="#F4F4FC"/>
            <path d="M366.893 352.213L369.065 353.438V353.201L367.09 352.095L366.893 352.213Z" fill="#C5D1E8"/>
            <path d="M367.087 352.095V343.602L366.85 343.483L366.889 352.213L367.087 352.095Z" fill="#F4F4FC"/>
            <path d="M392.92 355.926L390.984 357.072V347.868L392.92 346.762V350.001V355.926Z" fill="#0E7FD5"/>
            <path d="M389.842 357.546L387.906 358.652V349.448L389.842 348.342V351.581V357.546Z" fill="#0E7FD5"/>
            <path d="M386.838 359.204L384.902 360.35V351.146L386.838 350.04V353.279V359.204Z" fill="#0E7FD5"/>
            <path d="M369.379 335.149L384.034 343.207L384.074 337.598L369.34 329.145L369.379 335.149Z" fill="#D5DCE1"/>
            <path d="M369.891 334.754L383.519 342.338V337.914L369.852 330.053L369.891 334.754Z" fill="white"/>
            <path d="M384.469 337.401L369.814 328.987L369.34 329.145L384.074 337.598L384.469 337.401Z" fill="#F9FAFA"/>
            <path d="M384.033 343.207L384.073 337.598L384.468 337.4L384.428 342.733L384.033 343.207Z" fill="#AAB3BA"/>
            <path d="M373.637 335.343C374.248 334.885 374.213 333.806 373.559 332.934C372.905 332.061 371.88 331.724 371.269 332.182C370.657 332.64 370.692 333.718 371.346 334.591C372 335.464 373.026 335.801 373.637 335.343Z" fill="#D5DCE1"/>
            <path opacity="0.5" d="M406.072 187.911C424.715 198.654 424.642 216.029 405.854 226.844C387.066 237.658 356.671 237.731 337.955 226.988C319.24 216.245 319.385 198.87 338.173 188.055C357.034 177.241 387.429 177.168 406.072 187.911Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M390.113 196.563C400.124 202.259 400.051 211.631 389.968 217.399C379.885 223.167 363.635 223.239 353.697 217.471C343.687 211.776 343.759 202.403 353.842 196.635C363.853 190.867 380.102 190.795 390.113 196.563Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M353.767 208.818L332.367 196.85V175.149L353.767 187.117V208.818Z" fill="#DBE2F3"/>
            <path d="M373.934 196.418L353.768 207.953V187.117L373.934 175.582V196.418Z" fill="#BACAE3"/>
            <path d="M371.979 185.604L367.844 187.983V187.551L371.616 185.388L371.979 185.604Z" fill="#DBE2F3"/>
            <path d="M371.615 185.388V179.187L372.051 178.971V185.604H371.978L371.615 185.388Z" fill="#F4F4FC"/>
            <path d="M371.616 185.388L367.844 187.551V181.35L371.616 179.187V185.388Z" fill="#0E7FD5"/>
            <path d="M369.73 180.269V186.469" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.616 182.288L367.844 184.451" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M366.319 188.993L362.111 191.372V190.867L365.883 188.776L366.319 188.993Z" fill="#DBE2F3"/>
            <path d="M365.883 188.777V182.505L366.318 182.288V188.993L365.883 188.777Z" fill="#F4F4FC"/>
            <path d="M365.883 188.777L362.111 190.868V184.667L365.883 182.504V188.777Z" fill="#0E7FD5"/>
            <path d="M363.998 183.586V189.786" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M365.883 185.604L362.111 187.767" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.516 192.237L356.381 194.616V194.111L360.153 191.948L360.516 192.237Z" fill="#DBE2F3"/>
            <path d="M360.152 191.949V185.749L360.515 185.532V192.237L360.152 191.949Z" fill="#F4F4FC"/>
            <path d="M360.153 191.949L356.381 194.112V187.911L360.153 185.748V191.949Z" fill="#0E7FD5"/>
            <path d="M358.268 186.83V193.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.153 188.848L356.381 191.011" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.979 194.761L367.844 197.14V196.707L371.616 194.544L371.979 194.761Z" fill="#DBE2F3"/>
            <path d="M371.615 194.544V188.272L372.051 188.056V194.761H371.978L371.615 194.544Z" fill="#F4F4FC"/>
            <path d="M371.616 194.544L367.844 196.707V190.435L371.616 188.272V194.544Z" fill="#0E7FD5"/>
            <path d="M369.73 189.353V195.626" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.616 191.372L367.844 193.535" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M366.319 198.077L362.111 200.456V200.024L365.883 197.861L366.319 198.077Z" fill="#DBE2F3"/>
            <path d="M365.883 197.861V191.66L366.318 191.372V198.077L365.883 197.861Z" fill="#F4F4FC"/>
            <path d="M365.883 197.861L362.111 200.024V193.824L365.883 191.661V197.861Z" fill="#0E7FD5"/>
            <path d="M363.998 192.742V198.943" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M365.883 194.76L362.111 196.923" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.516 201.322L356.381 203.701V203.268L360.153 201.105L360.516 201.322Z" fill="#DBE2F3"/>
            <path d="M360.152 201.105V194.833L360.515 194.617V201.322L360.152 201.105Z" fill="#F4F4FC"/>
            <path d="M360.153 201.105L356.381 203.268V196.996L360.153 194.833V201.105Z" fill="#0E7FD5"/>
            <path d="M358.268 195.914V202.187" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.153 198.004L356.381 200.167" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.342 185.604L339.549 187.983V187.551L335.777 185.388L335.342 185.604Z" fill="#C5D1E8"/>
            <path d="M335.777 185.388V179.187L335.342 178.971V185.604L335.777 185.388Z" fill="#F4F4FC"/>
            <path d="M335.775 185.388L339.548 187.551V181.35L335.775 179.187V185.388Z" fill="#0E7FD5"/>
            <path d="M337.662 180.269V186.469" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.775 182.288L339.548 184.451" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.072 188.993L345.207 191.372V190.867L341.507 188.776L341.072 188.993Z" fill="#C5D1E8"/>
            <path d="M341.507 188.777V182.505L341.072 182.288V188.993L341.507 188.777Z" fill="#F4F4FC"/>
            <path d="M341.508 188.777L345.207 190.868V184.667L341.508 182.504V188.777Z" fill="#0E7FD5"/>
            <path d="M343.322 183.586V189.786" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.508 185.604L345.207 187.767" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M346.805 192.237L351.012 194.616V194.111L347.24 191.948L346.805 192.237Z" fill="#C5D1E8"/>
            <path d="M347.24 191.949V185.749L346.805 185.532V192.237L347.24 191.949Z" fill="#F4F4FC"/>
            <path d="M347.238 191.949L351.01 194.112V187.911L347.238 185.748V191.949Z" fill="#0E7FD5"/>
            <path d="M349.125 186.83V193.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.238 188.849L351.01 191.012" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.342 194.039L339.549 196.418V195.913L335.777 193.75L335.342 194.039Z" fill="#C5D1E8"/>
            <path d="M335.777 193.751V187.551L335.342 187.334V194.04L335.777 193.751Z" fill="#F4F4FC"/>
            <path d="M335.775 193.751L339.548 195.914V189.713L335.775 187.551V193.751Z" fill="#0E7FD5"/>
            <path d="M337.662 188.632V194.832" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.775 190.651L339.548 192.814" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M346.805 200.6L351.012 202.98V202.475L347.24 200.384L346.805 200.6Z" fill="#C5D1E8"/>
            <path d="M347.24 200.312V194.112L346.805 193.896V200.601L347.24 200.384V200.312Z" fill="#F4F4FC"/>
            <path d="M347.238 200.384L351.01 202.475V196.275L347.238 194.112V200.312V200.384Z" fill="#0E7FD5"/>
            <path d="M349.125 195.193V201.394" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.238 197.212L351.01 199.375" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.145 197.14L345.279 199.519V199.014L341.58 196.852L341.145 197.14Z" fill="#C5D1E8"/>
            <path d="M341.58 196.852V190.651L341.145 190.435V197.14L341.58 196.852Z" fill="#F4F4FC"/>
            <path d="M341.58 196.851L345.28 199.014V192.814L341.58 190.651V196.851Z" fill="#0E7FD5"/>
            <path d="M343.467 191.732V197.933" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.58 193.75L345.28 195.913" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M375.384 175.077L353.767 187.478L332.367 175.149V173.491L375.384 173.418V175.077Z" fill="#B6BAD5"/>
            <path d="M375.384 173.419L353.767 185.82L332.367 173.491L353.984 161.09L375.384 173.419Z" fill="#F4F4FC"/>
            <path d="M372.483 173.419L353.84 184.162L335.27 173.491L353.913 162.749L372.483 173.419Z" fill="#DBE2F3"/>
            <path d="M353.767 164.407L370.886 174.212L372.265 173.419L353.767 162.749L335.051 173.491L336.502 174.284L353.767 164.407Z" fill="#B6BAD5"/>
            <path d="M371.325 218.912L351.158 207.304V173.418L371.325 184.954V218.912Z" fill="#DBE2F3"/>
            <path d="M391.493 207.304L371.326 218.912V184.954L391.493 173.418V207.304Z" fill="#BACAE3"/>
            <path d="M389.459 185.532L385.324 187.911V187.406L389.024 185.315L389.459 185.532Z" fill="#DBE2F3"/>
            <path d="M389.023 185.315V179.042L389.459 178.826V185.531L389.023 185.315Z" fill="#F4F4FC"/>
            <path d="M389.024 185.315L385.324 187.406V181.205L389.024 179.042V185.315Z" fill="#0E7FD5"/>
            <path d="M387.211 180.124V186.324" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 182.143L385.324 184.306" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 188.848L379.592 191.227V190.794L383.364 188.631L383.727 188.848Z" fill="#DBE2F3"/>
            <path d="M383.363 188.632V182.432L383.726 182.143V188.848L383.363 188.632Z" fill="#F4F4FC"/>
            <path d="M383.364 188.632L379.592 190.794V184.522L383.364 182.431V188.632Z" fill="#0E7FD5"/>
            <path d="M381.479 183.441V189.713" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 185.531L379.592 187.694" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 192.092L373.789 194.471V193.967L377.561 191.876L377.997 192.092Z" fill="#DBE2F3"/>
            <path d="M377.561 191.876V185.603L377.996 185.387V192.092L377.561 191.876Z" fill="#F4F4FC"/>
            <path d="M377.561 191.876L373.789 193.967V187.766L377.561 185.603V191.876Z" fill="#0E7FD5"/>
            <path d="M375.676 186.685V192.957" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 188.703L373.789 190.866" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.459 195.553L385.324 197.932V197.428L389.024 195.337L389.459 195.553Z" fill="#DBE2F3"/>
            <path d="M389.023 195.337V189.064L389.459 188.848V195.553L389.023 195.337Z" fill="#F4F4FC"/>
            <path d="M389.024 195.336L385.324 197.427V191.227L389.024 189.064V195.336Z" fill="#0E7FD5"/>
            <path d="M387.211 190.146V196.418" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 192.164L385.324 194.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 198.869L379.592 201.249V200.816L383.364 198.653L383.727 198.869Z" fill="#DBE2F3"/>
            <path d="M383.363 198.653V192.453L383.726 192.164V198.869L383.363 198.653Z" fill="#F4F4FC"/>
            <path d="M383.364 198.653L379.592 200.816V194.616L383.364 192.453V198.653Z" fill="#0E7FD5"/>
            <path d="M381.479 193.534V199.734" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 195.553L379.592 197.716" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 202.113L373.789 204.492V204.06L377.561 201.897L377.997 202.113Z" fill="#DBE2F3"/>
            <path d="M377.561 201.897V195.625L377.996 195.408V202.114L377.561 201.897Z" fill="#F4F4FC"/>
            <path d="M377.561 201.898L373.789 204.06V197.788L377.561 195.625V201.898Z" fill="#0E7FD5"/>
            <path d="M375.676 196.707V202.979" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 198.797L373.789 200.888" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.459 204.854L385.324 207.233V206.8L389.024 204.637L389.459 204.854Z" fill="#DBE2F3"/>
            <path d="M389.023 204.637V198.365L389.459 198.148V204.854L389.023 204.637Z" fill="#F4F4FC"/>
            <path d="M389.024 204.637L385.324 206.8V200.527L389.024 198.365V204.637Z" fill="#0E7FD5"/>
            <path d="M387.211 199.446V205.719" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 201.464L385.324 203.627" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 208.17L379.592 210.549V210.117L383.364 207.954L383.727 208.17Z" fill="#DBE2F3"/>
            <path d="M383.363 207.953V201.753L383.726 201.464V208.169L383.363 207.953Z" fill="#F4F4FC"/>
            <path d="M383.364 207.954L379.592 210.117V203.916L383.364 201.753V207.954Z" fill="#0E7FD5"/>
            <path d="M381.479 202.835V209.035" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 204.853L379.592 207.016" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 211.415L373.789 213.794V213.361L377.561 211.198L377.997 211.415Z" fill="#DBE2F3"/>
            <path d="M377.561 211.198V204.926L377.996 204.709V211.415L377.561 211.198Z" fill="#F4F4FC"/>
            <path d="M377.561 211.198L373.789 213.361V207.088L377.561 204.926V211.198Z" fill="#0E7FD5"/>
            <path d="M375.676 206.007V212.28" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 208.098L373.789 210.261" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 185.532L357.25 187.911V187.406L353.478 185.315L353.115 185.532Z" fill="#C5D1E8"/>
            <path d="M353.478 185.315V179.042L353.115 178.826V185.531L353.478 185.315Z" fill="#F4F4FC"/>
            <path d="M353.477 185.315L357.249 187.406V181.205L353.477 179.042V185.315Z" fill="#0E7FD5"/>
            <path d="M355.363 180.124V186.324" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 182.143L357.249 184.306" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 188.848L362.981 191.227V190.794L359.209 188.631L358.773 188.848Z" fill="#C5D1E8"/>
            <path d="M359.209 188.632V182.432L358.773 182.143V188.848L359.209 188.632Z" fill="#F4F4FC"/>
            <path d="M359.209 188.632L362.981 190.794V184.522L359.209 182.431V188.632Z" fill="#0E7FD5"/>
            <path d="M361.096 183.441V189.713" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M359.209 185.531L362.981 187.694" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.578 192.092L368.713 194.471V193.967L364.941 191.876L364.578 192.092Z" fill="#C5D1E8"/>
            <path d="M364.941 191.876V185.603L364.578 185.387V192.092L364.941 191.876Z" fill="#F4F4FC"/>
            <path d="M364.939 191.876L368.712 193.967V187.766L364.939 185.603V191.876Z" fill="#0E7FD5"/>
            <path d="M366.826 186.685V192.957" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 188.704L368.712 190.867" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 195.553L357.25 197.932V197.428L353.478 195.337L353.115 195.553Z" fill="#C5D1E8"/>
            <path d="M353.478 195.337V189.064L353.115 188.848V195.553L353.478 195.337Z" fill="#F4F4FC"/>
            <path d="M353.477 195.336L357.249 197.427V191.227L353.477 189.064V195.336Z" fill="#0E7FD5"/>
            <path d="M355.363 190.146V196.418" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 192.164L357.249 194.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 198.869L362.981 201.249V200.816L359.209 198.653L358.773 198.869Z" fill="#C5D1E8"/>
            <path d="M359.209 198.653V192.453L358.773 192.164V198.869L359.209 198.653Z" fill="#F4F4FC"/>
            <path d="M359.209 198.653L362.981 200.816V194.616L359.209 192.453V198.653Z" fill="#0E7FD5"/>
            <path d="M361.096 193.534V199.734" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M359.209 195.553L362.981 197.716" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.578 202.113L368.713 204.492V204.06L364.941 201.897L364.578 202.113Z" fill="#C5D1E8"/>
            <path d="M364.941 201.897V195.625L364.578 195.408V202.114L364.941 201.897Z" fill="#F4F4FC"/>
            <path d="M364.939 201.898L368.712 204.06V197.788L364.939 195.625V201.898Z" fill="#0E7FD5"/>
            <path d="M366.826 196.707V202.979" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 198.797L368.712 200.888" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 204.854L357.25 207.233V206.8L353.478 204.637L353.115 204.854Z" fill="#C5D1E8"/>
            <path d="M353.478 204.637V198.365L353.115 198.148V204.854L353.478 204.637Z" fill="#F4F4FC"/>
            <path d="M353.477 204.637L357.249 206.8V200.527L353.477 198.365V204.637Z" fill="#0E7FD5"/>
            <path d="M355.363 199.446V205.719" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 201.464L357.249 203.627" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 211.631L362.981 214.01V213.577L359.209 211.414L358.773 211.631Z" fill="#DBE2F3"/>
            <path d="M359.209 207.953V201.753L358.773 201.464V208.169V211.63L359.209 211.414V207.953Z" fill="#F4F4FC"/>
            <path d="M359.209 211.415L362.981 213.577V203.916L359.209 201.753V207.954" fill="#A3B6D2"/>
            <path d="M364.578 211.415L368.713 213.794V213.361L364.941 211.198L364.578 211.415Z" fill="#C5D1E8"/>
            <path d="M364.941 211.198V204.926L364.578 204.709V211.415L364.941 211.198Z" fill="#F4F4FC"/>
            <path d="M364.939 211.198L368.712 213.361V207.088L364.939 204.926V211.198Z" fill="#0E7FD5"/>
            <path d="M366.826 206.007V212.28" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 208.098L368.712 210.261" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M392.943 172.915L371.326 185.316L349.926 172.987V171.329L392.943 171.257V172.915Z" fill="#B6BAD5"/>
            <path d="M392.943 171.257L371.326 183.657L349.926 171.329L371.471 159L392.943 171.257Z" fill="#F4F4FC"/>
            <path d="M390.042 171.257L371.326 181.999L352.828 171.329L371.471 160.658L390.042 171.257Z" fill="#DBE2F3"/>
            <path d="M371.252 162.245L388.372 172.122L389.823 171.257L371.252 160.587L352.609 171.329L354.06 172.194L371.252 162.245Z" fill="#B6BAD5"/>
            <path d="M391.273 230.305L370.744 218.769V198.798L391.273 210.333V230.305Z" fill="#DBE2F3"/>
            <path d="M410.644 219.202L391.275 230.377V210.334L410.644 199.231V219.202Z" fill="#BACAE3"/>
            <path d="M408.83 208.891L404.84 211.198V210.766L408.394 208.675L408.83 208.891Z" fill="#DBE2F3"/>
            <path d="M408.395 208.675V202.691L408.83 202.475V208.891L408.395 208.675Z" fill="#F4F4FC"/>
            <path d="M408.394 208.675L404.84 210.766V204.782L408.394 202.691V208.675Z" fill="#0E7FD5"/>
            <path d="M406.652 203.7V209.685" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.394 205.647L404.84 207.738" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M403.316 212.136L399.326 214.371V213.938L402.953 211.919L403.316 212.136Z" fill="#DBE2F3"/>
            <path d="M402.953 211.919V205.935L403.316 205.647V212.136L402.953 211.919Z" fill="#F4F4FC"/>
            <path d="M402.953 211.919L399.326 213.938V207.954L402.953 205.935V211.919Z" fill="#0E7FD5"/>
            <path d="M401.139 206.944V212.928" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M402.953 208.891L399.326 210.982" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.802 215.236L393.812 217.471V217.039L397.367 215.02L397.802 215.236Z" fill="#DBE2F3"/>
            <path d="M397.367 215.02V209.036L397.803 208.747V215.236L397.367 215.02Z" fill="#F4F4FC"/>
            <path d="M397.367 215.02L393.812 217.038V211.054L397.367 209.036V215.02Z" fill="#0E7FD5"/>
            <path d="M395.555 210.045V216.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.367 211.992L393.812 214.083" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.83 217.687L404.84 219.922V219.49L408.394 217.399L408.83 217.687Z" fill="#DBE2F3"/>
            <path d="M408.395 217.399V211.487L408.83 211.198V217.687L408.395 217.399Z" fill="#F4F4FC"/>
            <path d="M408.394 217.399L404.84 219.489V213.505L408.394 211.487V217.399Z" fill="#0E7FD5"/>
            <path d="M406.652 212.496V218.48" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.394 214.443L404.84 216.534" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M403.316 220.86L399.326 223.167V222.734L402.953 220.644L403.316 220.86Z" fill="#DBE2F3"/>
            <path d="M402.953 220.643V214.659L403.316 214.443V220.86L402.953 220.643Z" fill="#F4F4FC"/>
            <path d="M402.953 220.643L399.326 222.734V216.75L402.953 214.659V220.643Z" fill="#0E7FD5"/>
            <path d="M401.139 215.669V221.653" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M402.953 217.688L399.326 219.706" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.802 223.96L393.812 226.267V225.834L397.367 223.743L397.802 223.96Z" fill="#DBE2F3"/>
            <path d="M397.367 223.744V217.76L397.803 217.543V223.96L397.367 223.744Z" fill="#F4F4FC"/>
            <path d="M397.367 223.743L393.812 225.834V219.85L397.367 217.759V223.743Z" fill="#0E7FD5"/>
            <path d="M395.555 218.769V224.753" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.367 220.787L393.812 222.806" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.572 208.891L377.562 211.198V210.766L373.935 208.675L373.572 208.891Z" fill="#C5D1E8"/>
            <path d="M373.935 208.675V202.691L373.572 202.475V208.891L373.935 208.675Z" fill="#F4F4FC"/>
            <path d="M373.934 208.675L377.561 210.766V204.782L373.934 202.691V208.675Z" fill="#0E7FD5"/>
            <path d="M375.748 203.7V209.685" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.934 205.647L377.561 207.738" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.086 212.136L383.076 214.371V213.938L379.449 211.919L379.086 212.136Z" fill="#C5D1E8"/>
            <path d="M379.449 211.919V205.935L379.086 205.647V212.136L379.449 211.919Z" fill="#F4F4FC"/>
            <path d="M379.447 211.919L383.074 213.938V207.954L379.447 205.935V211.919Z" fill="#0E7FD5"/>
            <path d="M381.262 206.944V212.928" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.447 208.891L383.074 210.982" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M384.6 215.236L388.589 217.471V217.039L385.035 215.02L384.6 215.236Z" fill="#C5D1E8"/>
            <path d="M385.035 215.02V209.036L384.6 208.747V215.236L385.035 215.02Z" fill="#F4F4FC"/>
            <path d="M385.035 215.02L388.59 217.038V211.054L385.035 209.036V215.02Z" fill="#0E7FD5"/>
            <path d="M386.777 210.045V216.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M385.035 211.992L388.59 214.083" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.572 216.967L377.562 219.202V218.769L373.935 216.75L373.572 216.967Z" fill="#C5D1E8"/>
            <path d="M373.935 216.75V210.766L373.572 210.549V216.966L373.935 216.75Z" fill="#F4F4FC"/>
            <path d="M373.934 216.75L377.561 218.769V212.785L373.934 210.766V216.75Z" fill="#0E7FD5"/>
            <path d="M375.748 211.775V217.759" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.934 213.722L377.561 215.813" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M384.6 223.239L388.589 225.546V225.114L385.035 223.023L384.6 223.239Z" fill="#C5D1E8"/>
            <path d="M385.035 223.022V217.038L384.6 216.822V223.239L385.035 223.022Z" fill="#F4F4FC"/>
            <path d="M385.035 223.023L388.59 225.114V219.129L385.035 217.039V223.023Z" fill="#0E7FD5"/>
            <path d="M386.777 218.12V224.104" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M385.035 220.067L388.59 222.085" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.16 219.923L383.15 222.23V221.797L379.523 219.706L379.16 219.923Z" fill="#C5D1E8"/>
            <path d="M379.523 219.706V213.722L379.16 213.505V219.922L379.523 219.706Z" fill="#F4F4FC"/>
            <path d="M379.521 219.706L383.149 221.797V215.813L379.521 213.722V219.706Z" fill="#0E7FD5"/>
            <path d="M381.336 214.731V220.715" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.521 216.75L383.149 218.769" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M412.02 198.725L391.273 210.621L370.744 198.797V197.211L412.02 197.139V198.725Z" fill="#B6BAD5"/>
            <path d="M412.02 197.138L391.273 209.035L370.744 197.211L391.201 185.531L412.02 197.138Z" fill="#F4F4FC"/>
            <path d="M409.263 197.138L391.345 207.448L373.5 197.21L391.418 186.9L409.263 197.138Z" fill="#DBE2F3"/>
            <path d="M391.274 188.486L407.668 197.931L409.046 197.138L391.274 186.9L373.283 197.21L374.661 198.003L391.274 188.486Z" fill="#B6BAD5"/>
            <path d="M372.158 196.659L375.109 198.284L392.002 188.956L391.854 184.717L389.936 186.695L372.158 196.659Z" fill="#BACAE3"/>
            <path d="M371.368 177.727C377.31 177.155 381.869 174.057 381.553 170.807C381.236 167.557 376.163 165.386 370.221 165.958C364.28 166.53 359.72 169.628 360.036 172.878C360.353 176.128 365.426 178.299 371.368 177.727Z" fill="white"/>
            <path d="M378.359 173.097L374.193 174.85L364.717 170.435L368.883 168.683L378.359 173.097Z" fill="#0D80D8"/>
            <path d="M378.359 169.262L367.765 174.85L364.717 173.037L375.311 167.449L378.359 169.262Z" fill="#0D80D8"/>
            <path d="M223.328 278.753C223.328 278.753 279.702 278.753 282.11 278.753C284.518 278.753 285.572 277.386 285.572 274.519C285.572 271.652 285.572 232.406 285.57 229.875C285.569 227.344 286.592 226.979 288.692 226.979C290.792 226.979 320.397 226.979 320.397 226.979" stroke="url(#paint6_linear_1343_4733)"/>
            <path d="M223.328 278.753C223.328 278.753 279.702 278.753 282.11 278.753C284.518 278.753 285.572 277.386 285.572 274.519C285.572 271.652 285.572 232.406 285.57 229.875C285.569 227.344 286.592 226.979 288.692 226.979C290.792 226.979 320.397 226.979 320.397 226.979" stroke="#1A77CA"/>
            <path d="M216.077 279.572C216.077 279.572 216.275 233.803 216.29 231.395C216.305 228.987 217.679 227.942 220.545 227.96C223.412 227.978 240.586 228.083 243.117 228.1C245.648 228.117 246.019 227.097 246.032 224.996C246.045 222.896 246.032 180.787 246.032 180.787" stroke="url(#paint7_linear_1343_4733)"/>
            <path d="M216.077 279.572C216.077 279.572 216.275 233.803 216.29 231.395C216.305 228.987 217.679 227.942 220.545 227.96C223.412 227.978 240.586 228.083 243.117 228.1C245.648 228.117 246.019 227.097 246.032 224.996C246.045 222.896 246.032 180.787 246.032 180.787" stroke="#1A77CA"/>
            <path d="M223.512 288.901C223.512 288.901 244.333 288.901 246.741 288.901C249.149 288.901 250.202 290.269 250.202 293.135C250.202 296.002 250.206 341.575 250.204 344.106C250.203 346.638 251.226 347.002 253.326 347.002C255.426 347.002 312.594 347.002 312.594 347.002" stroke="url(#paint8_linear_1343_4733)"/>
            <path d="M223.512 288.901C223.512 288.901 244.333 288.901 246.741 288.901C249.149 288.901 250.202 290.269 250.202 293.135C250.202 296.002 250.206 341.575 250.204 344.106C250.203 346.638 251.226 347.002 253.326 347.002C255.426 347.002 312.594 347.002 312.594 347.002" stroke="#1A77CA"/>
            <path d="M190.447 284.169C190.643 283.974 190.643 283.658 190.447 283.462L187.265 280.28C187.07 280.085 186.753 280.085 186.558 280.28C186.363 280.476 186.363 280.792 186.558 280.987L189.387 283.816L186.558 286.644C186.363 286.84 186.363 287.156 186.558 287.351C186.753 287.547 187.07 287.547 187.265 287.351L190.447 284.169ZM138.094 283.316C137.818 283.316 137.594 283.54 137.594 283.816C137.594 284.092 137.818 284.316 138.094 284.316L138.094 283.316ZM190.094 283.316L138.094 283.316L138.094 284.316L190.094 284.316L190.094 283.316Z" fill="url(#paint9_linear_1343_4733)"/>
            <circle cx="246.094" cy="179.816" r="6" fill="#449BE1"/>
            <ellipse cx="211.094" cy="282.816" rx="17" ry="18" fill="#F4F6F8"/>
            <path d="M203.292 287.229C204.724 286.308 206.408 284.728 205.769 282.867C205.421 281.853 204.938 280.985 204.873 279.889C204.819 278.961 204.959 278.032 205.283 277.16C206.569 273.718 210.402 272.088 213.842 273.125C217.04 274.083 219.242 277.8 217.915 281.014C217.151 282.872 216.808 284.333 218.513 285.768C218.975 286.162 220.1 286.75 220.094 287.448C220.094 288.36 218.293 287.252 218.094 287.094C218.322 287.492 220.586 289.847 219.145 290.016C217.819 290.171 216.647 288.325 215.851 287.53C214.513 286.197 214.747 289.146 214.741 289.753C214.741 290.715 214.05 292.675 212.829 291.395C211.822 290.347 212.202 288.678 211.5 287.515C210.732 286.238 209.45 288.789 209.137 289.268C208.779 289.791 206.993 292.313 206.285 290.969C205.699 289.879 206.63 288.167 207.081 287.17C206.917 287.527 205.746 288.047 205.403 288.231C204.662 288.638 203.823 288.831 202.979 288.789C201.181 288.658 202.56 287.705 203.292 287.235V287.229Z" fill="#0D80D8"/>
            <path d="M43.409 351.498C44.5436 350.941 44.9828 349.626 44.3899 348.561C44.3302 348.454 44.2604 348.355 44.1855 348.262L47.2672 328.816L42.7114 328.843L40.8103 347.933C40.0226 348.579 39.7752 349.668 40.2811 350.577C40.8739 351.642 42.2743 352.054 43.409 351.498Z" fill="#FFB6B6"/>
            <path d="M44.1555 315.243C44.1555 315.243 42.7938 314.895 42.5959 317.696C42.4374 319.938 40.0576 333.599 40.723 336.656C39.8728 337.024 39.3602 337.895 39.5366 338.799L46.416 338.218C47.3597 337.046 47.3646 335.734 46.8238 335.459L47.3762 331.434L50.5694 318.321L45.1445 315.478L44.1555 315.243Z" fill="#C5AEEE"/>
            <path d="M48.6684 401.339L51.2827 401.339L52.5264 391.873L48.668 391.873L48.6684 401.339Z" fill="#FFB6B6"/>
            <path d="M52.152 401.56V399.896L51.4647 399.936L48.667 400.092L48.0196 400.129L47.5246 405.701L47.498 406H49.3543L49.414 405.703L49.6836 404.355L50.3797 405.703L50.5321 406H55.4534C55.7473 406 56.0147 405.892 56.2114 405.714C56.4258 405.525 56.5584 405.257 56.5584 404.961C56.7661 404.05 52.6735 402.081 52.152 401.56Z" fill="#2E475D"/>
            <path d="M37.1895 398.521L40.1104 399.062L42.8313 390.335L39.8993 389.402L37.1895 398.521Z" fill="#FFB6B6"/>
            <path d="M40.4822 399.612L40.9586 398.01L40.2853 397.874L37.5461 397.318L36.9118 397.191L34.8396 402.433L34.7285 402.714L36.5165 403.183L36.6589 402.912L37.3046 401.681L37.589 403.156L37.651 403.48L42.3912 404.722C42.6742 404.796 42.9627 404.76 43.2032 404.637C43.4637 404.51 43.668 404.285 43.753 403.999C44.2138 403.175 40.8355 400.246 40.4822 399.612Z" fill="#2E475D"/>
            <path d="M61.6168 347.989L61.6842 347.325L58.7764 339.841L46.3847 342.084C46.3847 342.084 39.9981 349.471 41.4183 354.359C41.8035 355.685 42.6267 371.313 42.3788 371.815C36.6708 383.389 38.2654 391.553 38.2654 391.553C38.2654 391.553 40.0695 393.03 43.1598 393.763C43.9661 393.954 50.0974 367.989 50.0974 367.989C50.0974 367.989 49.8273 369.609 49.4506 372.073C48.287 379.684 46.4593 395.006 48.0832 395.799C50.2328 396.849 52.2892 396.654 52.2892 396.654C52.2892 396.654 58.9909 377.75 57.5707 373.305" fill="#0F4778"/>
            <path d="M55.5298 315.831L51.7026 311.967L48.7933 311.808L44.8729 315.618L43.4192 319.467C41.792 323.178 41.4199 327.269 42.3532 331.185L44.586 340.554C44.586 340.554 44.93 341.658 44.0287 341.79C43.1275 341.923 43.2356 343.55 43.2356 343.55C43.2356 343.55 43.4191 344.961 42.544 345.085C41.6689 345.209 39.3354 345.209 41.3773 347.674C43.4192 350.138 59.5695 340.817 59.5695 340.817L59.6477 329.262C59.6477 329.262 64.1968 323.526 59.2978 320.24L55.5298 315.831Z" fill="#C5AEEE"/>
            <path opacity="0.35" d="M70.1032 356.661L58.033 352.568L45.9609 356.661L58.033 361.981L70.1032 356.661Z" fill="#332411"/>
            <path d="M58.1755 361.282L46.1035 355.845V343.376L58.1755 348.211V361.282Z" fill="#87BFEC"/>
            <path d="M58.1777 361.282L70.2498 355.845V343.376L58.1777 348.211V361.282Z" fill="#87BFEC"/>
            <path d="M70.2476 343.376L58.1755 338.799L46.1035 343.376L58.1755 348.211L70.2476 343.376Z" fill="#CDE4F7"/>
            <path d="M54.3844 340.217L50.9883 341.523L62.8343 346.413L66.1865 345.054L54.3844 340.217Z" fill="#1A77CA"/>
            <path d="M66.1892 345.033L62.7949 346.433V351.11L66.1892 349.75V345.033Z" fill="#1A77CA"/>
            <path d="M52.7285 340.856L64.5324 345.693" stroke="#093051" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.3414 347.904C63.198 347.011 63.1212 345.635 62.1699 344.831C62.0741 344.75 61.972 344.681 61.867 344.617L57.5311 325.384L53.2715 326.901L58.5816 345.414C58.0834 346.278 58.256 347.379 59.0678 348.066C60.0192 348.87 61.4848 348.798 62.3414 347.904Z" fill="#FFB6B6"/>
            <path d="M53.7417 315.73C53.7417 315.73 55.0273 315.184 55.6963 317.919C56.2319 320.109 60.8918 333.242 60.7517 336.359C61.6534 336.596 62.3068 337.379 62.2855 338.298L55.3952 338.751C54.2653 337.734 54.0385 336.439 54.526 336.087L53.2999 332.195L47.9297 319.725L52.805 316.109L53.7417 315.73Z" fill="#C5AEEE"/>
            <path d="M51.6814 309.282C54.7415 309.282 57.2222 306.953 57.2222 304.08C57.2222 301.208 54.7415 298.879 51.6814 298.879C48.6213 298.879 46.1406 301.208 46.1406 304.08C46.1406 306.953 48.6213 309.282 51.6814 309.282Z" fill="#FFB6B6"/>
            <path d="M56.8633 303.877C56.8792 303.877 56.9177 303.877 56.9903 303.879C57.2011 303.892 56.9154 303.882 56.8633 303.877Z" fill="#5D312E"/>
            <path d="M59.8138 303.381C59.773 303.475 59.6392 303.552 59.451 303.613C59.0928 303.185 58.6279 302.839 58.0973 302.624C58.3354 302.988 58.5304 303.375 58.6755 303.779C58.1608 303.85 57.5872 303.879 57.2176 303.882C57.1292 303.882 57.0521 303.882 56.9908 303.879C56.9001 303.728 56.755 303.483 56.6303 303.23C56.619 303.922 55.5918 305.961 55.6757 307.685C55.7778 309.748 55.8934 311.998 55.8934 311.998C55.5238 312.085 55.0771 312.147 54.5669 312.186C54.0046 311.217 53.5874 310.133 53.3629 309.035C53.338 308.918 53.3176 308.801 53.2972 308.684C53.2495 308.818 53.2042 308.954 53.1657 309.093C52.9956 309.667 52.8958 310.287 52.8641 310.93C52.8414 311.353 52.8505 311.783 52.8845 312.22C50.0865 312.754 46.6264 312.699 44.1231 311.394C44.207 311.179 44.2705 310.957 44.3113 310.732C44.4473 309.991 43.3499 309.887 42.6198 310.34C41.8897 309.652 41.3364 308.78 41.0371 307.688C45.8078 305.723 40.7854 303.305 44.47 299.82C44.0256 296.793 52.9276 295.918 54.6508 298.609C55.0272 298.341 56.1995 298.353 56.6983 298.549C57.1972 298.745 57.5826 299.075 57.959 299.394C58.2107 299.611 58.0928 300.044 57.9318 300.367C58.7232 300.012 60.4895 301.859 59.8138 303.381Z" fill="#5D312E"/>
            <path d="M108.782 287.204C110.225 287.937 110.954 289.269 110.413 290.178C109.871 291.086 108.263 291.229 106.818 290.495C106.237 290.21 105.745 289.791 105.382 289.274L99.3163 286.091L101.107 283.301L106.898 286.734C107.567 286.741 108.214 286.902 108.784 287.203L108.782 287.204Z" fill="#A0616A"/>
            <path d="M73.8191 266.228C73.8191 266.228 77.2336 262.745 78.6307 263.435C79.258 263.746 83.6013 270.575 89.9583 275.299C97.74 281.082 104.483 282.024 107.739 284.933C104.908 286.48 102.011 289.456 102.011 289.456L85.9397 279.987L73.821 266.227L73.8191 266.228Z" fill="#F2F8FD"/>
            <path d="M74.2246 265.671L79.9501 267.419V259.766H74.7545L74.2246 265.671Z" fill="#A0616A"/>
            <path d="M79.2425 261.884C82.3934 261.884 84.9478 259.512 84.9478 256.586C84.9478 253.661 82.3934 251.289 79.2425 251.289C76.0915 251.289 73.5371 253.661 73.5371 256.586C73.5371 259.512 76.0915 261.884 79.2425 261.884Z" fill="#A0616A"/>
            <path d="M77.7896 257.001L78.5765 257.888L79.9984 255.575C79.9984 255.575 81.8137 255.663 81.8137 254.411C81.8137 253.159 83.4793 253.124 83.4793 253.124C83.4793 253.124 85.8379 249.301 80.953 250.307C80.953 250.307 77.5638 248.153 75.8804 249.994C75.8804 249.994 70.7138 252.41 72.1916 256.615L74.6485 260.951L75.2052 259.97C75.2052 259.97 74.8676 255.849 77.7896 256.997V257.001Z" fill="black"/>
            <path opacity="0.35" d="M145.876 298.243L118.774 289.055L91.668 298.243L118.774 310.189L145.876 298.243Z" fill="#332411"/>
            <path d="M119.094 308.621L91.9883 296.414V268.415L119.094 279.272V308.621Z" fill="#87BFEC"/>
            <path d="M119.096 308.621L146.202 296.414V268.415L119.096 279.272V308.621Z" fill="#87BFEC"/>
            <path d="M146.201 268.415L119.094 258.138L91.9883 268.415L119.094 279.272L146.201 268.415Z" fill="#CDE4F7"/>
            <path d="M110.582 261.321L102.957 264.254L129.556 275.235L137.083 272.182L110.582 261.321Z" fill="#1A77CA"/>
            <path d="M137.084 272.137L129.463 275.28V285.781L137.084 282.728V272.137Z" fill="#1A77CA"/>
            <path d="M106.865 262.758L133.369 273.619" stroke="#093051" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M72.5061 351.266H77.1875V345.099H72.5061V351.266Z" fill="#A0616A"/>
            <path d="M76.6706 354.799C75.8702 354.865 71.8639 355.16 71.6627 354.307C71.4794 353.524 71.7499 352.706 71.7879 352.594C72.1725 349.037 72.3155 348.997 72.4027 348.974C72.5391 348.937 72.937 349.113 73.5831 349.499L73.6234 349.524L73.6323 349.568C73.6435 349.624 73.9297 350.93 75.2867 350.728C76.2167 350.591 76.5185 350.4 76.6147 350.307C76.5364 350.274 76.4381 350.215 76.3687 350.116C76.2681 349.971 76.2503 349.784 76.3173 349.56C76.4917 348.968 77.0148 348.094 77.0372 348.059L77.0976 347.959L82.4184 351.293L85.7048 352.165C85.9529 352.231 86.1519 352.395 86.2525 352.615C86.3911 352.922 86.3061 353.279 86.0379 353.503C85.441 354.001 84.2561 354.855 83.0108 354.96C82.6799 354.99 82.2418 355 81.7499 355C79.6954 355 76.6951 354.803 76.6728 354.799H76.6706Z" fill="#2E475D"/>
            <path d="M83.1689 291.933L70.8885 291.35C70.8885 291.35 69.0061 297.975 71.3849 304.879L71.671 346.452H78.6104L85.1519 303.567L83.1689 291.933Z" fill="#2E475D"/>
            <path d="M81.0954 264.202L74.355 260.881C74.355 260.881 67.0914 270.736 68.0952 276.075C69.1013 281.414 70.8853 291.352 70.8853 291.352L85.7545 291.935L83.1589 272.364L81.0954 264.202Z" fill="#F2F8FD"/>
            <path d="M75.8381 351.266H80.5195V345.099H75.8381V351.266Z" fill="#A0616A"/>
            <path d="M80.0006 354.799C79.2003 354.865 75.194 355.16 74.9928 354.307C74.8095 353.524 75.08 352.706 75.118 352.594C75.5025 349.037 75.6456 348.997 75.7328 348.974C75.8692 348.937 76.2671 349.113 76.9132 349.499L76.9535 349.524L76.9624 349.568C76.9736 349.624 77.2597 350.93 78.6168 350.728C79.5468 350.591 79.8486 350.4 79.9447 350.307C79.8665 350.274 79.7681 350.215 79.6988 350.116C79.5982 349.971 79.5803 349.784 79.6474 349.56C79.8218 348.968 80.3449 348.094 80.3673 348.059L80.4276 347.959L85.7485 351.293L89.0348 352.165C89.283 352.231 89.482 352.395 89.5826 352.615C89.7212 352.922 89.6362 353.279 89.3679 353.503C88.771 354.001 87.5861 354.855 86.3409 354.96C86.01 354.99 85.5718 355 85.08 355C83.0254 355 80.0252 354.803 80.0029 354.799H80.0006Z" fill="#2E475D"/>
            <path d="M85.7539 291.933L70.8846 291.35C70.8846 291.35 69.0022 297.975 71.381 304.879L74.256 346.452H81.1954L87.7369 303.567L85.7539 291.933Z" fill="#2E475D"/>
            <path d="M101.207 297.473C102.468 298.463 102.904 299.906 102.184 300.695C101.463 301.482 99.8571 301.319 98.5949 300.327C98.0843 299.938 97.6894 299.434 97.4422 298.859L92.1621 294.594L94.4999 292.198L99.4583 296.656C100.113 296.789 100.713 297.069 101.209 297.472L101.207 297.473Z" fill="#A0616A"/>
            <path d="M70.2034 271.613C70.2034 271.613 74.2771 268.845 75.5023 269.785C76.0522 270.208 78.8827 277.721 84.1265 283.551C90.5454 290.688 96.9572 292.885 99.5414 296.351C96.4432 297.332 92.9825 299.702 92.9825 299.702L79.2089 287.387L70.2054 271.612L70.2034 271.613Z" fill="#F2F8FD"/>
            <circle cx="327.094" cy="225.816" r="8" fill="#449BE1"/>
            <circle cx="316.133" cy="346.04" r="5.5" fill="#449BE1"/>
            <defs>
                <linearGradient id="paint0_linear_1343_4733" x1="260.393" y1="153.928" x2="262.023" y2="153.928" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1343_4733" x1="262.107" y1="154.933" x2="263.737" y2="154.933" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1343_4733" x1="263.822" y1="155.937" x2="265.452" y2="155.937" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1343_4733" x1="265.54" y1="156.943" x2="267.162" y2="156.943" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1343_4733" x1="267.257" y1="157.877" x2="268.879" y2="157.877" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint5_linear_1343_4733" x1="268.283" y1="165.524" x2="270.954" y2="165.524" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint6_linear_1343_4733" x1="304.826" y1="220.538" x2="215.99" y2="253.472" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint7_linear_1343_4733" x1="256.925" y1="197.388" x2="225.287" y2="281.126" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint8_linear_1343_4733" x1="294.254" y1="362.636" x2="209.729" y2="331.3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint9_linear_1343_4733" x1="143.99" y1="283.816" x2="189.184" y2="283.816" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1A77CA"/>
                    <stop offset="1" stopColor="#5DB1FC"/>
                </linearGradient>
            </defs>
        </svg>);
}
function IllustrationInLightTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 474 462" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M211.153 13.6366C282.586 -7.96488 343.275 71.2634 389.383 130.01C434.957 188.076 479.808 258.149 458.573 328.874C438.246 396.573 362.317 425.874 294.574 445.78C241.578 461.352 189.174 445.437 138.761 422.85C85.7014 399.076 12.1835 376.457 10.0362 318.292C7.92003 260.971 99.2472 256.046 130.836 208.194C171.495 146.603 140.568 34.9817 211.153 13.6366Z" fill="#DAE2E9" fillOpacity="0.5"/>
            <path opacity="0.5" d="M279.891 132.255C297.563 142.43 297.494 158.887 279.685 169.13C261.875 179.373 233.063 179.441 215.322 169.267C197.581 159.092 197.719 142.635 215.529 132.392C233.407 122.149 262.219 122.081 279.891 132.255Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M264.695 140.45C274.184 145.913 274.115 154.722 264.557 160.185C254.999 165.648 239.596 165.716 230.176 160.253C220.686 154.858 220.755 145.981 230.313 140.518C239.871 135.055 255.274 134.987 264.695 140.45Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path opacity="0.08" d="M252.623 165.298L276.678 151.367L247.381 134.493L223.254 148.351L252.623 165.298Z" fill="#282828"/>
            <path d="M245.943 152.301V161.492L216.574 144.546V135.354L245.943 152.301Z" fill="#AED0EB"/>
            <path d="M245.943 152.301L269.998 138.371L240.701 121.424L216.574 135.355L245.943 152.301Z" fill="#C5E6FF"/>
            <path d="M226.34 132.841L239.05 140.165L242.281 138.37L226.34 132.482V132.841Z" fill="#7AB2CE"/>
            <path d="M226.34 132.482L239.05 139.878L242.281 138.011L229.499 130.615L226.34 132.482Z" fill="#91C5E5"/>
            <path d="M223.829 126.02C223.327 127.24 223.614 128.174 224.834 128.892L246.879 141.674C247.669 142.176 249.033 141.53 249.392 140.525L255.137 121.927C255.927 119.916 254.921 118.695 254.06 118.193L232.231 105.627C231.441 105.124 230.507 105.052 229.933 106.417L223.829 126.02Z" fill="#9CC4E6"/>
            <path d="M223.181 125.804C222.678 127.025 222.965 127.959 224.186 128.677L246.231 141.458C247.021 141.961 248.385 141.315 248.744 140.309L254.488 121.711C255.278 119.701 254.273 118.48 253.411 117.978L231.582 105.411C230.792 104.909 229.859 104.837 229.284 106.201L223.181 125.804Z" fill="#B2DAF7"/>
            <path d="M225.189 127.025L246.301 139.161L252.691 119.916L231.652 107.781L225.189 127.025Z" fill="#F5F3F4"/>
            <path d="M252.691 119.916L246.301 139.161L225.189 127.025L227.272 120.778L231.652 107.781L241.777 113.598L252.691 119.916Z" fill="#EBF6FF"/>
            <path d="M241.277 113.884L240.631 115.895L237.184 125.948L227.777 120.562L231.152 110.438L231.87 108.427L241.277 113.884Z" fill="white"/>
            <path d="M241.277 113.884L240.631 115.895L231.152 110.438L231.87 108.427L241.277 113.884Z" fill="#EADEFC"/>
            <path d="M234.526 127.168L234.67 126.737L227.202 122.357L226.986 122.86L234.526 127.168Z" fill="#EADEFC"/>
            <path d="M234.239 127.958L234.383 127.528L226.915 123.147L226.771 123.65L234.239 127.958Z" fill="#EADEFC"/>
            <path d="M229.5 123.219L229.859 122.142L227.705 120.921L227.346 121.998L229.5 123.219Z" fill="#EADEFC"/>
            <path d="M231.869 124.583L232.228 123.506L230.074 122.286L229.715 123.363L231.869 124.583Z" fill="#C5E6FF"/>
            <path d="M234.312 126.02L234.671 124.942L232.517 123.722L232.158 124.727L234.312 126.02Z" fill="#EADEFC"/>
            <path d="M236.754 127.384L237.113 126.307L234.959 125.086L234.6 126.163L236.754 127.384Z" fill="#C5E6FF"/>
            <path d="M243.861 116.038L241.563 114.746L240.988 116.613L243.286 117.905L243.861 116.038Z" fill="#C5E6FF"/>
            <path d="M246.374 117.546L244.148 116.182L243.502 118.049L245.8 119.342L246.374 117.546Z" fill="#C5E6FF"/>
            <path d="M248.958 118.982L246.66 117.69L246.086 119.485L248.312 120.777L248.958 118.982Z" fill="#C5E6FF"/>
            <path d="M251.474 120.418L249.176 119.126L248.602 120.993L250.827 122.285L251.474 120.418Z" fill="#C5E6FF"/>
            <path d="M242.929 118.695L240.703 117.331L240.057 119.198L242.355 120.49L242.929 118.695Z" fill="#EADEFC"/>
            <path d="M245.443 120.132L243.217 118.839L242.57 120.634L244.868 121.998L245.443 120.132Z" fill="#EADEFC"/>
            <path d="M248.027 121.568L245.729 120.275L245.154 122.07L247.38 123.435L248.027 121.568Z" fill="#EADEFC"/>
            <path d="M250.538 123.076L248.241 121.711L247.666 123.578L249.964 124.871L250.538 123.076Z" fill="#EADEFC"/>
            <path d="M242.14 121.353L239.842 119.988L239.268 121.855L241.493 123.148L242.14 121.353Z" fill="#C5E6FF"/>
            <path d="M244.652 122.788L242.354 121.496L241.779 123.291L244.005 124.655L244.652 122.788Z" fill="#C5E6FF"/>
            <path d="M247.237 124.225L244.939 122.932L244.293 124.799L246.591 126.092L247.237 124.225Z" fill="#C5E6FF"/>
            <path d="M249.751 125.733L247.453 124.368L246.807 126.235L249.104 127.528L249.751 125.733Z" fill="#C5E6FF"/>
            <path d="M241.202 123.937L238.905 122.645L238.33 124.44L240.556 125.804L241.202 123.937Z" fill="#EADEFC"/>
            <path d="M243.72 125.373L241.422 124.081L240.848 125.948L243.074 127.24L243.72 125.373Z" fill="#EADEFC"/>
            <path d="M246.304 126.881L244.006 125.517L243.359 127.384L245.657 128.676L246.304 126.881Z" fill="#EADEFC"/>
            <path d="M248.816 128.317L246.518 127.025L245.943 128.82L248.169 130.184L248.816 128.317Z" fill="#EADEFC"/>
            <path d="M239.624 128.533L237.326 127.241L236.68 129.108L238.977 130.4L239.624 128.533Z" fill="#C5E6FF"/>
            <path d="M242.14 130.041L239.842 128.677L239.268 130.544L241.493 131.836L242.14 130.041Z" fill="#C5E6FF"/>
            <path d="M244.652 131.477L242.425 130.184L241.779 131.979L244.077 133.344L244.652 131.477Z" fill="#C5E6FF"/>
            <path d="M247.237 132.913L244.939 131.62L244.293 133.487L246.591 134.78L247.237 132.913Z" fill="#C5E6FF"/>
            <path d="M238.691 131.19L236.393 129.825L235.818 131.692L238.044 132.985L238.691 131.19Z" fill="#EADEFC"/>
            <path d="M241.202 132.625L238.905 131.333L238.33 133.128L240.556 134.492L241.202 132.625Z" fill="#EADEFC"/>
            <path d="M243.792 134.062L241.494 132.769L240.848 134.564L243.145 135.929L243.792 134.062Z" fill="#EADEFC"/>
            <path d="M246.304 135.569L244.006 134.205L243.359 136.072L245.657 137.365L246.304 135.569Z" fill="#EADEFC"/>
            <path d="M227.92 127.886L228.279 126.809L226.125 125.588L225.766 126.666L227.92 127.886Z" fill="#EADEFC"/>
            <path d="M230.291 129.251L230.65 128.245L228.496 126.953L228.137 128.03L230.291 129.251Z" fill="#C5E6FF"/>
            <path d="M232.73 130.687L233.089 129.61L230.935 128.389L230.576 129.394L232.73 130.687Z" fill="#EADEFC"/>
            <path d="M235.172 132.052L235.531 130.974L233.377 129.754L233.018 130.831L235.172 132.052Z" fill="#C5E6FF"/>
            <path d="M245.943 152.301V161.492L269.999 147.562V138.37L245.943 152.301Z" fill="#76A1C2"/>
            <path d="M245.943 153.809V154.814L216.574 137.868V136.863L245.943 153.809Z" fill="#416F91"/>
            <path d="M249.031 152.014L245.943 153.809V161.492L247.164 160.774L249.031 152.014Z" fill="#416F91"/>
            <path d="M245.227 156.394C245.227 156.466 245.226 156.537 245.155 156.609C245.083 156.681 244.939 156.753 244.796 156.609L225.48 145.479C225.336 145.407 225.193 145.192 225.121 145.048C225.049 144.977 225.049 144.833 225.049 144.761C225.049 144.618 225.121 144.546 225.192 144.474C225.264 144.402 225.408 144.402 225.48 144.474L244.796 155.604C245.011 155.819 245.227 156.178 245.227 156.394Z" fill="#274B66"/>
            <path d="M245.153 156.681C245.081 156.753 244.938 156.825 244.794 156.681L225.478 145.551C225.335 145.479 225.191 145.264 225.119 145.12L225.191 145.048C225.263 144.976 225.406 144.976 225.478 145.048L244.794 156.178C244.938 156.322 245.081 156.466 245.153 156.681Z" fill="#76A1C2"/>
            <path d="M227.131 148.567C227.131 149.213 226.629 149.501 226.126 149.142C225.551 148.783 225.049 147.993 225.049 147.346C225.049 146.7 225.551 146.413 226.126 146.772C226.7 147.131 227.131 147.921 227.131 148.567Z" fill="#0D80D8"/>
            <path d="M226.198 148.711L225.982 148.639V147.275L226.198 147.347V148.711Z" fill="white"/>
            <path opacity="0.08" d="M273.659 157.112L260.375 164.795L270.715 170.755L284.071 163.072L273.659 157.112Z" fill="#282828"/>
            <path d="M267.199 159.338V168.745L280.555 161.061V151.655L267.199 159.338Z" fill="#76A1C2"/>
            <path d="M270.644 163.933C270.644 164.077 270.573 164.293 270.429 164.293L267.772 165.8C267.628 165.872 267.557 165.8 267.557 165.657V164.364C267.557 164.221 267.628 164.005 267.772 164.005L270.429 162.497C270.573 162.426 270.644 162.497 270.644 162.641V163.933Z" fill="#173B56"/>
            <path d="M267.199 159.338V168.745L256.859 162.785V153.378L267.199 159.338Z" fill="#AED0EB"/>
            <path d="M261.383 161.421C261.383 161.923 261.024 162.139 260.593 161.852C260.162 161.636 259.803 160.99 259.803 160.487C259.803 159.985 260.162 159.769 260.593 160.056C261.095 160.344 261.383 160.918 261.383 161.421Z" fill="white"/>
            <path d="M270.144 145.694L256.859 153.378L267.199 159.337L280.556 151.654L270.144 145.694Z" fill="#D2E7F7"/>
            <path d="M267.197 163.574V165.441L263.319 163.215C262.888 163 262.529 162.353 262.529 161.851V161.779C262.529 161.276 262.888 161.061 263.319 161.348L267.197 163.574Z" fill="#76A1C2"/>
            <path d="M264.037 162.641C264.037 162.928 263.822 163.072 263.607 162.928C263.391 162.784 263.176 162.425 263.176 162.138C263.176 161.851 263.391 161.707 263.607 161.851C263.822 161.995 264.037 162.354 264.037 162.641Z" fill="#173B56"/>
            <path d="M261.381 161.42C261.381 161.851 261.094 162.067 260.663 161.851C260.304 161.636 259.945 161.061 259.945 160.63C259.945 160.2 260.233 159.984 260.663 160.2C261.022 160.415 261.381 160.918 261.381 161.42Z" fill="#0D80D8"/>
            <path d="M265.405 163.43C265.405 163.718 265.189 163.861 264.974 163.718C264.758 163.574 264.543 163.215 264.543 162.928C264.543 162.64 264.758 162.497 264.974 162.64C265.189 162.712 265.405 163.071 265.405 163.43Z" fill="#173B56"/>
            <path d="M266.838 164.221C266.838 164.508 266.623 164.652 266.407 164.508C266.12 164.364 265.977 164.005 265.977 163.718C265.977 163.431 266.192 163.287 266.407 163.431C266.623 163.574 266.838 163.933 266.838 164.221Z" fill="#173B56"/>
            <path d="M261.096 161.349L260.162 160.774V160.631L261.096 161.205V161.349Z" fill="white"/>
            <path d="M260.666 160.99L260.594 160.918V160.631L260.666 160.702V160.99Z" fill="white"/>
            <path d="M260.234 161.061L260.162 160.99V160.631L260.234 160.702V161.061Z" fill="white"/>
            <path d="M261.093 161.563L261.021 161.492V161.133L261.093 161.204V161.563Z" fill="white"/>
            <path d="M269.927 156.538V156.179L268.275 155.604L268.203 155.533H268.131L266.48 154.599V154.527H266.408L266.264 154.455L264.828 153.594H264.757L264.685 153.522L264.541 153.45L263.105 152.588H263.033L262.961 152.517L261.382 151.655V151.583L259.371 152.373V152.732L259.443 152.804L261.094 153.737L261.166 153.809L262.746 154.743L262.89 154.814L264.469 155.748L265.044 155.461L264.469 155.748L264.613 155.82L266.193 156.753L267.844 155.82L267.629 155.892L266.193 156.753H266.264L267.916 157.687L269.639 156.753L269.927 156.538Z" fill="#416F91"/>
            <path d="M261.097 153.378L259.445 152.445L261.456 151.296L263.036 152.229L261.097 153.378Z" fill="#416F91"/>
            <path d="M262.961 152.158L260.951 153.307L259.371 152.373L261.382 151.224L262.961 152.158Z" fill="#76A1C2"/>
            <path d="M261.528 152.876L260.092 152.014L260.235 151.942L261.743 152.804L261.528 152.876Z" fill="url(#paint0_linear_1467_20858)"/>
            <path d="M262.964 152.157L262.389 152.516C262.317 152.444 262.389 152.372 262.461 152.301L262.82 152.085L262.964 152.157Z" fill="#416F91"/>
            <path d="M261.525 151.296L261.166 151.511C261.094 151.583 260.95 151.583 260.807 151.583L261.381 151.224L261.525 151.296Z" fill="#5E95AA"/>
            <path d="M261.381 151.224L261.165 151.368C261.093 151.368 261.021 151.44 261.021 151.44L261.381 151.224Z" fill="white"/>
            <path d="M263.035 152.157L262.82 152.3C262.748 152.3 262.748 152.372 262.748 152.372L263.035 152.157Z" fill="white"/>
            <path d="M262.748 154.383L261.168 153.45L263.179 152.229L264.758 153.234L262.748 154.383Z" fill="#416F91"/>
            <path d="M264.686 153.163L262.675 154.311L261.096 153.378L263.034 152.229L264.686 153.163Z" fill="#76A1C2"/>
            <path d="M263.249 153.881L261.812 153.019L261.956 152.947L263.392 153.809L263.249 153.881Z" fill="url(#paint1_linear_1467_20858)"/>
            <path d="M264.686 153.163L264.112 153.522C264.04 153.45 264.112 153.378 264.184 153.306L264.543 153.091L264.686 153.163Z" fill="#416F91"/>
            <path d="M263.177 152.301L262.818 152.516C262.746 152.588 262.603 152.588 262.459 152.588L263.033 152.229L263.177 152.301Z" fill="#416F91"/>
            <path d="M263.107 152.229L262.892 152.373C262.82 152.373 262.748 152.444 262.748 152.444L263.107 152.229Z" fill="white"/>
            <path d="M264.758 153.162L264.543 153.306C264.471 153.306 264.471 153.378 264.471 153.378L264.758 153.162Z" fill="white"/>
            <path d="M264.47 155.316L262.891 154.383L264.901 153.234L266.481 154.167L264.47 155.316Z" fill="#416F91"/>
            <path d="M266.41 154.167L264.4 155.316L262.748 154.383L264.759 153.234L266.41 154.167Z" fill="#76A1C2"/>
            <path d="M264.971 154.885L263.535 154.024L263.679 153.952L265.115 154.814L264.971 154.885Z" fill="url(#paint2_linear_1467_20858)"/>
            <path d="M266.409 154.168L265.834 154.527C265.763 154.455 265.834 154.384 265.906 154.312L266.265 154.096L266.409 154.168Z" fill="#416F91"/>
            <path d="M264.902 153.306L264.543 153.521C264.471 153.593 264.327 153.593 264.184 153.593L264.758 153.234L264.902 153.306Z" fill="#416F91"/>
            <path d="M264.83 153.234L264.614 153.377C264.543 153.377 264.471 153.449 264.471 153.449L264.83 153.234Z" fill="white"/>
            <path d="M266.482 154.168L266.267 154.312C266.195 154.312 266.195 154.383 266.195 154.383L266.482 154.168Z" fill="white"/>
            <path d="M266.191 156.322L264.611 155.388L266.622 154.24L268.202 155.173L266.191 156.322Z" fill="#416F91"/>
            <path d="M268.133 155.101L266.122 156.322L264.471 155.317L266.481 154.168L268.133 155.101Z" fill="#76A1C2"/>
            <path d="M266.696 155.891L265.26 155.029L265.403 154.957L266.839 155.747L266.696 155.891Z" fill="url(#paint3_linear_1467_20858)"/>
            <path d="M268.13 155.101L267.555 155.46C267.483 155.388 267.555 155.316 267.627 155.244L267.986 155.029L268.13 155.101Z" fill="#416F91"/>
            <path d="M266.624 154.24L266.265 154.455C266.193 154.527 266.05 154.527 265.906 154.527L266.481 154.168L266.624 154.24Z" fill="#416F91"/>
            <path d="M266.554 154.24L266.339 154.383C266.267 154.383 266.195 154.455 266.195 154.455L266.554 154.24Z" fill="white"/>
            <path d="M268.201 155.173L267.986 155.317C267.914 155.317 267.914 155.389 267.914 155.389L268.201 155.173Z" fill="white"/>
            <path d="M267.915 157.327L266.264 156.394L268.274 155.245L269.926 156.179L267.915 157.327Z" fill="#416F91"/>
            <path d="M269.857 156.107L267.847 157.256L266.195 156.322L268.206 155.173L269.857 156.107Z" fill="#76A1C2"/>
            <path d="M268.42 156.825L266.912 156.035L267.056 155.892L268.564 156.753L268.42 156.825Z" fill="url(#paint4_linear_1467_20858)"/>
            <path d="M269.856 156.106L269.282 156.465C269.21 156.394 269.282 156.322 269.353 156.25L269.712 156.035L269.856 156.106Z" fill="#416F91"/>
            <path d="M268.347 155.245L267.988 155.461C267.916 155.533 267.773 155.532 267.629 155.532L268.203 155.173L268.347 155.245Z" fill="#416F91"/>
            <path d="M268.273 155.173L268.058 155.317C267.986 155.317 267.914 155.389 267.914 155.389L268.273 155.173Z" fill="white"/>
            <path d="M269.854 156.178L269.638 156.322C269.566 156.322 269.566 156.394 269.566 156.394L269.854 156.178Z" fill="white"/>
            <path d="M270.502 162.785V163.862C270.502 164.005 270.43 164.149 270.286 164.221L267.917 165.585C267.845 165.657 267.773 165.585 267.701 165.585V164.436C267.701 164.293 267.773 164.149 267.917 164.077L270.286 162.713H270.358C270.43 162.641 270.502 162.713 270.502 162.785Z" fill="#295259"/>
            <path d="M270.43 162.713V163.79C270.43 163.933 270.358 164.077 270.215 164.149L267.845 165.513H267.773V164.364C267.773 164.221 267.845 164.077 267.989 164.005L270.359 162.641H270.43C270.358 162.641 270.43 162.713 270.43 162.713Z" fill="#416F91"/>
            <path d="M269.209 164.939V165.801L267.988 165.083V164.221L269.281 164.364L269.496 164.221C269.281 164.508 269.137 164.723 269.209 164.939Z" fill="#DBDBDB"/>
            <path d="M270.646 163.144C270.574 163.215 269.856 163.718 269.497 164.293C269.353 164.58 269.21 164.795 269.281 165.011L268.779 164.723L268.061 164.293L270.358 163L270.646 163.144Z" fill="url(#paint5_linear_1467_20858)"/>
            <path d="M270.645 163.144V164.005C270.573 164.077 269.352 164.939 269.209 165.657V165.011C269.137 164.795 269.28 164.58 269.424 164.293C269.855 163.718 270.573 163.216 270.645 163.144Z" fill="#C4C4C4"/>
            <path d="M263.535 138.801L267.844 135.857L268.633 135.714V136.001L263.535 138.801Z" fill="#6EA5BA"/>
            <path d="M268.635 135.714C268.635 135.786 268.563 135.858 268.491 135.858L263.249 138.945L253.053 144.833C252.837 144.977 251.545 145.839 251.545 146.557C251.401 146.557 243.861 141.961 243.861 141.961C243.933 141.458 244.077 140.812 244.436 140.597L245.01 140.238L260.52 131.262C260.664 131.19 260.951 131.19 261.095 131.262L268.491 135.499C268.563 135.57 268.635 135.642 268.635 135.714Z" fill="#AED0EB"/>
            <path d="M268.635 135.714V137.078C268.635 137.15 268.563 137.221 268.491 137.221C268.491 137.221 263.249 141.027 261.239 142.392C259.013 143.899 251.832 147.777 251.832 147.777C251.76 147.849 251.617 147.849 251.545 147.849V146.556C251.545 145.838 252.766 144.977 253.053 144.833L263.249 138.945L268.491 135.929C268.563 135.857 268.635 135.785 268.635 135.714Z" fill="#76A1C2"/>
            <path d="M266.551 136.216C266.479 136.432 266.335 136.575 266.048 136.719L262.027 139.017C261.309 139.448 260.088 139.448 259.37 139.017L255.205 136.575C254.846 136.36 254.703 136.145 254.631 135.857C254.631 135.57 254.775 135.283 255.205 135.067L259.227 132.77C259.945 132.339 261.165 132.339 261.883 132.77L266.048 135.211C266.551 135.498 266.694 135.857 266.551 136.216Z" fill="#6EA5BA"/>
            <path d="M266.551 136.217C266.479 136.432 266.335 136.576 266.048 136.719L262.027 139.017C261.309 139.448 260.088 139.448 259.37 139.017L255.205 136.576C254.846 136.36 254.703 136.145 254.631 135.858C254.703 135.642 254.846 135.498 255.133 135.355L259.155 133.057C259.873 132.626 261.093 132.626 261.812 133.057L265.976 135.498C266.335 135.714 266.551 135.929 266.551 136.217Z" fill="#D2E7F7"/>
            <path d="M251.614 146.556V147.921C251.47 147.921 251.399 147.921 251.327 147.849L243.931 143.612C243.859 143.541 243.787 143.541 243.787 143.469V143.11C243.787 143.11 243.859 142.176 243.859 141.961C243.931 141.961 251.47 146.556 251.614 146.556Z" fill="#6DA8BF"/>
            <path d="M258.939 139.376L254.488 136.863C253.841 136.504 252.692 136.504 252.046 136.863L246.014 140.309C245.727 140.453 245.584 140.669 245.512 140.884L252.046 137.222C252.621 136.863 253.626 136.863 254.272 137.222L258.221 139.52C258.796 139.879 258.796 140.453 258.221 140.812L251.543 144.618C252.046 144.618 252.549 144.546 252.908 144.331L258.939 140.884C259.658 140.453 259.658 139.807 258.939 139.376Z" fill="#6EA5BA"/>
            <path d="M246.158 139.304C246.158 139.304 245.656 140.238 245.584 140.956C245.584 140.956 247.451 139.663 248.384 139.161C249.39 138.658 251.975 136.791 253.267 136.863C254.56 136.935 258.294 139.161 258.294 139.161C258.294 139.161 258.868 139.089 258.868 138.443C258.868 137.797 255.421 136.001 254.488 135.571C253.554 135.14 252.334 135.571 250.969 136.36C249.749 137.15 246.446 138.73 246.158 139.304Z" fill="#76A1C2"/>
            <path d="M253.986 137.868C254.13 137.94 254.13 138.084 253.986 138.155L253.411 138.514C253.268 138.586 253.052 138.586 252.909 138.514L252.047 138.012C251.904 137.94 251.904 137.796 252.047 137.725L252.621 137.366C252.765 137.294 252.981 137.294 253.124 137.366L253.986 137.868Z" fill="#6EA5BA"/>
            <path d="M252.55 138.658C252.694 138.73 252.694 138.873 252.55 138.945L251.976 139.304C251.832 139.376 251.617 139.376 251.473 139.304L250.612 138.801C250.468 138.73 250.468 138.586 250.612 138.514L251.186 138.155C251.33 138.083 251.545 138.083 251.689 138.155L252.55 138.658Z" fill="#6EA5BA"/>
            <path d="M250.97 139.519C251.114 139.591 251.114 139.734 250.97 139.806L250.396 140.165C250.252 140.237 250.037 140.237 249.893 140.165L249.032 139.662C248.888 139.591 248.888 139.447 249.032 139.375L249.606 139.016C249.75 138.944 249.965 138.944 250.109 139.016L250.97 139.519Z" fill="#6EA5BA"/>
            <path d="M249.462 140.381C249.606 140.453 249.606 140.597 249.462 140.669L248.888 141.028C248.744 141.099 248.529 141.099 248.385 141.028L247.524 140.525C247.38 140.453 247.38 140.31 247.524 140.238L248.098 139.879C248.242 139.807 248.457 139.807 248.601 139.879L249.462 140.381Z" fill="#6EA5BA"/>
            <path d="M248.027 141.171C248.171 141.243 248.171 141.386 248.027 141.458L247.452 141.817C247.309 141.889 247.093 141.889 246.95 141.817L246.088 141.315C245.945 141.243 245.945 141.099 246.088 141.027L246.663 140.668C246.806 140.596 247.022 140.596 247.165 140.668L248.027 141.171Z" fill="#6EA5BA"/>
            <path d="M253.986 137.724C254.13 137.795 254.13 137.939 253.986 138.011L253.411 138.37C253.268 138.442 253.052 138.442 252.909 138.37L252.047 137.867C251.904 137.795 251.904 137.652 252.047 137.58L252.621 137.221C252.765 137.149 252.981 137.149 253.124 137.221L253.986 137.724Z" fill="#E5F4FF"/>
            <path d="M252.55 138.514C252.694 138.586 252.694 138.729 252.55 138.801L251.976 139.16C251.832 139.232 251.617 139.232 251.473 139.16L250.612 138.658C250.468 138.586 250.468 138.442 250.612 138.37L251.186 138.011C251.33 137.94 251.545 137.94 251.689 138.011L252.55 138.514Z" fill="#E5F4FF"/>
            <path d="M250.97 139.447C251.114 139.519 251.114 139.663 250.97 139.735L250.396 140.094C250.252 140.165 250.037 140.165 249.893 140.094L249.032 139.591C248.888 139.519 248.888 139.376 249.032 139.304L249.606 138.945C249.75 138.873 249.965 138.873 250.109 138.945L250.97 139.447Z" fill="#E5F4FF"/>
            <path d="M249.462 140.237C249.606 140.309 249.606 140.452 249.462 140.524L248.888 140.883C248.744 140.955 248.529 140.955 248.385 140.883L247.524 140.38C247.38 140.309 247.38 140.165 247.524 140.093L248.098 139.734C248.242 139.662 248.457 139.662 248.601 139.734L249.462 140.237Z" fill="#E5F4FF"/>
            <path d="M248.027 141.099C248.171 141.171 248.171 141.315 248.027 141.387L247.452 141.746C247.309 141.818 247.093 141.818 246.95 141.746L246.088 141.243C245.945 141.171 245.945 141.028 246.088 140.956L246.663 140.597C246.806 140.525 247.022 140.525 247.165 140.597L248.027 141.099Z" fill="#E5F4FF"/>
            <path d="M255.638 138.945C255.782 139.017 255.782 139.161 255.638 139.233L255.064 139.592C254.92 139.663 254.705 139.663 254.561 139.592L253.7 139.089C253.556 139.017 253.556 138.873 253.7 138.802L254.274 138.443C254.418 138.371 254.633 138.371 254.777 138.443L255.638 138.945Z" fill="#6EA5BA"/>
            <path d="M254.201 139.735C254.345 139.807 254.345 139.95 254.201 140.022L253.626 140.381C253.483 140.453 253.267 140.453 253.124 140.381L252.262 139.878C252.118 139.807 252.118 139.663 252.262 139.591L252.836 139.232C252.98 139.16 253.195 139.16 253.339 139.232L254.201 139.735Z" fill="#6EA5BA"/>
            <path d="M252.623 140.668C252.766 140.74 252.766 140.884 252.623 140.955L252.048 141.314C251.905 141.386 251.689 141.386 251.546 141.314L250.684 140.812C250.54 140.74 250.54 140.596 250.684 140.525L251.258 140.166C251.402 140.094 251.617 140.094 251.761 140.166L252.623 140.668Z" fill="#6EA5BA"/>
            <path d="M251.113 141.459C251.256 141.53 251.256 141.674 251.113 141.746L250.538 142.105C250.395 142.177 250.179 142.177 250.036 142.105L249.174 141.602C249.031 141.53 249.031 141.387 249.174 141.315L249.748 140.956C249.892 140.884 250.108 140.884 250.251 140.956L251.113 141.459Z" fill="#6EA5BA"/>
            <path d="M249.677 142.32C249.821 142.391 249.821 142.535 249.677 142.607L249.103 142.966C248.959 143.038 248.744 143.038 248.6 142.966L247.739 142.463C247.595 142.391 247.595 142.248 247.739 142.176L248.313 141.817C248.456 141.745 248.672 141.745 248.816 141.817L249.677 142.32Z" fill="#6EA5BA"/>
            <path d="M255.638 138.801C255.782 138.873 255.782 139.016 255.638 139.088L255.064 139.447C254.92 139.519 254.705 139.519 254.561 139.447L253.7 138.944C253.556 138.873 253.556 138.729 253.7 138.657L254.274 138.298C254.418 138.226 254.633 138.226 254.777 138.298L255.638 138.801Z" fill="#E5F4FF"/>
            <path d="M254.201 139.663C254.345 139.735 254.345 139.879 254.201 139.951L253.626 140.31C253.483 140.381 253.267 140.381 253.124 140.31L252.262 139.807C252.118 139.735 252.118 139.592 252.262 139.52L252.836 139.161C252.98 139.089 253.195 139.089 253.339 139.161L254.201 139.663Z" fill="#E5F4FF"/>
            <path d="M252.623 140.524C252.766 140.596 252.766 140.74 252.623 140.812L252.048 141.171C251.905 141.243 251.689 141.243 251.546 141.171L250.684 140.668C250.54 140.596 250.54 140.453 250.684 140.381L251.258 140.022C251.402 139.95 251.617 139.95 251.761 140.022L252.623 140.524Z" fill="#E5F4FF"/>
            <path d="M251.113 141.386C251.256 141.458 251.256 141.602 251.113 141.674L250.538 142.033C250.395 142.104 250.179 142.104 250.036 142.033L249.174 141.53C249.031 141.458 249.031 141.314 249.174 141.243L249.748 140.884C249.892 140.812 250.108 140.812 250.251 140.884L251.113 141.386Z" fill="#E5F4FF"/>
            <path d="M249.677 142.176C249.821 142.248 249.821 142.392 249.677 142.464L249.103 142.823C248.959 142.895 248.744 142.895 248.6 142.823L247.739 142.32C247.595 142.248 247.595 142.105 247.739 142.033L248.313 141.674C248.456 141.602 248.672 141.602 248.816 141.674L249.677 142.176Z" fill="#E5F4FF"/>
            <path d="M257.363 139.95C257.506 140.022 257.506 140.166 257.363 140.237L256.788 140.596C256.645 140.668 256.429 140.668 256.286 140.596L255.424 140.094C255.281 140.022 255.281 139.878 255.424 139.807L255.999 139.447C256.142 139.376 256.358 139.376 256.501 139.447L257.363 139.95Z" fill="#6EA5BA"/>
            <path d="M255.925 140.74C256.069 140.812 256.069 140.956 255.925 141.028L255.351 141.387C255.207 141.458 254.992 141.458 254.848 141.387L253.987 140.884C253.843 140.812 253.843 140.669 253.987 140.597L254.561 140.238C254.705 140.166 254.92 140.166 255.064 140.238L255.925 140.74Z" fill="#6EA5BA"/>
            <path d="M254.343 141.602C254.487 141.673 254.487 141.817 254.343 141.889L253.769 142.248C253.625 142.32 253.41 142.32 253.266 142.248L252.405 141.745C252.261 141.673 252.261 141.53 252.405 141.458L252.979 141.099C253.123 141.027 253.338 141.027 253.482 141.099L254.343 141.602Z" fill="#6EA5BA"/>
            <path d="M252.836 142.463C252.979 142.535 252.979 142.679 252.836 142.751L252.261 143.11C252.117 143.181 251.902 143.181 251.758 143.11L250.897 142.607C250.753 142.535 250.753 142.391 250.897 142.32L251.471 141.961C251.615 141.889 251.83 141.889 251.974 141.961L252.836 142.463Z" fill="#6EA5BA"/>
            <path d="M251.4 143.254C251.544 143.325 251.544 143.469 251.4 143.541L250.825 143.9C250.682 143.972 250.466 143.972 250.323 143.9L249.461 143.397C249.318 143.325 249.318 143.182 249.461 143.11L250.036 142.751C250.179 142.679 250.395 142.679 250.538 142.751L251.4 143.254Z" fill="#6EA5BA"/>
            <path d="M257.363 139.806C257.506 139.878 257.506 140.022 257.363 140.094L256.788 140.453C256.645 140.524 256.429 140.524 256.286 140.453L255.424 139.95C255.281 139.878 255.281 139.735 255.424 139.663L255.999 139.304C256.142 139.232 256.358 139.232 256.501 139.304L257.363 139.806Z" fill="#E5F4FF"/>
            <path d="M255.925 140.596C256.069 140.668 256.069 140.811 255.925 140.883L255.351 141.242C255.207 141.314 254.992 141.314 254.848 141.242L253.987 140.74C253.843 140.668 253.843 140.524 253.987 140.452L254.561 140.093C254.705 140.021 254.92 140.021 255.064 140.093L255.925 140.596Z" fill="#E5F4FF"/>
            <path d="M254.343 141.53C254.487 141.602 254.487 141.745 254.343 141.817L253.769 142.176C253.625 142.248 253.41 142.248 253.266 142.176L252.405 141.674C252.261 141.602 252.261 141.458 252.405 141.386L252.979 141.027C253.123 140.955 253.338 140.955 253.482 141.027L254.343 141.53Z" fill="#E5F4FF"/>
            <path d="M252.836 142.32C252.979 142.391 252.979 142.535 252.836 142.607L252.261 142.966C252.117 143.038 251.902 143.038 251.758 142.966L250.897 142.463C250.753 142.391 250.753 142.248 250.897 142.176L251.471 141.817C251.615 141.745 251.83 141.745 251.974 141.817L252.836 142.32Z" fill="#E5F4FF"/>
            <path d="M251.4 143.181C251.544 143.253 251.544 143.397 251.4 143.469L250.825 143.828C250.682 143.9 250.466 143.9 250.323 143.828L249.461 143.325C249.318 143.253 249.318 143.11 249.461 143.038L250.036 142.679C250.179 142.607 250.395 142.607 250.538 142.679L251.4 143.181Z" fill="#E5F4FF"/>
            <path d="M251.905 142.822C251.905 142.822 251.33 143.468 251.618 144.617C251.618 144.617 257.218 141.673 258.08 141.099C258.942 140.524 259.444 140.021 259.444 140.021V138.514C259.444 138.514 255.854 140.883 254.418 141.673C253.054 142.463 251.905 142.822 251.905 142.822Z" fill="#76A1C2"/>
            <path d="M259.014 137.868L254.777 135.427C254.131 135.068 253.054 135.068 252.407 135.427L246.663 138.73C246.376 138.873 246.232 139.089 246.16 139.304L252.479 135.786C253.054 135.427 253.987 135.427 254.562 135.786L258.367 137.94C258.942 138.299 258.942 138.802 258.367 139.161L251.905 142.823C252.407 142.823 252.838 142.751 253.197 142.536L258.942 139.232C259.66 138.802 259.66 138.227 259.014 137.868Z" fill="#D7F9FF"/>
            <path d="M250.897 146.844C250.849 146.94 250.801 146.964 250.754 146.916L244.722 143.397C244.65 143.397 244.578 143.325 244.578 143.182V143.11C244.578 143.038 244.65 142.966 244.65 142.966H244.722L250.754 146.485C250.825 146.628 250.897 146.7 250.897 146.844Z" fill="#152F35"/>
            <path d="M250.897 146.915C250.897 146.987 250.825 146.987 250.754 146.915L244.722 143.397C244.65 143.397 244.578 143.325 244.578 143.181L244.65 143.109H244.722L250.754 146.628C250.825 146.772 250.825 146.843 250.897 146.915Z" fill="#254B56"/>
            <path d="M249.388 145.91C249.604 146.053 249.604 146.197 249.388 146.34L243.788 149.572C243.572 149.715 243.213 149.715 242.998 149.572L239.982 147.848C239.766 147.705 239.766 147.561 239.982 147.418L245.583 144.186C245.798 144.043 246.157 144.043 246.373 144.186L249.388 145.91Z" fill="#124164"/>
            <path d="M249.388 145.767C249.604 145.91 249.604 146.054 249.388 146.197L243.788 149.429C243.572 149.572 243.213 149.572 242.998 149.429L239.982 147.705C239.766 147.562 239.766 147.418 239.982 147.275L245.583 144.043C245.798 143.9 246.157 143.9 246.373 144.043L249.388 145.767Z" fill="#0D80D8"/>
            <path d="M244.723 145.192C244.795 145.264 244.795 145.336 244.723 145.336L241.277 147.346C241.205 147.418 241.061 147.418 240.989 147.346C240.918 147.274 240.918 147.203 240.989 147.203L244.436 145.192C244.508 145.12 244.651 145.12 244.723 145.192Z" fill="#475158"/>
            <path d="M245.082 145.623H244.866L242.281 147.131L241.491 147.561C241.42 147.561 241.42 147.633 241.491 147.705H241.707L244.292 146.197L245.082 145.766V145.623Z" fill="#475158"/>
            <path d="M245.439 145.839H245.224L242.639 147.347L241.849 147.777C241.777 147.777 241.777 147.849 241.849 147.921H242.064L244.649 146.413L245.439 145.982C245.487 145.934 245.487 145.887 245.439 145.839Z" fill="#475158"/>
            <path d="M246.158 146.269H246.014L244.076 147.418L243.501 147.777C243.429 147.777 243.429 147.849 243.501 147.849H243.645L245.584 146.7L246.158 146.341V146.269Z" fill="#475158"/>
            <path d="M246.735 144.618C246.807 144.618 246.807 144.689 246.735 144.689L245.873 145.192H245.658L245.155 144.905C245.084 144.905 245.084 144.833 245.155 144.833L246.017 144.33H246.233L246.735 144.618Z" fill="#475158"/>
            <path opacity="0.5" d="M247.593 146.341C247.808 146.485 247.665 146.7 247.306 146.916C246.947 147.131 246.516 147.203 246.3 147.059C246.085 146.916 246.229 146.7 246.588 146.485C246.947 146.269 247.377 146.198 247.593 146.341Z" fill="#AAB3BA"/>
            <path opacity="0.5" d="M248.243 145.982C248.459 146.126 248.315 146.341 247.956 146.557C247.597 146.772 247.166 146.844 246.951 146.7C246.735 146.557 246.879 146.341 247.238 146.126C247.597 145.91 248.028 145.838 248.243 145.982Z" fill="#657079"/>
            <path opacity="0.5" d="M393.797 326.201C411.469 336.421 411.401 352.952 393.591 363.24C375.781 373.529 346.97 373.598 329.229 363.378C311.488 353.158 311.625 336.627 329.435 326.339C347.313 316.05 376.125 316.05 393.797 326.201Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M378.601 334.433C388.09 339.851 388.022 348.768 378.464 354.256C368.906 359.743 353.503 359.811 344.082 354.324C334.593 348.837 334.661 339.989 344.22 334.501C353.778 329.014 369.181 328.945 378.601 334.433Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M368.074 338.704L350.338 329.5V311.765L368.074 320.968V338.704Z" fill="#DBE2F3"/>
            <path d="M378.542 332.661L368.074 338.704V320.968L378.542 314.925V332.661Z" fill="#BACAE3"/>
            <path d="M377.397 321.205L375.225 322.469V322.232L377.16 321.087L377.397 321.205Z" fill="#DBE2F3"/>
            <path d="M377.158 321.087V317.848L377.395 317.729V321.205L377.158 321.087Z" fill="#F4F4FC"/>
            <path d="M377.16 321.087L375.225 322.232V318.954L377.16 317.848V321.087Z" fill="#0E7FD5"/>
            <path d="M376.211 318.401V321.64" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.16 319.467L375.225 320.573" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M374.393 322.943L372.26 324.207V323.97L374.195 322.825L374.393 322.943Z" fill="#DBE2F3"/>
            <path d="M374.195 322.825V319.586L374.393 319.467V322.943L374.195 322.825Z" fill="#F4F4FC"/>
            <path d="M374.195 322.825L372.26 323.97V320.731L374.195 319.586V322.825Z" fill="#0E7FD5"/>
            <path d="M373.207 320.139V323.417" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M374.195 321.205L372.26 322.351" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.43 324.642L369.258 325.906V325.669L371.193 324.523L371.43 324.642Z" fill="#DBE2F3"/>
            <path d="M371.191 324.523V321.284L371.428 321.166V324.642L371.191 324.523Z" fill="#F4F4FC"/>
            <path d="M371.193 324.523L369.258 325.669V322.39L371.193 321.284V324.523Z" fill="#0E7FD5"/>
            <path d="M370.246 321.837V325.076" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.193 322.904L369.258 324.01" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.643 317.967L353.815 319.231V318.994L351.84 317.848L351.643 317.967Z" fill="#C5D1E8"/>
            <path d="M351.84 317.848V314.609L351.643 314.49V317.967L351.84 317.848Z" fill="#F4F4FC"/>
            <path d="M351.84 317.848L353.815 318.993V315.715L351.84 314.609V317.848Z" fill="#0E7FD5"/>
            <path d="M352.826 315.162V318.401" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.84 316.229L353.815 317.374" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M354.605 319.744L356.778 320.969V320.732L354.842 319.626L354.605 319.744Z" fill="#C5D1E8"/>
            <path d="M354.842 319.626V316.347L354.605 316.229V319.744L354.842 319.626Z" fill="#F4F4FC"/>
            <path d="M354.84 319.626L356.775 320.732V317.493L354.84 316.347V319.626Z" fill="#0E7FD5"/>
            <path d="M355.791 316.9V320.178" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M354.84 317.967L356.775 319.112" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M357.609 321.403L359.782 322.667V322.43L357.807 321.285L357.609 321.403Z" fill="#C5D1E8"/>
            <path d="M357.807 321.285V318.046L357.609 317.927V321.403L357.807 321.285Z" fill="#F4F4FC"/>
            <path d="M357.803 321.285L359.778 322.43V319.152L357.803 318.046V321.285Z" fill="#0E7FD5"/>
            <path d="M358.793 318.599V321.838" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M357.803 319.665L359.778 320.81" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M351.643 328.395L353.578 329.54V321.008L351.643 319.863V323.141V328.395Z" fill="#0E7FD5"/>
            <path d="M354.328 329.896L356.303 331.002V322.47L354.328 321.364V324.603V329.896Z" fill="#0E7FD5"/>
            <path d="M360.806 333.53L362.742 334.675L362.623 325.748L360.688 324.642V327.881L360.806 333.53Z" fill="#0E7FD5"/>
            <path d="M363.77 335.307L365.745 336.453L365.627 327.526L363.691 326.42V329.659L363.77 335.307Z" fill="#0E7FD5"/>
            <path d="M379.252 315.201L368.034 321.679L349.666 311.567V310.698L379.252 314.332V315.201Z" fill="#B6BAD5"/>
            <path d="M379.252 314.332L368.034 320.81L349.666 310.698L360.687 304.457L379.252 314.332Z" fill="#F4F4FC"/>
            <path d="M377.752 314.372L368.074 319.981L351.168 310.698L360.49 305.247L377.752 314.372Z" fill="#DBE2F3"/>
            <path d="M360.489 306.116L377.001 314.767L377.751 314.332L360.489 305.247L351.049 310.698L351.799 311.093L360.489 306.116Z" fill="#B6BAD5"/>
            <path d="M360.648 322.983L362.821 324.247V323.97L360.885 322.864L360.648 322.983Z" fill="#C5D1E8"/>
            <path d="M360.885 322.864V319.625L360.648 319.507V322.983L360.885 322.864Z" fill="#F4F4FC"/>
            <path d="M360.887 322.864L362.822 323.97V320.731L360.887 319.625V322.864Z" fill="#0E7FD5"/>
            <path d="M361.832 320.178V323.417" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.887 321.245L362.822 322.351" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M363.652 324.721L365.785 325.985V325.748L363.85 324.602L363.652 324.721Z" fill="#C5D1E8"/>
            <path d="M363.85 324.602V321.363L363.652 321.245V324.721L363.85 324.602Z" fill="#F4F4FC"/>
            <path d="M363.846 324.602L365.781 325.748V322.469L363.846 321.363V324.602Z" fill="#0E7FD5"/>
            <path d="M364.836 321.916V325.155" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M363.846 322.983L365.781 324.089" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M356.777 332.898L356.817 327.565L360.174 329.303L360.293 334.715L356.777 332.898Z" fill="#0E7FD5"/>
            <path d="M356.894 327.012L355.748 328.71L359.422 330.764L360.528 328.908L356.894 327.012Z" fill="#274B66"/>
            <path d="M360.528 329.896V328.908L359.383 330.804L360.528 329.896Z" fill="#0F2535"/>
            <path d="M355.946 329.026C356.055 329.026 356.143 328.938 356.143 328.829C356.143 328.72 356.055 328.631 355.946 328.631C355.836 328.631 355.748 328.72 355.748 328.829C355.748 328.938 355.836 329.026 355.946 329.026Z" fill="#274B66"/>
            <path d="M356.262 329.184C356.371 329.184 356.459 329.096 356.459 328.987C356.459 328.878 356.371 328.789 356.262 328.789C356.153 328.789 356.064 328.878 356.064 328.987C356.064 329.096 356.153 329.184 356.262 329.184Z" fill="#274B66"/>
            <path d="M356.537 329.343C356.646 329.343 356.735 329.254 356.735 329.145C356.735 329.036 356.646 328.948 356.537 328.948C356.428 328.948 356.34 329.036 356.34 329.145C356.34 329.254 356.428 329.343 356.537 329.343Z" fill="#274B66"/>
            <path d="M356.856 329.54C356.965 329.54 357.053 329.452 357.053 329.343C357.053 329.233 356.965 329.145 356.856 329.145C356.747 329.145 356.658 329.233 356.658 329.343C356.658 329.452 356.747 329.54 356.856 329.54Z" fill="#274B66"/>
            <path d="M357.172 329.698C357.281 329.698 357.37 329.61 357.37 329.501C357.37 329.392 357.281 329.303 357.172 329.303C357.063 329.303 356.975 329.392 356.975 329.501C356.975 329.61 357.063 329.698 357.172 329.698Z" fill="#274B66"/>
            <path d="M357.448 329.856C357.557 329.856 357.645 329.768 357.645 329.658C357.645 329.549 357.557 329.461 357.448 329.461C357.338 329.461 357.25 329.549 357.25 329.658C357.25 329.768 357.338 329.856 357.448 329.856Z" fill="#274B66"/>
            <path d="M357.725 330.014C357.834 330.014 357.922 329.925 357.922 329.816C357.922 329.707 357.834 329.619 357.725 329.619C357.616 329.619 357.527 329.707 357.527 329.816C357.527 329.925 357.616 330.014 357.725 330.014Z" fill="#274B66"/>
            <path d="M358.08 330.211C358.189 330.211 358.278 330.123 358.278 330.014C358.278 329.905 358.189 329.816 358.08 329.816C357.971 329.816 357.883 329.905 357.883 330.014C357.883 330.123 357.971 330.211 358.08 330.211Z" fill="#274B66"/>
            <path d="M358.358 330.369C358.467 330.369 358.555 330.281 358.555 330.172C358.555 330.063 358.467 329.974 358.358 329.974C358.249 329.974 358.16 330.063 358.16 330.172C358.16 330.281 358.249 330.369 358.358 330.369Z" fill="#274B66"/>
            <path d="M358.631 330.527C358.74 330.527 358.829 330.439 358.829 330.33C358.829 330.221 358.74 330.132 358.631 330.132C358.522 330.132 358.434 330.221 358.434 330.33C358.434 330.439 358.522 330.527 358.631 330.527Z" fill="#274B66"/>
            <path d="M358.989 330.686C359.098 330.686 359.186 330.597 359.186 330.488C359.186 330.379 359.098 330.291 358.989 330.291C358.879 330.291 358.791 330.379 358.791 330.488C358.791 330.597 358.879 330.686 358.989 330.686Z" fill="#274B66"/>
            <path d="M359.266 330.883C359.375 330.883 359.463 330.795 359.463 330.686C359.463 330.577 359.375 330.488 359.266 330.488C359.157 330.488 359.068 330.577 359.068 330.686C359.068 330.795 359.157 330.883 359.266 330.883Z" fill="#274B66"/>
            <path d="M356.896 326.38L360.333 328.197L360.372 324.523L356.936 322.706L356.896 326.38Z" fill="white"/>
            <path d="M363.568 335.228L365.741 336.492V336.255L363.805 335.109L363.568 335.228Z" fill="#C5D1E8"/>
            <path d="M363.805 335.11L363.766 326.459L363.568 326.341V335.228L363.805 335.11Z" fill="#F4F4FC"/>
            <path d="M360.605 333.49L362.778 334.714V334.477L360.803 333.332L360.605 333.49Z" fill="#C5D1E8"/>
            <path d="M360.805 333.332V324.682L360.568 324.563L360.608 333.49L360.805 333.332Z" fill="#F4F4FC"/>
            <path d="M354.127 329.935L356.299 331.199V330.962L354.364 329.816L354.127 329.935Z" fill="#C5D1E8"/>
            <path d="M354.364 329.817L354.324 321.363L354.127 321.245V329.935L354.364 329.817Z" fill="#F4F4FC"/>
            <path d="M351.445 328.434L353.618 329.659V329.422L351.643 328.316L351.445 328.434Z" fill="#C5D1E8"/>
            <path d="M351.639 328.315V319.823L351.402 319.704L351.442 328.434L351.639 328.315Z" fill="#F4F4FC"/>
            <path d="M377.473 332.147L375.537 333.293V324.089L377.473 322.983V326.222V332.147Z" fill="#0E7FD5"/>
            <path d="M374.394 333.767L372.459 334.873V325.669L374.394 324.563V327.802V333.767Z" fill="#0E7FD5"/>
            <path d="M371.391 335.425L369.455 336.571V327.367L371.391 326.261V329.5V335.425Z" fill="#0E7FD5"/>
            <path d="M353.932 311.37L368.587 319.428L368.626 313.819L353.893 305.366L353.932 311.37Z" fill="#D5DCE1"/>
            <path d="M354.444 310.975L368.071 318.559V314.135L354.404 306.274L354.444 310.975Z" fill="white"/>
            <path d="M369.021 313.622L354.367 305.208L353.893 305.366L368.626 313.819L369.021 313.622Z" fill="#F9FAFA"/>
            <path d="M368.586 319.427L368.625 313.818L369.02 313.621L368.981 318.953L368.586 319.427Z" fill="#AAB3BA"/>
            <path d="M358.19 311.564C358.801 311.106 358.766 310.027 358.112 309.154C357.458 308.281 356.432 307.945 355.821 308.403C355.21 308.86 355.245 309.939 355.899 310.812C356.553 311.685 357.579 312.022 358.19 311.564Z" fill="#D5DCE1"/>
            <path d="M344.497 355.63L326.762 346.426V328.691L344.497 337.894V355.63Z" fill="#DBE2F3"/>
            <path d="M354.966 349.587L344.498 355.63V337.894L354.966 331.851V349.587Z" fill="#BACAE3"/>
            <path d="M353.821 338.131L351.648 339.395V339.158L353.584 338.013L353.821 338.131Z" fill="#DBE2F3"/>
            <path d="M353.582 338.013V334.774L353.819 334.655V338.131L353.582 338.013Z" fill="#F4F4FC"/>
            <path d="M353.584 338.013L351.648 339.158V335.88L353.584 334.774V338.013Z" fill="#0E7FD5"/>
            <path d="M352.635 335.327V338.566" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.584 336.393L351.648 337.499" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M350.817 339.869L348.684 341.133V340.896L350.619 339.751L350.817 339.869Z" fill="#DBE2F3"/>
            <path d="M350.619 339.751V336.512L350.817 336.393V339.869L350.619 339.751Z" fill="#F4F4FC"/>
            <path d="M350.619 339.751L348.684 340.896V337.657L350.619 336.512V339.751Z" fill="#0E7FD5"/>
            <path d="M349.631 337.065V340.343" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M350.619 338.131L348.684 339.277" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.854 341.568L345.682 342.832V342.595L347.617 341.449L347.854 341.568Z" fill="#DBE2F3"/>
            <path d="M347.615 341.449V338.21L347.852 338.092V341.568L347.615 341.449Z" fill="#F4F4FC"/>
            <path d="M347.617 341.449L345.682 342.595V339.316L347.617 338.21V341.449Z" fill="#0E7FD5"/>
            <path d="M346.67 338.763V342.002" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.617 339.83L345.682 340.936" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.066 334.893L330.239 336.157V335.92L328.264 334.774L328.066 334.893Z" fill="#C5D1E8"/>
            <path d="M328.264 334.774V331.535L328.066 331.417V334.893L328.264 334.774Z" fill="#F4F4FC"/>
            <path d="M328.264 334.774L330.239 335.919V332.641L328.264 331.535V334.774Z" fill="#0E7FD5"/>
            <path d="M329.25 332.088V335.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.264 333.155L330.239 334.3" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M331.029 336.67L333.202 337.895V337.658L331.266 336.552L331.029 336.67Z" fill="#C5D1E8"/>
            <path d="M331.266 336.552V333.273L331.029 333.155V336.67L331.266 336.552Z" fill="#F4F4FC"/>
            <path d="M331.264 336.552L333.199 337.658V334.419L331.264 333.273V336.552Z" fill="#0E7FD5"/>
            <path d="M332.215 333.826V337.104" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M331.264 334.893L333.199 336.038" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M334.033 338.329L336.206 339.593V339.356L334.231 338.211L334.033 338.329Z" fill="#C5D1E8"/>
            <path d="M334.231 338.211V334.972L334.033 334.853V338.329L334.231 338.211Z" fill="#F4F4FC"/>
            <path d="M334.227 338.211L336.202 339.356V336.078L334.227 334.972V338.211Z" fill="#0E7FD5"/>
            <path d="M335.217 335.525V338.764" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M334.227 336.591L336.202 337.736" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M328.066 345.321L330.002 346.466V337.934L328.066 336.789V340.067V345.321Z" fill="#0E7FD5"/>
            <path d="M330.752 346.822L332.727 347.928V339.396L330.752 338.29V341.529V346.822Z" fill="#0E7FD5"/>
            <path d="M337.23 350.456L339.165 351.601L339.047 342.674L337.111 341.568V344.807L337.23 350.456Z" fill="#0E7FD5"/>
            <path d="M340.194 352.233L342.169 353.379L342.051 344.452L340.115 343.346V346.585L340.194 352.233Z" fill="#0E7FD5"/>
            <path d="M355.676 332.127L344.458 338.605L326.09 328.493V327.624L355.676 331.258V332.127Z" fill="#B6BAD5"/>
            <path d="M355.676 331.258L344.458 337.736L326.09 327.624L337.11 321.383L355.676 331.258Z" fill="#F4F4FC"/>
            <path d="M354.176 331.298L344.498 336.907L327.592 327.624L336.914 322.173L354.176 331.298Z" fill="#DBE2F3"/>
            <path d="M336.913 323.042L353.425 331.693L354.175 331.258L336.913 322.173L327.473 327.624L328.223 328.019L336.913 323.042Z" fill="#B6BAD5"/>
            <path d="M337.072 339.909L339.245 341.173V340.896L337.309 339.79L337.072 339.909Z" fill="#C5D1E8"/>
            <path d="M337.309 339.79V336.551L337.072 336.433V339.909L337.309 339.79Z" fill="#F4F4FC"/>
            <path d="M337.311 339.79L339.246 340.896V337.657L337.311 336.551V339.79Z" fill="#0E7FD5"/>
            <path d="M338.256 337.104V340.343" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M337.311 338.171L339.246 339.277" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M340.076 341.647L342.209 342.911V342.674L340.274 341.528L340.076 341.647Z" fill="#C5D1E8"/>
            <path d="M340.274 341.528V338.289L340.076 338.171V341.647L340.274 341.528Z" fill="#F4F4FC"/>
            <path d="M340.27 341.528L342.205 342.674V339.395L340.27 338.289V341.528Z" fill="#0E7FD5"/>
            <path d="M341.26 338.842V342.081" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M340.27 339.909L342.205 341.015" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M333.201 349.824L333.241 344.491L336.598 346.229L336.717 351.641L333.201 349.824Z" fill="#0E7FD5"/>
            <path d="M333.317 343.938L332.172 345.636L335.845 347.69L336.951 345.834L333.317 343.938Z" fill="#274B66"/>
            <path d="M336.952 346.822V345.834L335.807 347.73L336.952 346.822Z" fill="#0F2535"/>
            <path d="M332.369 345.952C332.478 345.952 332.567 345.864 332.567 345.755C332.567 345.646 332.478 345.557 332.369 345.557C332.26 345.557 332.172 345.646 332.172 345.755C332.172 345.864 332.26 345.952 332.369 345.952Z" fill="#274B66"/>
            <path d="M332.686 346.11C332.795 346.11 332.883 346.022 332.883 345.913C332.883 345.804 332.795 345.715 332.686 345.715C332.577 345.715 332.488 345.804 332.488 345.913C332.488 346.022 332.577 346.11 332.686 346.11Z" fill="#274B66"/>
            <path d="M332.961 346.269C333.07 346.269 333.159 346.18 333.159 346.071C333.159 345.962 333.07 345.874 332.961 345.874C332.852 345.874 332.764 345.962 332.764 346.071C332.764 346.18 332.852 346.269 332.961 346.269Z" fill="#274B66"/>
            <path d="M333.28 346.466C333.389 346.466 333.477 346.378 333.477 346.269C333.477 346.159 333.389 346.071 333.28 346.071C333.17 346.071 333.082 346.159 333.082 346.269C333.082 346.378 333.17 346.466 333.28 346.466Z" fill="#274B66"/>
            <path d="M333.596 346.624C333.705 346.624 333.793 346.536 333.793 346.427C333.793 346.318 333.705 346.229 333.596 346.229C333.487 346.229 333.398 346.318 333.398 346.427C333.398 346.536 333.487 346.624 333.596 346.624Z" fill="#274B66"/>
            <path d="M333.871 346.782C333.98 346.782 334.069 346.694 334.069 346.584C334.069 346.475 333.98 346.387 333.871 346.387C333.762 346.387 333.674 346.475 333.674 346.584C333.674 346.694 333.762 346.782 333.871 346.782Z" fill="#274B66"/>
            <path d="M334.149 346.94C334.258 346.94 334.346 346.851 334.346 346.742C334.346 346.633 334.258 346.545 334.149 346.545C334.04 346.545 333.951 346.633 333.951 346.742C333.951 346.851 334.04 346.94 334.149 346.94Z" fill="#274B66"/>
            <path d="M334.504 347.137C334.613 347.137 334.702 347.049 334.702 346.94C334.702 346.831 334.613 346.742 334.504 346.742C334.395 346.742 334.307 346.831 334.307 346.94C334.307 347.049 334.395 347.137 334.504 347.137Z" fill="#274B66"/>
            <path d="M334.781 347.296C334.891 347.296 334.979 347.207 334.979 347.098C334.979 346.989 334.891 346.901 334.781 346.901C334.672 346.901 334.584 346.989 334.584 347.098C334.584 347.207 334.672 347.296 334.781 347.296Z" fill="#274B66"/>
            <path d="M335.055 347.453C335.164 347.453 335.252 347.365 335.252 347.256C335.252 347.147 335.164 347.058 335.055 347.058C334.946 347.058 334.857 347.147 334.857 347.256C334.857 347.365 334.946 347.453 335.055 347.453Z" fill="#274B66"/>
            <path d="M335.412 347.612C335.521 347.612 335.61 347.523 335.61 347.414C335.61 347.305 335.521 347.217 335.412 347.217C335.303 347.217 335.215 347.305 335.215 347.414C335.215 347.523 335.303 347.612 335.412 347.612Z" fill="#274B66"/>
            <path d="M335.69 347.809C335.799 347.809 335.887 347.721 335.887 347.612C335.887 347.503 335.799 347.414 335.69 347.414C335.581 347.414 335.492 347.503 335.492 347.612C335.492 347.721 335.581 347.809 335.69 347.809Z" fill="#274B66"/>
            <path d="M333.32 343.306L336.757 345.123L336.796 341.449L333.36 339.632L333.32 343.306Z" fill="white"/>
            <path d="M339.992 352.154L342.165 353.418V353.181L340.229 352.036L339.992 352.154Z" fill="#C5D1E8"/>
            <path d="M340.229 352.036L340.19 343.385L339.992 343.267V352.154L340.229 352.036Z" fill="#F4F4FC"/>
            <path d="M337.029 350.416L339.202 351.64V351.403L337.227 350.258L337.029 350.416Z" fill="#C5D1E8"/>
            <path d="M337.229 350.258V341.608L336.992 341.489L337.032 350.416L337.229 350.258Z" fill="#F4F4FC"/>
            <path d="M330.551 346.861L332.723 348.125V347.888L330.788 346.742L330.551 346.861Z" fill="#C5D1E8"/>
            <path d="M330.788 346.743L330.748 338.289L330.551 338.171V346.861L330.788 346.743Z" fill="#F4F4FC"/>
            <path d="M327.869 345.36L330.042 346.585V346.348L328.067 345.242L327.869 345.36Z" fill="#C5D1E8"/>
            <path d="M328.063 345.241V336.749L327.826 336.63L327.866 345.36L328.063 345.241Z" fill="#F4F4FC"/>
            <path d="M353.896 349.073L351.961 350.219V341.015L353.896 339.909V343.148V349.073Z" fill="#0E7FD5"/>
            <path d="M350.818 350.693L348.883 351.799V342.595L350.818 341.489V344.728V350.693Z" fill="#0E7FD5"/>
            <path d="M347.814 352.351L345.879 353.497V344.293L347.814 343.187V346.426V352.351Z" fill="#0E7FD5"/>
            <path d="M330.356 328.296L345.011 336.354L345.05 330.745L330.316 322.292L330.356 328.296Z" fill="#D5DCE1"/>
            <path d="M330.868 327.901L344.495 335.485V331.061L330.828 323.2L330.868 327.901Z" fill="white"/>
            <path d="M345.445 330.548L330.79 322.134L330.316 322.292L345.05 330.745L345.445 330.548Z" fill="#F9FAFA"/>
            <path d="M345.01 336.353L345.049 330.744L345.444 330.547L345.405 335.879L345.01 336.353Z" fill="#AAB3BA"/>
            <path d="M334.614 328.49C335.225 328.032 335.19 326.953 334.536 326.08C333.882 325.207 332.856 324.871 332.245 325.329C331.634 325.786 331.669 326.865 332.323 327.738C332.977 328.611 334.002 328.948 334.614 328.49Z" fill="#D5DCE1"/>
            <path d="M383.521 362.483L365.785 353.28V335.544L383.521 344.748V362.483Z" fill="#DBE2F3"/>
            <path d="M393.989 356.44L383.521 362.483V344.748L393.989 338.704V356.44Z" fill="#BACAE3"/>
            <path d="M392.844 344.985L390.672 346.249V346.012L392.607 344.866L392.844 344.985Z" fill="#DBE2F3"/>
            <path d="M392.605 344.866V341.627L392.843 341.508V344.984L392.605 344.866Z" fill="#F4F4FC"/>
            <path d="M392.607 344.866L390.672 346.012V342.733L392.607 341.627V344.866Z" fill="#0E7FD5"/>
            <path d="M391.658 342.18V345.419" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M392.607 343.247L390.672 344.353" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.84 346.723L387.707 347.987V347.75L389.643 346.604L389.84 346.723Z" fill="#DBE2F3"/>
            <path d="M389.643 346.604V343.365L389.84 343.247V346.723L389.643 346.604Z" fill="#F4F4FC"/>
            <path d="M389.643 346.604L387.707 347.75V344.511L389.643 343.365V346.604Z" fill="#0E7FD5"/>
            <path d="M388.654 343.918V347.196" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.643 344.984L387.707 346.13" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M386.878 348.421L384.705 349.685V349.448L386.641 348.303L386.878 348.421Z" fill="#DBE2F3"/>
            <path d="M386.639 348.303V345.064L386.876 344.945V348.421L386.639 348.303Z" fill="#F4F4FC"/>
            <path d="M386.641 348.303L384.705 349.448V346.17L386.641 345.064V348.303Z" fill="#0E7FD5"/>
            <path d="M385.693 345.617V348.856" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M386.641 346.683L384.705 347.789" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.09 341.746L369.262 343.01V342.773L367.287 341.627L367.09 341.746Z" fill="#C5D1E8"/>
            <path d="M367.287 341.627V338.388L367.09 338.27V341.746L367.287 341.627Z" fill="#F4F4FC"/>
            <path d="M367.287 341.627L369.262 342.773V339.494L367.287 338.388V341.627Z" fill="#0E7FD5"/>
            <path d="M368.273 338.941V342.18" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.287 340.008L369.262 341.153" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M370.053 343.523L372.225 344.748V344.511L370.29 343.405L370.053 343.523Z" fill="#C5D1E8"/>
            <path d="M370.29 343.405V340.126L370.053 340.008V343.523L370.29 343.405Z" fill="#F4F4FC"/>
            <path d="M370.287 343.405L372.223 344.511V341.272L370.287 340.126V343.405Z" fill="#0E7FD5"/>
            <path d="M371.238 340.679V343.958" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M370.287 341.746L372.223 342.891" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.057 345.182L375.229 346.446V346.209L373.254 345.064L373.057 345.182Z" fill="#C5D1E8"/>
            <path d="M373.254 345.064V341.825L373.057 341.706V345.182L373.254 345.064Z" fill="#F4F4FC"/>
            <path d="M373.25 345.064L375.225 346.209V342.931L373.25 341.825V345.064Z" fill="#0E7FD5"/>
            <path d="M374.24 342.378V345.617" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.25 343.444L375.225 344.59" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M367.09 352.174L369.025 353.32V344.787L367.09 343.642V346.92V352.174Z" fill="#0E7FD5"/>
            <path d="M369.775 353.675L371.75 354.781V346.249L369.775 345.143V348.382V353.675Z" fill="#0E7FD5"/>
            <path d="M376.253 357.309L378.189 358.455L378.07 349.528L376.135 348.422V351.661L376.253 357.309Z" fill="#0E7FD5"/>
            <path d="M379.218 359.087L381.193 360.232L381.074 351.305L379.139 350.199V353.438L379.218 359.087Z" fill="#0E7FD5"/>
            <path d="M394.699 338.98L383.481 345.459L365.113 335.346V334.477L394.699 338.111V338.98Z" fill="#B6BAD5"/>
            <path d="M394.699 338.112L383.481 344.59L365.113 334.478L376.134 328.236L394.699 338.112Z" fill="#F4F4FC"/>
            <path d="M393.199 338.151L383.521 343.76L366.615 334.478L375.937 329.026L393.199 338.151Z" fill="#DBE2F3"/>
            <path d="M375.937 329.895L392.448 338.546L393.198 338.112L375.937 329.026L366.496 334.478L367.247 334.873L375.937 329.895Z" fill="#B6BAD5"/>
            <path d="M376.096 346.762L378.268 348.026V347.749L376.333 346.643L376.096 346.762Z" fill="#C5D1E8"/>
            <path d="M376.333 346.643V343.404L376.096 343.286V346.762L376.333 346.643Z" fill="#F4F4FC"/>
            <path d="M376.334 346.643L378.27 347.749V344.51L376.334 343.404V346.643Z" fill="#0E7FD5"/>
            <path d="M377.279 343.958V347.197" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M376.334 345.024L378.27 346.13" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.1 348.5L381.233 349.764V349.527L379.297 348.382L379.1 348.5Z" fill="#C5D1E8"/>
            <path d="M379.297 348.382V345.143L379.1 345.024V348.5L379.297 348.382Z" fill="#F4F4FC"/>
            <path d="M379.293 348.382L381.229 349.527V346.249L379.293 345.143V348.382Z" fill="#0E7FD5"/>
            <path d="M380.283 345.695V348.934" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.293 346.762L381.229 347.868" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M372.225 356.677L372.264 351.344L375.622 353.082L375.74 358.494L372.225 356.677Z" fill="#0E7FD5"/>
            <path d="M372.341 350.791L371.195 352.49L374.869 354.544L375.975 352.687L372.341 350.791Z" fill="#274B66"/>
            <path d="M375.976 353.675V352.687L374.83 354.583L375.976 353.675Z" fill="#0F2535"/>
            <path d="M371.393 352.805C371.502 352.805 371.59 352.717 371.59 352.608C371.59 352.499 371.502 352.41 371.393 352.41C371.284 352.41 371.195 352.499 371.195 352.608C371.195 352.717 371.284 352.805 371.393 352.805Z" fill="#274B66"/>
            <path d="M371.709 352.964C371.818 352.964 371.907 352.875 371.907 352.766C371.907 352.657 371.818 352.569 371.709 352.569C371.6 352.569 371.512 352.657 371.512 352.766C371.512 352.875 371.6 352.964 371.709 352.964Z" fill="#274B66"/>
            <path d="M371.985 353.122C372.094 353.122 372.182 353.033 372.182 352.924C372.182 352.815 372.094 352.727 371.985 352.727C371.876 352.727 371.787 352.815 371.787 352.924C371.787 353.033 371.876 353.122 371.985 353.122Z" fill="#274B66"/>
            <path d="M372.303 353.319C372.412 353.319 372.5 353.231 372.5 353.122C372.5 353.013 372.412 352.924 372.303 352.924C372.194 352.924 372.105 353.013 372.105 353.122C372.105 353.231 372.194 353.319 372.303 353.319Z" fill="#274B66"/>
            <path d="M372.619 353.477C372.728 353.477 372.817 353.389 372.817 353.28C372.817 353.171 372.728 353.082 372.619 353.082C372.51 353.082 372.422 353.171 372.422 353.28C372.422 353.389 372.51 353.477 372.619 353.477Z" fill="#274B66"/>
            <path d="M372.895 353.635C373.004 353.635 373.092 353.547 373.092 353.438C373.092 353.329 373.004 353.24 372.895 353.24C372.786 353.24 372.697 353.329 372.697 353.438C372.697 353.547 372.786 353.635 372.895 353.635Z" fill="#274B66"/>
            <path d="M373.172 353.793C373.281 353.793 373.37 353.705 373.37 353.595C373.37 353.486 373.281 353.398 373.172 353.398C373.063 353.398 372.975 353.486 372.975 353.595C372.975 353.705 373.063 353.793 373.172 353.793Z" fill="#274B66"/>
            <path d="M373.528 353.99C373.637 353.99 373.725 353.902 373.725 353.793C373.725 353.684 373.637 353.595 373.528 353.595C373.419 353.595 373.33 353.684 373.33 353.793C373.33 353.902 373.419 353.99 373.528 353.99Z" fill="#274B66"/>
            <path d="M373.805 354.149C373.914 354.149 374.002 354.06 374.002 353.951C374.002 353.842 373.914 353.754 373.805 353.754C373.696 353.754 373.607 353.842 373.607 353.951C373.607 354.06 373.696 354.149 373.805 354.149Z" fill="#274B66"/>
            <path d="M374.078 354.307C374.187 354.307 374.276 354.218 374.276 354.109C374.276 354 374.187 353.912 374.078 353.912C373.969 353.912 373.881 354 373.881 354.109C373.881 354.218 373.969 354.307 374.078 354.307Z" fill="#274B66"/>
            <path d="M374.436 354.465C374.545 354.465 374.633 354.377 374.633 354.268C374.633 354.158 374.545 354.07 374.436 354.07C374.327 354.07 374.238 354.158 374.238 354.268C374.238 354.377 374.327 354.465 374.436 354.465Z" fill="#274B66"/>
            <path d="M374.713 354.662C374.822 354.662 374.911 354.574 374.911 354.465C374.911 354.356 374.822 354.267 374.713 354.267C374.604 354.267 374.516 354.356 374.516 354.465C374.516 354.574 374.604 354.662 374.713 354.662Z" fill="#274B66"/>
            <path d="M372.344 350.159L375.78 351.976L375.82 348.302L372.383 346.485L372.344 350.159Z" fill="white"/>
            <path d="M379.016 359.007L381.188 360.271V360.034L379.253 358.889L379.016 359.007Z" fill="#C5D1E8"/>
            <path d="M379.253 358.889L379.213 350.238L379.016 350.12V359.008L379.253 358.889Z" fill="#F4F4FC"/>
            <path d="M376.053 357.269L378.225 358.494V358.257L376.25 357.111L376.053 357.269Z" fill="#C5D1E8"/>
            <path d="M376.253 357.112V348.461L376.016 348.342L376.055 357.27L376.253 357.112Z" fill="#F4F4FC"/>
            <path d="M369.574 353.714L371.747 354.978V354.741L369.811 353.595L369.574 353.714Z" fill="#C5D1E8"/>
            <path d="M369.811 353.596L369.772 345.143L369.574 345.024V353.714L369.811 353.596Z" fill="#F4F4FC"/>
            <path d="M366.893 352.213L369.065 353.438V353.201L367.09 352.095L366.893 352.213Z" fill="#C5D1E8"/>
            <path d="M367.087 352.095V343.602L366.85 343.484L366.889 352.213L367.087 352.095Z" fill="#F4F4FC"/>
            <path d="M392.92 355.926L390.984 357.072V347.868L392.92 346.762V350.001V355.926Z" fill="#0E7FD5"/>
            <path d="M389.842 357.546L387.906 358.652V349.448L389.842 348.342V351.581V357.546Z" fill="#0E7FD5"/>
            <path d="M386.838 359.205L384.902 360.35V351.147L386.838 350.041V353.28V359.205Z" fill="#0E7FD5"/>
            <path d="M369.379 335.149L384.034 343.207L384.074 337.598L369.34 329.145L369.379 335.149Z" fill="#D5DCE1"/>
            <path d="M369.891 334.754L383.519 342.338V337.914L369.852 330.053L369.891 334.754Z" fill="white"/>
            <path d="M384.469 337.401L369.814 328.987L369.34 329.145L384.074 337.598L384.469 337.401Z" fill="#F9FAFA"/>
            <path d="M384.033 343.207L384.073 337.598L384.468 337.4L384.428 342.733L384.033 343.207Z" fill="#AAB3BA"/>
            <path d="M373.637 335.343C374.248 334.885 374.213 333.806 373.559 332.934C372.905 332.061 371.88 331.724 371.269 332.182C370.657 332.64 370.692 333.718 371.346 334.591C372 335.464 373.026 335.801 373.637 335.343Z" fill="#D5DCE1"/>
            <path opacity="0.5" d="M406.072 187.911C424.715 198.653 424.642 216.029 405.854 226.844C387.066 237.658 356.671 237.73 337.955 226.988C319.24 216.245 319.385 198.87 338.173 188.055C357.034 177.24 387.429 177.168 406.072 187.911Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M390.113 196.563C400.124 202.259 400.051 211.631 389.968 217.399C379.885 223.167 363.635 223.239 353.697 217.471C343.687 211.775 343.759 202.403 353.842 196.635C363.853 190.867 380.102 190.795 390.113 196.563Z" stroke="#1A77CA" strokeMiterlimit="10"/>
            <path d="M353.767 208.818L332.367 196.85V175.149L353.767 187.117V208.818Z" fill="#DBE2F3"/>
            <path d="M373.934 196.418L353.768 207.953V187.117L373.934 175.581V196.418Z" fill="#BACAE3"/>
            <path d="M371.979 185.604L367.844 187.984V187.551L371.616 185.388L371.979 185.604Z" fill="#DBE2F3"/>
            <path d="M371.615 185.388V179.187L372.051 178.971V185.604H371.978L371.615 185.388Z" fill="#F4F4FC"/>
            <path d="M371.616 185.388L367.844 187.55V181.35L371.616 179.187V185.388Z" fill="#0E7FD5"/>
            <path d="M369.73 180.269V186.469" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.616 182.288L367.844 184.451" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M366.319 188.992L362.111 191.372V190.867L365.883 188.776L366.319 188.992Z" fill="#DBE2F3"/>
            <path d="M365.883 188.777V182.505L366.318 182.288V188.993L365.883 188.777Z" fill="#F4F4FC"/>
            <path d="M365.883 188.777L362.111 190.868V184.667L365.883 182.504V188.777Z" fill="#0E7FD5"/>
            <path d="M363.998 183.586V189.786" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M365.883 185.604L362.111 187.767" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.516 192.237L356.381 194.616V194.111L360.153 191.948L360.516 192.237Z" fill="#DBE2F3"/>
            <path d="M360.152 191.949V185.749L360.515 185.532V192.237L360.152 191.949Z" fill="#F4F4FC"/>
            <path d="M360.153 191.949L356.381 194.112V187.911L360.153 185.748V191.949Z" fill="#0E7FD5"/>
            <path d="M358.268 186.83V193.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.153 188.848L356.381 191.011" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.979 194.761L367.844 197.14V196.707L371.616 194.544L371.979 194.761Z" fill="#DBE2F3"/>
            <path d="M371.615 194.544V188.272L372.051 188.056V194.761H371.978L371.615 194.544Z" fill="#F4F4FC"/>
            <path d="M371.616 194.544L367.844 196.707V190.435L371.616 188.272V194.544Z" fill="#0E7FD5"/>
            <path d="M369.73 189.353V195.626" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M371.616 191.372L367.844 193.535" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M366.319 198.077L362.111 200.456V200.024L365.883 197.861L366.319 198.077Z" fill="#DBE2F3"/>
            <path d="M365.883 197.861V191.66L366.318 191.372V198.077L365.883 197.861Z" fill="#F4F4FC"/>
            <path d="M365.883 197.861L362.111 200.024V193.824L365.883 191.661V197.861Z" fill="#0E7FD5"/>
            <path d="M363.998 192.742V198.943" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M365.883 194.76L362.111 196.923" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.516 201.322L356.381 203.701V203.268L360.153 201.105L360.516 201.322Z" fill="#DBE2F3"/>
            <path d="M360.152 201.105V194.833L360.515 194.617V201.322L360.152 201.105Z" fill="#F4F4FC"/>
            <path d="M360.153 201.105L356.381 203.268V196.996L360.153 194.833V201.105Z" fill="#0E7FD5"/>
            <path d="M358.268 195.914V202.187" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M360.153 198.004L356.381 200.167" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.342 185.604L339.549 187.984V187.551L335.777 185.388L335.342 185.604Z" fill="#C5D1E8"/>
            <path d="M335.777 185.388V179.187L335.342 178.971V185.604L335.777 185.388Z" fill="#F4F4FC"/>
            <path d="M335.775 185.388L339.548 187.55V181.35L335.775 179.187V185.388Z" fill="#0E7FD5"/>
            <path d="M337.662 180.269V186.469" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.775 182.288L339.548 184.451" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.072 188.992L345.207 191.372V190.867L341.507 188.776L341.072 188.992Z" fill="#C5D1E8"/>
            <path d="M341.507 188.777V182.505L341.072 182.288V188.993L341.507 188.777Z" fill="#F4F4FC"/>
            <path d="M341.508 188.777L345.207 190.868V184.667L341.508 182.504V188.777Z" fill="#0E7FD5"/>
            <path d="M343.322 183.586V189.786" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.508 185.604L345.207 187.767" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M346.805 192.237L351.012 194.616V194.111L347.24 191.948L346.805 192.237Z" fill="#C5D1E8"/>
            <path d="M347.24 191.949V185.749L346.805 185.532V192.237L347.24 191.949Z" fill="#F4F4FC"/>
            <path d="M347.238 191.949L351.01 194.112V187.911L347.238 185.748V191.949Z" fill="#0E7FD5"/>
            <path d="M349.125 186.83V193.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.238 188.849L351.01 191.012" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.342 194.039L339.549 196.418V195.913L335.777 193.75L335.342 194.039Z" fill="#C5D1E8"/>
            <path d="M335.777 193.751V187.551L335.342 187.334V194.039L335.777 193.751Z" fill="#F4F4FC"/>
            <path d="M335.775 193.751L339.548 195.914V189.713L335.775 187.55V193.751Z" fill="#0E7FD5"/>
            <path d="M337.662 188.632V194.832" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M335.775 190.651L339.548 192.814" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M346.805 200.6L351.012 202.98V202.475L347.24 200.384L346.805 200.6Z" fill="#C5D1E8"/>
            <path d="M347.24 200.312V194.112L346.805 193.895V200.601L347.24 200.384V200.312Z" fill="#F4F4FC"/>
            <path d="M347.238 200.384L351.01 202.475V196.274L347.238 194.112V200.312V200.384Z" fill="#0E7FD5"/>
            <path d="M349.125 195.193V201.393" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M347.238 197.212L351.01 199.375" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.145 197.14L345.279 199.519V199.015L341.58 196.852L341.145 197.14Z" fill="#C5D1E8"/>
            <path d="M341.58 196.851V190.651L341.145 190.435V197.14L341.58 196.851Z" fill="#F4F4FC"/>
            <path d="M341.58 196.851L345.28 199.014V192.814L341.58 190.651V196.851Z" fill="#0E7FD5"/>
            <path d="M343.467 191.732V197.933" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.58 193.75L345.28 195.913" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M375.384 175.077L353.767 187.478L332.367 175.149V173.491L375.384 173.418V175.077Z" fill="#B6BAD5"/>
            <path d="M375.384 173.419L353.767 185.819L332.367 173.491L353.984 161.09L375.384 173.419Z" fill="#F4F4FC"/>
            <path d="M372.483 173.419L353.84 184.162L335.27 173.491L353.913 162.749L372.483 173.419Z" fill="#DBE2F3"/>
            <path d="M353.767 164.407L370.886 174.212L372.265 173.419L353.767 162.749L335.051 173.491L336.502 174.284L353.767 164.407Z" fill="#B6BAD5"/>
            <path d="M371.325 218.912L351.158 207.304V173.418L371.325 184.954V218.912Z" fill="#DBE2F3"/>
            <path d="M391.493 207.304L371.326 218.912V184.954L391.493 173.418V207.304Z" fill="#BACAE3"/>
            <path d="M389.459 185.532L385.324 187.911V187.406L389.024 185.315L389.459 185.532Z" fill="#DBE2F3"/>
            <path d="M389.023 185.315V179.042L389.459 178.826V185.531L389.023 185.315Z" fill="#F4F4FC"/>
            <path d="M389.024 185.315L385.324 187.406V181.205L389.024 179.042V185.315Z" fill="#0E7FD5"/>
            <path d="M387.211 180.124V186.324" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 182.143L385.324 184.306" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 188.848L379.592 191.227V190.794L383.364 188.631L383.727 188.848Z" fill="#DBE2F3"/>
            <path d="M383.363 188.632V182.432L383.726 182.143V188.848L383.363 188.632Z" fill="#F4F4FC"/>
            <path d="M383.364 188.632L379.592 190.794V184.522L383.364 182.431V188.632Z" fill="#0E7FD5"/>
            <path d="M381.479 183.441V189.713" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 185.532L379.592 187.694" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 192.092L373.789 194.471V193.967L377.561 191.876L377.997 192.092Z" fill="#DBE2F3"/>
            <path d="M377.561 191.876V185.604L377.996 185.387V192.092L377.561 191.876Z" fill="#F4F4FC"/>
            <path d="M377.561 191.876L373.789 193.967V187.766L377.561 185.603V191.876Z" fill="#0E7FD5"/>
            <path d="M375.676 186.685V192.957" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 188.703L373.789 190.866" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.459 195.553L385.324 197.932V197.427L389.024 195.337L389.459 195.553Z" fill="#DBE2F3"/>
            <path d="M389.023 195.337V189.064L389.459 188.848V195.553L389.023 195.337Z" fill="#F4F4FC"/>
            <path d="M389.024 195.337L385.324 197.427V191.227L389.024 189.064V195.337Z" fill="#0E7FD5"/>
            <path d="M387.211 190.146V196.418" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 192.164L385.324 194.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 198.869L379.592 201.249V200.816L383.364 198.653L383.727 198.869Z" fill="#DBE2F3"/>
            <path d="M383.363 198.653V192.453L383.726 192.164V198.87L383.363 198.653Z" fill="#F4F4FC"/>
            <path d="M383.364 198.653L379.592 200.816V194.616L383.364 192.453V198.653Z" fill="#0E7FD5"/>
            <path d="M381.479 193.534V199.734" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 195.553L379.592 197.716" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 202.113L373.789 204.493V204.06L377.561 201.897L377.997 202.113Z" fill="#DBE2F3"/>
            <path d="M377.561 201.897V195.625L377.996 195.408V202.113L377.561 201.897Z" fill="#F4F4FC"/>
            <path d="M377.561 201.898L373.789 204.061V197.788L377.561 195.625V201.898Z" fill="#0E7FD5"/>
            <path d="M375.676 196.707V202.979" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 198.797L373.789 200.888" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.459 204.853L385.324 207.233V206.8L389.024 204.637L389.459 204.853Z" fill="#DBE2F3"/>
            <path d="M389.023 204.637V198.365L389.459 198.148V204.854L389.023 204.637Z" fill="#F4F4FC"/>
            <path d="M389.024 204.637L385.324 206.8V200.527L389.024 198.365V204.637Z" fill="#0E7FD5"/>
            <path d="M387.211 199.446V205.719" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M389.024 201.464L385.324 203.627" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.727 208.17L379.592 210.549V210.116L383.364 207.954L383.727 208.17Z" fill="#DBE2F3"/>
            <path d="M383.363 207.953V201.753L383.726 201.464V208.169L383.363 207.953Z" fill="#F4F4FC"/>
            <path d="M383.364 207.954L379.592 210.117V203.916L383.364 201.753V207.954Z" fill="#0E7FD5"/>
            <path d="M381.479 202.835V209.035" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M383.364 204.853L379.592 207.016" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.997 211.415L373.789 213.794V213.361L377.561 211.198L377.997 211.415Z" fill="#DBE2F3"/>
            <path d="M377.561 211.198V204.926L377.996 204.71V211.415L377.561 211.198Z" fill="#F4F4FC"/>
            <path d="M377.561 211.198L373.789 213.361V207.089L377.561 204.926V211.198Z" fill="#0E7FD5"/>
            <path d="M375.676 206.007V212.28" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M377.561 208.099L373.789 210.261" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 185.532L357.25 187.911V187.406L353.478 185.315L353.115 185.532Z" fill="#C5D1E8"/>
            <path d="M353.478 185.315V179.042L353.115 178.826V185.531L353.478 185.315Z" fill="#F4F4FC"/>
            <path d="M353.477 185.315L357.249 187.406V181.205L353.477 179.042V185.315Z" fill="#0E7FD5"/>
            <path d="M355.363 180.124V186.324" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 182.143L357.249 184.306" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 188.848L362.981 191.227V190.794L359.209 188.631L358.773 188.848Z" fill="#C5D1E8"/>
            <path d="M359.209 188.632V182.432L358.773 182.143V188.848L359.209 188.632Z" fill="#F4F4FC"/>
            <path d="M359.209 188.632L362.981 190.794V184.522L359.209 182.431V188.632Z" fill="#0E7FD5"/>
            <path d="M361.096 183.441V189.713" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M359.209 185.531L362.981 187.694" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.578 192.092L368.713 194.471V193.967L364.941 191.876L364.578 192.092Z" fill="#C5D1E8"/>
            <path d="M364.941 191.876V185.604L364.578 185.387V192.092L364.941 191.876Z" fill="#F4F4FC"/>
            <path d="M364.939 191.876L368.712 193.967V187.766L364.939 185.603V191.876Z" fill="#0E7FD5"/>
            <path d="M366.826 186.685V192.957" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 188.704L368.712 190.867" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 195.553L357.25 197.932V197.427L353.478 195.337L353.115 195.553Z" fill="#C5D1E8"/>
            <path d="M353.478 195.337V189.064L353.115 188.848V195.553L353.478 195.337Z" fill="#F4F4FC"/>
            <path d="M353.477 195.337L357.249 197.427V191.227L353.477 189.064V195.337Z" fill="#0E7FD5"/>
            <path d="M355.363 190.146V196.418" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 192.164L357.249 194.327" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 198.869L362.981 201.249V200.816L359.209 198.653L358.773 198.869Z" fill="#C5D1E8"/>
            <path d="M359.209 198.653V192.453L358.773 192.164V198.87L359.209 198.653Z" fill="#F4F4FC"/>
            <path d="M359.209 198.653L362.981 200.816V194.616L359.209 192.453V198.653Z" fill="#0E7FD5"/>
            <path d="M361.096 193.534V199.734" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M359.209 195.553L362.981 197.716" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.578 202.113L368.713 204.493V204.06L364.941 201.897L364.578 202.113Z" fill="#C5D1E8"/>
            <path d="M364.941 201.897V195.625L364.578 195.408V202.113L364.941 201.897Z" fill="#F4F4FC"/>
            <path d="M364.939 201.898L368.712 204.061V197.788L364.939 195.625V201.898Z" fill="#0E7FD5"/>
            <path d="M366.826 196.707V202.979" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 198.797L368.712 200.888" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.115 204.853L357.25 207.233V206.8L353.478 204.637L353.115 204.853Z" fill="#C5D1E8"/>
            <path d="M353.478 204.637V198.365L353.115 198.148V204.854L353.478 204.637Z" fill="#F4F4FC"/>
            <path d="M353.477 204.637L357.249 206.8V200.527L353.477 198.365V204.637Z" fill="#0E7FD5"/>
            <path d="M355.363 199.446V205.719" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M353.477 201.464L357.249 203.627" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M358.773 211.631L362.981 214.01V213.577L359.209 211.414L358.773 211.631Z" fill="#DBE2F3"/>
            <path d="M359.209 207.953V201.753L358.773 201.464V208.169V211.63L359.209 211.414V207.953Z" fill="#F4F4FC"/>
            <path d="M359.209 211.415L362.981 213.577V203.916L359.209 201.753V207.954" fill="#A3B6D2"/>
            <path d="M364.578 211.415L368.713 213.794V213.361L364.941 211.198L364.578 211.415Z" fill="#C5D1E8"/>
            <path d="M364.941 211.198V204.926L364.578 204.71V211.415L364.941 211.198Z" fill="#F4F4FC"/>
            <path d="M364.939 211.198L368.712 213.361V207.089L364.939 204.926V211.198Z" fill="#0E7FD5"/>
            <path d="M366.826 206.007V212.28" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M364.939 208.099L368.712 210.261" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M392.943 172.915L371.326 185.316L349.926 172.987V171.329L392.943 171.257V172.915Z" fill="#B6BAD5"/>
            <path d="M392.943 171.257L371.326 183.657L349.926 171.329L371.471 159L392.943 171.257Z" fill="#F4F4FC"/>
            <path d="M390.042 171.257L371.326 181.999L352.828 171.329L371.471 160.659L390.042 171.257Z" fill="#DBE2F3"/>
            <path d="M371.252 162.245L388.372 172.122L389.823 171.257L371.252 160.587L352.609 171.329L354.06 172.195L371.252 162.245Z" fill="#B6BAD5"/>
            <path d="M391.273 230.305L370.744 218.769V198.798L391.273 210.334V230.305Z" fill="#DBE2F3"/>
            <path d="M410.644 219.202L391.275 230.377V210.334L410.644 199.231V219.202Z" fill="#BACAE3"/>
            <path d="M408.83 208.891L404.84 211.198V210.766L408.394 208.675L408.83 208.891Z" fill="#DBE2F3"/>
            <path d="M408.395 208.675V202.691L408.83 202.475V208.891L408.395 208.675Z" fill="#F4F4FC"/>
            <path d="M408.394 208.675L404.84 210.766V204.782L408.394 202.691V208.675Z" fill="#0E7FD5"/>
            <path d="M406.652 203.701V209.685" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.394 205.647L404.84 207.738" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M403.316 212.136L399.326 214.371V213.938L402.953 211.919L403.316 212.136Z" fill="#DBE2F3"/>
            <path d="M402.953 211.919V205.935L403.316 205.647V212.136L402.953 211.919Z" fill="#F4F4FC"/>
            <path d="M402.953 211.92L399.326 213.938V207.954L402.953 205.935V211.92Z" fill="#0E7FD5"/>
            <path d="M401.139 206.945V212.929" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M402.953 208.892L399.326 210.982" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.802 215.236L393.812 217.471V217.039L397.367 215.02L397.802 215.236Z" fill="#DBE2F3"/>
            <path d="M397.367 215.02V209.036L397.803 208.747V215.236L397.367 215.02Z" fill="#F4F4FC"/>
            <path d="M397.367 215.02L393.812 217.039V211.055L397.367 209.036V215.02Z" fill="#0E7FD5"/>
            <path d="M395.555 210.046V216.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.367 211.992L393.812 214.083" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.83 217.687L404.84 219.922V219.49L408.394 217.399L408.83 217.687Z" fill="#DBE2F3"/>
            <path d="M408.395 217.399V211.487L408.83 211.198V217.687L408.395 217.399Z" fill="#F4F4FC"/>
            <path d="M408.394 217.399L404.84 219.49V213.505L408.394 211.487V217.399Z" fill="#0E7FD5"/>
            <path d="M406.652 212.497V218.481" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M408.394 214.443L404.84 216.534" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M403.316 220.86L399.326 223.167V222.735L402.953 220.644L403.316 220.86Z" fill="#DBE2F3"/>
            <path d="M402.953 220.643V214.659L403.316 214.443V220.86L402.953 220.643Z" fill="#F4F4FC"/>
            <path d="M402.953 220.643L399.326 222.734V216.75L402.953 214.659V220.643Z" fill="#0E7FD5"/>
            <path d="M401.139 215.669V221.653" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M402.953 217.688L399.326 219.706" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.802 223.96L393.812 226.267V225.834L397.367 223.743L397.802 223.96Z" fill="#DBE2F3"/>
            <path d="M397.367 223.744V217.76L397.803 217.543V223.96L397.367 223.744Z" fill="#F4F4FC"/>
            <path d="M397.367 223.743L393.812 225.834V219.85L397.367 217.759V223.743Z" fill="#0E7FD5"/>
            <path d="M395.555 218.769V224.753" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M397.367 220.787L393.812 222.806" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.572 208.891L377.562 211.198V210.766L373.935 208.675L373.572 208.891Z" fill="#C5D1E8"/>
            <path d="M373.935 208.675V202.691L373.572 202.475V208.891L373.935 208.675Z" fill="#F4F4FC"/>
            <path d="M373.934 208.675L377.561 210.766V204.782L373.934 202.691V208.675Z" fill="#0E7FD5"/>
            <path d="M375.748 203.701V209.685" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.934 205.647L377.561 207.738" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.086 212.136L383.076 214.371V213.938L379.449 211.919L379.086 212.136Z" fill="#C5D1E8"/>
            <path d="M379.449 211.919V205.935L379.086 205.647V212.136L379.449 211.919Z" fill="#F4F4FC"/>
            <path d="M379.447 211.92L383.074 213.938V207.954L379.447 205.935V211.92Z" fill="#0E7FD5"/>
            <path d="M381.262 206.945V212.929" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.447 208.892L383.074 210.982" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M384.6 215.236L388.589 217.471V217.039L385.035 215.02L384.6 215.236Z" fill="#C5D1E8"/>
            <path d="M385.035 215.02V209.036L384.6 208.747V215.236L385.035 215.02Z" fill="#F4F4FC"/>
            <path d="M385.035 215.02L388.59 217.039V211.055L385.035 209.036V215.02Z" fill="#0E7FD5"/>
            <path d="M386.777 210.046V216.03" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M385.035 211.992L388.59 214.083" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.572 216.967L377.562 219.202V218.769L373.935 216.75L373.572 216.967Z" fill="#C5D1E8"/>
            <path d="M373.935 216.75V210.766L373.572 210.549V216.966L373.935 216.75Z" fill="#F4F4FC"/>
            <path d="M373.934 216.75L377.561 218.769V212.785L373.934 210.766V216.75Z" fill="#0E7FD5"/>
            <path d="M375.748 211.775V217.759" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M373.934 213.722L377.561 215.813" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M384.6 223.239L388.589 225.546V225.114L385.035 223.023L384.6 223.239Z" fill="#C5D1E8"/>
            <path d="M385.035 223.022V217.038L384.6 216.822V223.239L385.035 223.022Z" fill="#F4F4FC"/>
            <path d="M385.035 223.023L388.59 225.114V219.13L385.035 217.039V223.023Z" fill="#0E7FD5"/>
            <path d="M386.777 218.12V224.104" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M385.035 220.067L388.59 222.085" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.16 219.923L383.15 222.23V221.797L379.523 219.706L379.16 219.923Z" fill="#C5D1E8"/>
            <path d="M379.523 219.706V213.722L379.16 213.506V219.922L379.523 219.706Z" fill="#F4F4FC"/>
            <path d="M379.521 219.706L383.149 221.797V215.813L379.521 213.722V219.706Z" fill="#0E7FD5"/>
            <path d="M381.336 214.731V220.715" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M379.521 216.75L383.149 218.769" stroke="#CCCFF6" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M412.02 198.725L391.273 210.621L370.744 198.797V197.211L412.02 197.139V198.725Z" fill="#B6BAD5"/>
            <path d="M412.02 197.139L391.273 209.035L370.744 197.211L391.201 185.531L412.02 197.139Z" fill="#F4F4FC"/>
            <path d="M409.263 197.138L391.345 207.448L373.5 197.21L391.418 186.9L409.263 197.138Z" fill="#DBE2F3"/>
            <path d="M391.274 188.486L407.668 197.931L409.046 197.138L391.274 186.9L373.283 197.21L374.661 198.003L391.274 188.486Z" fill="#B6BAD5"/>
            <path d="M372.158 196.659L375.109 198.284L392.002 188.956L391.854 184.717L389.936 186.695L372.158 196.659Z" fill="#BACAE3"/>
            <path d="M371.368 177.727C377.31 177.155 381.869 174.057 381.553 170.807C381.236 167.557 376.163 165.386 370.221 165.958C364.28 166.53 359.72 169.628 360.036 172.878C360.353 176.128 365.426 178.299 371.368 177.727Z" fill="white"/>
            <path d="M378.359 173.097L374.193 174.85L364.717 170.435L368.883 168.683L378.359 173.097Z" fill="#0D80D8"/>
            <path d="M378.359 169.262L367.765 174.85L364.717 173.037L375.311 167.449L378.359 169.262Z" fill="#0D80D8"/>
            <path d="M223.328 278.753C223.328 278.753 279.702 278.753 282.11 278.753C284.518 278.753 285.572 277.386 285.572 274.519C285.572 271.652 285.572 232.406 285.57 229.875C285.569 227.344 286.592 226.979 288.692 226.979C290.792 226.979 320.397 226.979 320.397 226.979" stroke="url(#paint6_linear_1467_20858)"/>
            <path d="M223.328 278.753C223.328 278.753 279.702 278.753 282.11 278.753C284.518 278.753 285.572 277.386 285.572 274.519C285.572 271.652 285.572 232.406 285.57 229.875C285.569 227.344 286.592 226.979 288.692 226.979C290.792 226.979 320.397 226.979 320.397 226.979" stroke="#1A77CA"/>
            <path d="M216.077 279.572C216.077 279.572 216.275 233.803 216.29 231.395C216.305 228.987 217.679 227.942 220.545 227.96C223.412 227.978 240.586 228.083 243.117 228.1C245.648 228.117 246.019 227.097 246.032 224.996C246.045 222.896 246.032 180.787 246.032 180.787" stroke="url(#paint7_linear_1467_20858)"/>
            <path d="M216.077 279.572C216.077 279.572 216.275 233.803 216.29 231.395C216.305 228.987 217.679 227.942 220.545 227.96C223.412 227.978 240.586 228.083 243.117 228.1C245.648 228.117 246.019 227.097 246.032 224.996C246.045 222.896 246.032 180.787 246.032 180.787" stroke="#1A77CA"/>
            <path d="M223.512 288.901C223.512 288.901 244.333 288.901 246.741 288.901C249.149 288.901 250.202 290.269 250.202 293.135C250.202 296.002 250.206 341.575 250.204 344.107C250.203 346.638 251.226 347.002 253.326 347.002C255.426 347.002 312.594 347.002 312.594 347.002" stroke="url(#paint8_linear_1467_20858)"/>
            <path d="M223.512 288.901C223.512 288.901 244.333 288.901 246.741 288.901C249.149 288.901 250.202 290.269 250.202 293.135C250.202 296.002 250.206 341.575 250.204 344.107C250.203 346.638 251.226 347.002 253.326 347.002C255.426 347.002 312.594 347.002 312.594 347.002" stroke="#1A77CA"/>
            <path d="M190.447 284.169C190.643 283.974 190.643 283.658 190.447 283.462L187.265 280.28C187.07 280.085 186.753 280.085 186.558 280.28C186.363 280.476 186.363 280.792 186.558 280.987L189.387 283.816L186.558 286.644C186.363 286.84 186.363 287.156 186.558 287.351C186.753 287.547 187.07 287.547 187.265 287.351L190.447 284.169ZM138.094 283.316C137.818 283.316 137.594 283.54 137.594 283.816C137.594 284.092 137.818 284.316 138.094 284.316L138.094 283.316ZM190.094 283.316L138.094 283.316L138.094 284.316L190.094 284.316L190.094 283.316Z" fill="url(#paint9_linear_1467_20858)"/>
            <circle cx="246.094" cy="179.816" r="6" fill="#449BE1"/>
            <ellipse cx="211.094" cy="282.816" rx="17" ry="18" fill="#F4F6F8"/>
            <path d="M203.292 287.229C204.724 286.308 206.408 284.728 205.769 282.867C205.421 281.853 204.938 280.985 204.873 279.889C204.819 278.961 204.959 278.032 205.283 277.16C206.569 273.718 210.402 272.088 213.842 273.125C217.04 274.083 219.242 277.8 217.915 281.014C217.151 282.872 216.808 284.333 218.513 285.768C218.975 286.162 220.1 286.75 220.094 287.448C220.094 288.36 218.293 287.252 218.094 287.094C218.322 287.492 220.586 289.847 219.145 290.016C217.819 290.171 216.647 288.325 215.851 287.53C214.513 286.197 214.747 289.146 214.741 289.753C214.741 290.715 214.05 292.675 212.829 291.395C211.822 290.347 212.202 288.678 211.5 287.515C210.732 286.238 209.45 288.789 209.137 289.268C208.779 289.791 206.993 292.313 206.285 290.969C205.699 289.879 206.63 288.167 207.081 287.17C206.917 287.527 205.746 288.047 205.403 288.231C204.662 288.638 203.823 288.831 202.979 288.789C201.181 288.658 202.56 287.705 203.292 287.235V287.229Z" fill="#0D80D8"/>
            <path d="M43.409 351.498C44.5436 350.941 44.9828 349.627 44.3899 348.561C44.3302 348.454 44.2604 348.355 44.1855 348.262L47.2672 328.816L42.7114 328.843L40.8103 347.933C40.0226 348.579 39.7752 349.668 40.2811 350.577C40.8739 351.642 42.2743 352.054 43.409 351.498Z" fill="#FFB6B6"/>
            <path d="M44.1555 315.243C44.1555 315.243 42.7938 314.895 42.5959 317.696C42.4374 319.938 40.0576 333.599 40.723 336.656C39.8728 337.024 39.3602 337.895 39.5366 338.799L46.416 338.218C47.3597 337.046 47.3646 335.734 46.8238 335.459L47.3762 331.434L50.5694 318.321L45.1445 315.478L44.1555 315.243Z" fill="#C5AEEE"/>
            <path d="M48.6684 401.339L51.2827 401.339L52.5264 391.873L48.668 391.873L48.6684 401.339Z" fill="#FFB6B6"/>
            <path d="M52.152 401.56V399.896L51.4647 399.936L48.667 400.092L48.0196 400.129L47.5246 405.701L47.498 406H49.3543L49.414 405.703L49.6836 404.355L50.3797 405.703L50.5321 406H55.4534C55.7473 406 56.0147 405.892 56.2114 405.714C56.4258 405.525 56.5584 405.257 56.5584 404.961C56.7661 404.05 52.6735 402.081 52.152 401.56Z" fill="#2E475D"/>
            <path d="M37.1895 398.521L40.1104 399.062L42.8313 390.335L39.8993 389.402L37.1895 398.521Z" fill="#FFB6B6"/>
            <path d="M40.4822 399.613L40.9586 398.01L40.2853 397.875L37.5461 397.318L36.9118 397.191L34.8396 402.433L34.7285 402.714L36.5165 403.183L36.6589 402.912L37.3046 401.681L37.589 403.156L37.651 403.48L42.3912 404.722C42.6742 404.796 42.9627 404.76 43.2032 404.637C43.4637 404.51 43.668 404.285 43.753 404C44.2138 403.175 40.8355 400.246 40.4822 399.613Z" fill="#2E475D"/>
            <path d="M61.6168 347.989L61.6842 347.325L58.7764 339.841L46.3847 342.084C46.3847 342.084 39.9981 349.471 41.4183 354.359C41.8035 355.685 42.6267 371.313 42.3788 371.815C36.6708 383.389 38.2654 391.553 38.2654 391.553C38.2654 391.553 40.0695 393.03 43.1598 393.763C43.9661 393.954 50.0974 367.989 50.0974 367.989C50.0974 367.989 49.8273 369.609 49.4506 372.073C48.287 379.684 46.4593 395.006 48.0832 395.799C50.2328 396.849 52.2892 396.654 52.2892 396.654C52.2892 396.654 58.9909 377.75 57.5707 373.305" fill="#0F4778"/>
            <path d="M55.5298 315.831L51.7026 311.967L48.7933 311.808L44.8729 315.618L43.4192 319.467C41.792 323.178 41.4199 327.269 42.3532 331.185L44.586 340.554C44.586 340.554 44.93 341.658 44.0287 341.79C43.1275 341.923 43.2356 343.55 43.2356 343.55C43.2356 343.55 43.4191 344.961 42.544 345.085C41.6689 345.209 39.3354 345.209 41.3773 347.674C43.4192 350.138 59.5695 340.817 59.5695 340.817L59.6477 329.262C59.6477 329.262 64.1968 323.526 59.2978 320.241L55.5298 315.831Z" fill="#C5AEEE"/>
            <path opacity="0.35" d="M70.1032 356.66L58.033 352.568L45.9609 356.66L58.033 361.98L70.1032 356.66Z" fill="#449BE1"/>
            <path d="M58.1755 361.282L46.1035 355.845V343.376L58.1755 348.211V361.282Z" fill="#87BFEC"/>
            <path d="M58.1777 361.282L70.2498 355.845V343.376L58.1777 348.211V361.282Z" fill="#87BFEC"/>
            <path d="M70.2476 343.376L58.1755 338.799L46.1035 343.376L58.1755 348.211L70.2476 343.376Z" fill="#CDE4F7"/>
            <path d="M54.3844 340.217L50.9883 341.523L62.8343 346.413L66.1865 345.054L54.3844 340.217Z" fill="#1A77CA"/>
            <path d="M66.1892 345.034L62.7949 346.433V351.11L66.1892 349.751V345.034Z" fill="#1A77CA"/>
            <path d="M52.7285 340.856L64.5324 345.693" stroke="#093051" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.3414 347.904C63.198 347.011 63.1212 345.635 62.1699 344.831C62.0741 344.75 61.972 344.68 61.867 344.617L57.5311 325.383L53.2715 326.901L58.5816 345.414C58.0834 346.278 58.256 347.379 59.0678 348.065C60.0192 348.87 61.4848 348.797 62.3414 347.904Z" fill="#FFB6B6"/>
            <path d="M53.7417 315.73C53.7417 315.73 55.0273 315.184 55.6963 317.919C56.2319 320.109 60.8918 333.242 60.7517 336.359C61.6534 336.596 62.3068 337.379 62.2855 338.298L55.3952 338.751C54.2653 337.734 54.0385 336.439 54.526 336.087L53.2999 332.195L47.9297 319.725L52.805 316.109L53.7417 315.73Z" fill="#C5AEEE"/>
            <path d="M51.6814 309.282C54.7415 309.282 57.2222 306.953 57.2222 304.08C57.2222 301.208 54.7415 298.879 51.6814 298.879C48.6213 298.879 46.1406 301.208 46.1406 304.08C46.1406 306.953 48.6213 309.282 51.6814 309.282Z" fill="#FFB6B6"/>
            <path d="M56.8633 303.877C56.8792 303.877 56.9177 303.877 56.9903 303.88C57.2011 303.893 56.9154 303.882 56.8633 303.877Z" fill="#5D312E"/>
            <path d="M59.8138 303.381C59.773 303.475 59.6392 303.552 59.451 303.613C59.0928 303.185 58.6279 302.839 58.0973 302.624C58.3354 302.988 58.5304 303.375 58.6755 303.779C58.1608 303.85 57.5872 303.879 57.2176 303.882C57.1292 303.882 57.0521 303.882 56.9908 303.879C56.9001 303.728 56.755 303.483 56.6303 303.23C56.619 303.922 55.5918 305.961 55.6757 307.685C55.7778 309.748 55.8934 311.998 55.8934 311.998C55.5238 312.085 55.0771 312.147 54.5669 312.186C54.0046 311.217 53.5874 310.133 53.3629 309.035C53.338 308.918 53.3176 308.801 53.2972 308.684C53.2495 308.818 53.2042 308.954 53.1657 309.093C52.9956 309.667 52.8958 310.287 52.8641 310.93C52.8414 311.353 52.8505 311.783 52.8845 312.22C50.0865 312.754 46.6264 312.699 44.1231 311.394C44.207 311.179 44.2705 310.957 44.3113 310.732C44.4473 309.991 43.3499 309.887 42.6198 310.34C41.8897 309.652 41.3364 308.78 41.0371 307.688C45.8078 305.723 40.7854 303.305 44.47 299.82C44.0256 296.793 52.9276 295.918 54.6508 298.609C55.0272 298.341 56.1995 298.353 56.6983 298.549C57.1972 298.745 57.5826 299.075 57.959 299.394C58.2107 299.611 58.0928 300.044 57.9318 300.367C58.7232 300.012 60.4895 301.859 59.8138 303.381Z" fill="#5D312E"/>
            <path d="M108.782 287.204C110.225 287.937 110.954 289.268 110.413 290.178C109.871 291.086 108.263 291.229 106.818 290.495C106.237 290.21 105.745 289.791 105.382 289.274L99.3163 286.091L101.107 283.301L106.898 286.734C107.567 286.741 108.214 286.902 108.784 287.203L108.782 287.204Z" fill="#A0616A"/>
            <path d="M73.8191 266.228C73.8191 266.228 77.2336 262.745 78.6307 263.435C79.258 263.746 83.6013 270.575 89.9583 275.3C97.74 281.082 104.483 282.024 107.739 284.933C104.908 286.48 102.011 289.456 102.011 289.456L85.9397 279.987L73.821 266.227L73.8191 266.228Z" fill="#F2F8FD"/>
            <path d="M74.2246 265.672L79.9501 267.419V259.766H74.7545L74.2246 265.672Z" fill="#A0616A"/>
            <path d="M79.2425 261.884C82.3934 261.884 84.9478 259.512 84.9478 256.586C84.9478 253.661 82.3934 251.289 79.2425 251.289C76.0915 251.289 73.5371 253.661 73.5371 256.586C73.5371 259.512 76.0915 261.884 79.2425 261.884Z" fill="#A0616A"/>
            <path d="M77.7896 257.001L78.5765 257.888L79.9984 255.575C79.9984 255.575 81.8137 255.663 81.8137 254.411C81.8137 253.159 83.4793 253.124 83.4793 253.124C83.4793 253.124 85.8379 249.301 80.953 250.307C80.953 250.307 77.5638 248.153 75.8804 249.994C75.8804 249.994 70.7138 252.41 72.1916 256.615L74.6485 260.951L75.2052 259.97C75.2052 259.97 74.8676 255.849 77.7896 256.997V257.001Z" fill="black"/>
            <path opacity="0.35" d="M145.876 298.243L118.774 289.055L91.668 298.243L118.774 310.189L145.876 298.243Z" fill="#449BE1"/>
            <path d="M119.094 308.622L91.9883 296.414V268.415L119.094 279.272V308.622Z" fill="#87BFEC"/>
            <path d="M119.096 308.622L146.202 296.414V268.415L119.096 279.272V308.622Z" fill="#87BFEC"/>
            <path d="M146.201 268.415L119.094 258.138L91.9883 268.415L119.094 279.272L146.201 268.415Z" fill="#CDE4F7"/>
            <path d="M110.582 261.321L102.957 264.255L129.556 275.235L137.083 272.182L110.582 261.321Z" fill="#1A77CA"/>
            <path d="M137.084 272.137L129.463 275.28V285.781L137.084 282.728V272.137Z" fill="#1A77CA"/>
            <path d="M106.865 262.758L133.369 273.619" stroke="#093051" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M72.5061 351.266H77.1875V345.099H72.5061V351.266Z" fill="#A0616A"/>
            <path d="M76.6706 354.799C75.8702 354.865 71.8639 355.16 71.6627 354.307C71.4794 353.524 71.7499 352.706 71.7879 352.594C72.1725 349.037 72.3155 348.997 72.4027 348.974C72.5391 348.937 72.937 349.113 73.5831 349.5L73.6234 349.524L73.6323 349.568C73.6435 349.624 73.9297 350.93 75.2867 350.728C76.2167 350.591 76.5185 350.4 76.6147 350.307C76.5364 350.274 76.4381 350.216 76.3687 350.116C76.2681 349.971 76.2503 349.784 76.3173 349.56C76.4917 348.968 77.0148 348.094 77.0372 348.059L77.0976 347.959L82.4184 351.293L85.7048 352.165C85.9529 352.231 86.1519 352.395 86.2525 352.615C86.3911 352.922 86.3061 353.279 86.0379 353.503C85.441 354.002 84.2561 354.855 83.0108 354.961C82.6799 354.99 82.2418 355 81.7499 355C79.6954 355 76.6951 354.803 76.6728 354.799H76.6706Z" fill="#2E475D"/>
            <path d="M83.1689 291.933L70.8885 291.35C70.8885 291.35 69.0061 297.975 71.3849 304.879L71.671 346.452H78.6104L85.1519 303.567L83.1689 291.933Z" fill="#2E475D"/>
            <path d="M81.0954 264.202L74.355 260.881C74.355 260.881 67.0914 270.736 68.0952 276.075C69.1013 281.414 70.8853 291.352 70.8853 291.352L85.7545 291.935L83.1589 272.364L81.0954 264.202Z" fill="#F2F8FD"/>
            <path d="M75.8381 351.266H80.5195V345.099H75.8381V351.266Z" fill="#A0616A"/>
            <path d="M80.0006 354.799C79.2003 354.865 75.194 355.16 74.9928 354.307C74.8095 353.524 75.08 352.706 75.118 352.594C75.5025 349.037 75.6456 348.997 75.7328 348.974C75.8692 348.937 76.2671 349.113 76.9132 349.5L76.9535 349.524L76.9624 349.568C76.9736 349.624 77.2597 350.93 78.6168 350.728C79.5468 350.591 79.8486 350.4 79.9447 350.307C79.8665 350.274 79.7681 350.216 79.6988 350.116C79.5982 349.971 79.5803 349.784 79.6474 349.56C79.8218 348.968 80.3449 348.094 80.3673 348.059L80.4276 347.959L85.7485 351.293L89.0348 352.165C89.283 352.231 89.482 352.395 89.5826 352.615C89.7212 352.922 89.6362 353.279 89.3679 353.503C88.771 354.002 87.5861 354.855 86.3409 354.961C86.01 354.99 85.5718 355 85.08 355C83.0254 355 80.0252 354.803 80.0029 354.799H80.0006Z" fill="#2E475D"/>
            <path d="M85.7539 291.933L70.8846 291.35C70.8846 291.35 69.0022 297.975 71.381 304.879L74.256 346.452H81.1954L87.7369 303.567L85.7539 291.933Z" fill="#2E475D"/>
            <path d="M101.207 297.473C102.468 298.463 102.904 299.906 102.184 300.695C101.463 301.482 99.8571 301.319 98.5949 300.327C98.0843 299.938 97.6894 299.434 97.4422 298.859L92.1621 294.594L94.4999 292.198L99.4583 296.656C100.113 296.789 100.713 297.069 101.209 297.472L101.207 297.473Z" fill="#A0616A"/>
            <path d="M70.2034 271.613C70.2034 271.613 74.2771 268.844 75.5023 269.785C76.0522 270.208 78.8827 277.721 84.1265 283.551C90.5454 290.688 96.9572 292.885 99.5414 296.351C96.4432 297.332 92.9825 299.702 92.9825 299.702L79.2089 287.387L70.2054 271.612L70.2034 271.613Z" fill="#F2F8FD"/>
            <circle cx="327.094" cy="225.816" r="8" fill="#449BE1"/>
            <circle cx="316.133" cy="346.04" r="5.5" fill="#449BE1"/>
            <defs>
                <linearGradient id="paint0_linear_1467_20858" x1="260.085" y1="152.409" x2="261.715" y2="152.409" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1467_20858" x1="261.798" y1="153.414" x2="263.428" y2="153.414" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1467_20858" x1="263.514" y1="154.419" x2="265.144" y2="154.419" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1467_20858" x1="265.231" y1="155.424" x2="266.854" y2="155.424" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1467_20858" x1="266.948" y1="156.358" x2="268.571" y2="156.358" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint5_linear_1467_20858" x1="267.974" y1="164.005" x2="270.646" y2="164.005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E2E3E5"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <linearGradient id="paint6_linear_1467_20858" x1="304.826" y1="220.538" x2="215.99" y2="253.472" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint7_linear_1467_20858" x1="256.925" y1="197.388" x2="225.287" y2="281.126" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint8_linear_1467_20858" x1="294.254" y1="362.636" x2="209.729" y2="331.3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#93CCFF"/>
                    <stop offset="1" stopColor="#1A77CA"/>
                </linearGradient>
                <linearGradient id="paint9_linear_1467_20858" x1="143.99" y1="283.816" x2="189.184" y2="283.816" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1A77CA"/>
                    <stop offset="1" stopColor="#5DB1FC"/>
                </linearGradient>
            </defs>
        </svg>);
}
const illustrationStyles = css({
    width: "100%",
});
