import React, { forwardRef } from "react";
import type { NavigationBarButtonAriaAttributes } from "./NavigationBarButton";
import { getNavigationButtonAriaAttributes } from "./NavigationBarButton";
import { navigationBarIconButtonStyles } from "./navigationBarStyles";
export interface NavigationBarIconButtonProps extends NavigationBarButtonAriaAttributes {
    onClick?: React.MouseEventHandler;
    accessibleName: string;
    icon: React.ReactNode;
}
export const NavigationBarIconButton = forwardRef<HTMLButtonElement, NavigationBarIconButtonProps>(({ onClick, accessibleName, icon, ...otherProps }, ref) => {
    const ariaAttributes = getNavigationButtonAriaAttributes(otherProps);
    return (<button className={navigationBarIconButtonStyles} onClick={onClick} aria-label={accessibleName} ref={ref} {...ariaAttributes}>
            {icon}
        </button>);
});
NavigationBarIconButton.displayName = "NavigationBarIconButton";
