import type { MouseEventHandler } from "react";
import React, { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import type { MenuState } from "./CustomMenu";
export type MenuTargetAriaAttributes = {
    "aria-controls": string;
    "aria-haspopup": "menu";
    "aria-expanded": "true" | "false";
};
export function useMenuState(): [
    MouseEventHandler,
    MenuState,
    MenuTargetAriaAttributes
] {
    const [anchorElement, setAnchorElement] = React.useState<Element | null>(null);
    const openMenu = useCallback<MouseEventHandler>((event) => setAnchorElement(event.currentTarget), [setAnchorElement]);
    const onClose = useCallback(() => setAnchorElement(null), [setAnchorElement]);
    const menuId = React.useRef(`menu-${uuidv4()}`);
    const menuState = useMemo(() => ({
        isOpen: Boolean(anchorElement),
        onClose,
        anchorElement,
        menuId: menuId.current,
    }), [anchorElement, onClose]);
    const triggerAriaAttributes = {
        "aria-controls": menuId.current,
        "aria-haspopup": "menu" as const,
        "aria-expanded": toBooleanString(menuState.isOpen),
    };
    return [openMenu, menuState, triggerAriaAttributes];
}
function toBooleanString(value: boolean): "true" | "false" {
    return value ? "true" : "false";
}
