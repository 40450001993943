import { css, keyframes } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export type BeaconSizeType = "small" | "medium";
export interface BeaconBaseProps {
    size?: BeaconSizeType;
    ripple?: number;
    onClick?: () => void;
}
interface BeaconSize {
    innerCircle: number;
    outerCircle: number;
}
interface BeaconDefaultSize {
    small: BeaconSize;
    medium: BeaconSize;
}
export function BeaconBase({ size = "medium", ripple = 0.75, onClick }: BeaconBaseProps) {
    const beacondefaultSize: BeaconDefaultSize = {
        small: {
            innerCircle: 10,
            outerCircle: 20,
        },
        medium: {
            innerCircle: 14,
            outerCircle: 32,
        },
    };
    const outerRingKeyframes = keyframes({
        "0%": {
            transform: "scale(1)",
        },
        "45%": {
            transform: `scale(${ripple})`,
        },
        "100%": {
            transform: "scale(1)",
        },
    });
    const beaconStyles = {
        container: css({
            width: beacondefaultSize[size].outerCircle,
            height: beacondefaultSize[size].outerCircle,
            position: "relative",
            cursor: "pointer",
            background: "none",
            border: "none",
        }),
        beaconInner: css({
            position: "absolute",
            width: beacondefaultSize[size].innerCircle,
            height: beacondefaultSize[size].innerCircle,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "block",
            borderRadius: "50%",
            backgroundColor: themeTokens.color.icon.info,
        }),
        beaconOuter: css({
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0",
            top: "0",
            display: "block",
            borderRadius: "50%",
            border: `2px solid ${themeTokens.color.icon.info}`,
            backgroundColor: themeTokens.color.button.background.tertiary.pressed,
            transformOrigin: "center center",
            animation: `${outerRingKeyframes} 1.2s ease-in-out infinite`,
            boxSizing: "border-box",
        }),
    };
    const onBeaconClicked = () => {
        onClick?.();
    };
    return (<button onClick={onBeaconClicked} className={beaconStyles.container}>
            <span className={beaconStyles.beaconInner}/>
            <span className={beaconStyles.beaconOuter}/>
        </button>);
}
