import { css, cx } from "@emotion/css";
import { themeTokens, space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useSetPageTitleSecondarySegmentEffect } from "../../routing";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { Breadcrumbs } from "../Breadcrumbs";
import { PageHeaderSecondaryMainContent } from "./PageHeaderSecondaryMainContent";
import type { PageHeaderSecondaryMainContentProps } from "./PageHeaderSecondaryMainContent";
export type PageHeaderSecondaryProps = PageHeaderSecondaryMainContentProps & {
    /**
     * A list of BreadcrumbItem that forms the breadcrumbs at the top of HeaderSecondary.
     *
     * The breadcrumbs is only visible when this property is not empty list.
     */
    breadcrumbsItems?: BreadcrumbItem[];
    /**
     * indicates if this HeaderSecondary component should be in a sticky state because of scrolling.
     *
     * Sticky state has different visual effect compared to normal state.
     */
    isStickied?: boolean;
    /** If true and when the breadcrumbsItems' length is one, the breadcrumb in secondary header will show with back icon */
    showBreadcrumbBackIcon?: boolean;
};
export function PageHeaderSecondary({ breadcrumbsItems, showBreadcrumbBackIcon, isStickied, titleStatusIcon, titleChip, title, titleAccessory, ...mainContentProps }: PageHeaderSecondaryProps) {
    useSetPageTitleSecondarySegmentEffect(title);
    return (<div className={cx(headerSecondaryStyles, { [headerSecondaryStickiedStyle]: isStickied })}>
            {breadcrumbsItems && breadcrumbsItems.length !== 0 && (<div className={breadcrumbsContainer}>
                    <Breadcrumbs items={breadcrumbsItems} showBackIcon={showBreadcrumbBackIcon}/>
                </div>)}
            <PageHeaderSecondaryMainContent title={title} titleStatusIcon={titleStatusIcon} titleChip={titleChip} titleAccessory={titleAccessory} {...mainContentProps}/>
        </div>);
}
const headerSecondaryStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: space["16"],
});
const headerSecondaryStickiedStyle = css({
    borderBottom: `1px solid ${themeTokens.color.border.secondary}`,
    boxShadow: themeTokens.shadow.small,
});
const breadcrumbsContainer = css({
    paddingBottom: space["4"],
});
