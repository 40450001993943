import { css, cx } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
interface CircleXMarkIconProps {
    size: 16 | 20 | 24;
}
export function CircleXMarkIcon({ size }: CircleXMarkIconProps) {
    const sizeStyles = css({
        width: size,
        height: size,
    });
    return (<svg className={cx(iconStyles, sizeStyles)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0312 7C15.3438 7 11.0938 9.5 8.71875 13.5C6.40625 17.5625 6.40625 22.5 8.71875 26.5C11.0938 30.5625 15.3438 33 20.0312 33C24.6562 33 28.9062 30.5625 31.2812 26.5C33.5938 22.5 33.5938 17.5625 31.2812 13.5C28.9062 9.5 24.6562 7 20.0312 7ZM20.0312 36C14.2812 36 9.03125 33 6.15625 28C3.28125 23.0625 3.28125 17 6.15625 12C9.03125 7.0625 14.2812 4 20.0312 4C25.7188 4 30.9688 7.0625 33.8438 12C36.7188 17 36.7188 23.0625 33.8438 28C30.9688 33 25.7188 36 20.0312 36ZM14.9688 14.9375C15.5312 14.375 16.4688 14.375 17.0312 14.9375L19.9688 17.875L22.9062 14.9375C23.5312 14.375 24.4688 14.375 25.0312 14.9375C25.6562 15.5625 25.6562 16.5 25.0312 17.0625L22.0938 20L25.0312 22.9375C25.6562 23.5625 25.6562 24.5 25.0312 25.0625C24.4688 25.6875 23.5312 25.6875 22.9062 25.0625L19.9688 22.125L17.0312 25.0625C16.4688 25.6875 15.5312 25.6875 14.9688 25.0625C14.3438 24.5 14.3438 23.5625 14.9688 22.9375L17.9062 20L14.9688 17.0625C14.3438 16.5 14.3438 15.5625 14.9688 14.9375Z"/>
        </svg>);
}
