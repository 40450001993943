import { css, cx } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
const styles = cx(iconStyles, css({
    fill: "none",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
}));
export function FormIcon() {
    return (<svg className={styles} width="40" height="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 4h1a3 3 0 0 1 3 3 3 3 0 0 1 3-3h1"/>
            <path d="M13 20h-1a3 3 0 0 1-3-3 3 3 0 0 1-3 3H5"/>
            <path d="M5 16H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1"/>
            <path d="M13 8h7a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-7"/>
            <path d="M9 7v10"/>
        </svg>);
}
