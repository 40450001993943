import * as React from "react";
import type { BeaconBaseProps } from "./BeaconBase";
import { BeaconBase } from "./BeaconBase";
export interface BeaconInlineProps extends BeaconBaseProps {
    open: boolean;
}
export function BeaconInline({ open, size, ripple, onClick }: BeaconInlineProps) {
    const onBeaconClicked = () => {
        onClick?.();
    };
    if (!open) {
        return <></>;
    }
    return <BeaconBase size={size} ripple={ripple} onClick={onBeaconClicked}></BeaconBase>;
}
