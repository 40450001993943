import React from "react";
import type { IconSize } from "./iconStyles";
import { getIconStyles } from "./iconStyles";
interface EditIconProps {
    size?: IconSize;
}
export function EditIcon({ size = 24 }: EditIconProps) {
    return (<svg className={getIconStyles(size)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.83387 31.4076L4.2705 26.524C4.52034 25.6474 5.02004 24.8335 5.64466 24.2074L24.6331 5.17394C26.1947 3.60869 28.7556 3.60869 30.3172 5.17394L32.7532 7.61573C32.9406 7.80356 33.128 8.054 33.2529 8.24183C34.3147 9.80708 34.1274 11.9358 32.7532 13.3132L13.7647 32.3467C13.7023 32.4093 13.5773 32.4719 13.5149 32.5971C12.8903 33.098 12.2032 33.4737 11.4536 33.7241L6.58159 35.1642L3.89572 35.9155C3.39603 36.1033 2.83387 35.9781 2.4591 35.5398C2.02186 35.1642 1.89694 34.6007 2.08432 34.0998L2.83387 31.4076ZM6.70652 28.8405L5.70712 32.2841L9.14253 31.2823L10.5792 30.8441C11.0164 30.7188 11.3287 30.531 11.641 30.218L25.9448 15.8803L22.0722 11.9984L7.76837 26.3361C7.70591 26.3361 7.70591 26.3987 7.64345 26.4614C7.3936 26.7118 7.26867 27.0248 7.14375 27.4005L6.70652 28.8405ZM17.5124 32.9728H36.5009C37.3129 32.9728 38 33.6615 38 34.4754C38 35.352 37.3129 35.9781 36.5009 35.9781H17.5124C16.638 35.9781 16.0134 35.352 16.0134 34.4754C16.0134 33.6615 16.638 32.9728 17.5124 32.9728Z"/>
        </svg>);
}
