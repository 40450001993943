import * as React from "react";
type BusyState = Promise<unknown> | boolean;
export function useIsBusy(busyState: BusyState | undefined | null) {
    const isBusyFromPromise = useIsBusyFromPromise(busyState instanceof Promise ? busyState : null);
    return typeof busyState === "boolean" ? busyState : isBusyFromPromise;
}
function useIsBusyFromPromise(busyPromise: Promise<unknown> | null) {
    const [isBusy, setIsBusy] = React.useState(false);
    const latestPromise = React.useRef<Promise<unknown> | undefined>();
    React.useEffect(() => {
        if (busyPromise === null) {
            setIsBusy(false);
            return;
        }
        let effectIsActive = true;
        const awaitPromise = async () => {
            latestPromise.current = busyPromise;
            setIsBusy(true);
            try {
                await busyPromise;
            }
            catch {
                // We don't care about errors, we only care about the promise completing
            }
            if (effectIsActive && latestPromise.current == busyPromise) {
                setIsBusy(false);
            }
        };
        awaitPromise();
        return () => {
            effectIsActive = false;
        };
    }, [busyPromise]);
    return isBusy;
}
