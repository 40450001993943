import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export const GreenTickIcon = () => {
    return (<div className={style}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM11.2995 4.64319L12.4706 5.85399L6.88718 11.4374L3.64729 8.16623L4.82375 6.98977L6.88835 9.06832L11.2995 4.64319Z" fill={themeTokens.color.icon.success}/>
            </svg>
        </div>);
};
const style = css({
    width: "16px",
    height: "16px",
    display: "flex",
});
