import { css } from "@emotion/css";
import { useCallback } from "react";
import * as React from "react";
import { Switch } from "../../Switch";
import { MenuItemButton } from "../MenuItemButton/MenuItemButton";
export interface MenuItemToggleProps {
    isEnabled: boolean;
    label: string;
    setIsEnabled: (isEnabled: boolean) => void;
    autoFocus?: boolean;
    compact?: boolean;
}
export function MenuItemToggle({ setIsEnabled, isEnabled, label, autoFocus, compact }: MenuItemToggleProps) {
    const toggleIsEnabled = useCallback(() => setIsEnabled(!isEnabled), [isEnabled, setIsEnabled]);
    // TODO: This should be role="menuitemcheckbox"
    return (<MenuItemButton onClick={toggleIsEnabled} autoFocus={autoFocus} compact={compact}>
            <div className={containerStyles}>
                <div className={labelStyles}>{label}</div>
                <Switch size="small" value={isEnabled}/>
            </div>
        </MenuItemButton>);
}
MenuItemToggle.displayName = "MenuItemToggle";
const containerStyles = css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
});
const labelStyles = css({
    display: "flex",
    alignItems: "center",
});
