import * as React from "react";
import { ExternalLinkIcon } from "../Icon/ExternalLinkIcon";
import type { LinkProps } from "./Link";
import { Link } from "./Link";
export type ExternalLinkProps = Pick<LinkProps, "href" | "label" | "size">;
export function ExternalLink({ href, label, size }: ExternalLinkProps) {
    return <Link href={formatUrl(href)} label={label} trailingIcon={<ExternalLinkIcon />} openInNewTab size={size}/>;
}
function formatUrl(href: string) {
    if (parseUrl(href)) {
        return href;
    }
    return "https://oc.to/" + href;
}
function parseUrl(url: string): boolean {
    try {
        // We need these .startsWith checks because IE11 will assume URL("someString") is relative to the current
        // domain and won't throw (like every other browser in the universe).
        if (url.startsWith("http://") || url.startsWith("https://")) {
            const ignored = new URL(url);
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
}
